import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { red } from "@material-ui/core/colors";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
  },
  pap: {
    backgroundColor: "antiquewhite",
    boxShadow:
      "0px 2px 3px -1px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 1px 0px rgba(0,0,0,0.12)",
    borderRadius: "6px",
    padding: "15px 0px",
  },
  stdname: {
    fontWeight: "500",
    display: "inline-block",
    textTransform: "capitalize",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

function ClassDetails({ classDetail }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={3} className={classes.pap}>
        <Grid item xs={12} sm={6}>
          Center Code
          <Paper className={classes.paper}>{classDetail?.centerCode}</Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          Class Type
          <Paper className={classes.paper}>{classDetail?.classType}</Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          Program
          <Paper className={classes.paper}>{classDetail?.program}</Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          Duration
          <Paper className={classes.paper}>{classDetail?.duration}</Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          Status
          <Paper className={classes.paper}>{classDetail?.status}</Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          Time Slot From
          <Paper className={classes.paper}>{classDetail?.timeSlotFrom?.length ? moment(classDetail.timeSlotFrom.toString()).format("DD/MM/YYYY HH:mm") : 'N/A'}</Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          Centerhead Credit
          <Paper className={classes.paper}>{classDetail?.centerheadCredit}</Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          Faculty Credit
          <Paper className={classes.paper}>{classDetail?.facultyCredit}</Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          Faculty
          <Paper className={classes.paper}>{classDetail?.facultyId?.name}</Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          Points Per Student Debit
          <Paper className={classes.paper}>{classDetail?.pointsPerStudent}</Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          Total Class Points Debit
          <Paper className={classes.paper}>{classDetail?.totalClassPoints}</Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          Demo Class
          <Paper className={classes.paper}>{classDetail?.isDemo ? "Yes" : "No"}</Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          Reason
          <Paper className={classes.paper}>{classDetail?.cancellationReason ? classDetail.cancellationReason : "-"}</Paper>
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.pap}>
        <Grid item sm={6} xs={6} >
          <Typography variant="h6">Student Information</Typography>
        </Grid>
        {classDetail?.students?.length === 0 ?
          <Grid item xs={12} sm={12} style={{ paddingBottom: "2px", paddingTop: "3px", margin: "3px" }} >
            <Typography variant="body1">{"No Student Available."}</Typography>
          </Grid>
          :
          <Grid item xs={12} sm={12}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Index</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {classDetail?.students?.map((std, i) => {
                    return (
                      <TableRow key={std.name + i}>
                        <TableCell>{i + 1}</TableCell>
                        <TableCell component="th" scope="row">
                          {std.id?.name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {std.id?.approval_status?.status}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        }
      </Grid>
    </div>
  );
}

export default ClassDetails;
