import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import moment from 'moment'
import { fade, lighten, makeStyles } from '@material-ui/core/styles';
import { Table, InputBase, CircularProgress, Button, Tooltip, IconButton, InputLabel, FormControl, Select, Input, MenuItem, Dialog, DialogActions, DialogTitle, DialogContent} from '@material-ui/core';
// import {Table, InputBase, Button, CircularProgress, Box } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import SearchIcon from "@material-ui/icons/Search";
import { GlobalContext } from "../../context/GlobalState";
import SingleStudentData from "./SingleStudentData";
// import AddClass from "../Classes/AddClass"
import { Edit, VisibilitySharp } from '@material-ui/icons';
import EditStudent from './EditStudent';
import EditClass from '../Classes/EditClass';
import AddOrder from '../Orders/AddOrders';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', disablePadding: true, label: 'Student' },
  { id: 'program', label: 'Program' },
  { id: 'classCount', disablePadding: true, numeric: false, label: 'Upcoming' },
  { id: 'lastCompletedClass', disablePadding: true, numeric: false, label: 'Last Completed Class' },
  { id: 'parentCredits', disablePadding: true, numeric: false, label: 'Available Credit' },
  { id: 'cancelledClassCount', disablePadding: true, numeric: false, label: 'Cancelled Count' },
  { id: 'scheduleClassPoints', disablePadding: true, numeric: false, label: 'Booked Amount' },
  { id: 'completedHours', label: 'Total Hours' },
  { id: 'overallStatus', label: 'Status' },
  { id: 'dob', label: 'DOB' },
  { id: 'addClass', disablePadding: true, numeric: false, label: 'Class Action' },
  // { id: 'status', label: 'Status' },
  { id: 'action', label: 'Action' }
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
}));



const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto"
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit",
    border: '1px solid #dddd',
    margin: '6px 6px 0px 6px',
    borderRadius: '6px'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch"
      }
    }
  },
  tbrow: {
    '&:nth-of-type(odd)': {
      backgroundColor: "#faebd761",
    },
    "&:hover": {
      boxShadow: "0px 2px 5px lightsteelblue",
    },
  },
  statusCircle: {
     height: "25px",
     width: "25px",
     borderRadius: "50%",
     display: "inline-block",
     marginTop: "25px",
     marginLeft: "15px"
  },
  good: {
    backgroundColor: "#47B950"
  },
  fair: {
    backgroundColor: "#E9B404"
  },
  bad: {
    backgroundColor: "#B30301"
  },
}));

export default function AllStudents() {
  const classes = useStyles();
  const [selectedStatusType, setSelectedStatusType] = useState("Active");
  const [data, setData] = useState([]);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [onpayment, setPayment] = React.useState(false)
  const [spinner, setSpinner] = React.useState(true);
  // const [newdata, setNewdata] = React.useState({})
  const [newdata1, setNewdata1] = React.useState({})
  const [editingStudent, setEditStudent] = React.useState(false);
  const [addingClass, setAddingClass] = React.useState(false);
  const [orderingBook, setOrderingBook] = React.useState(false);
  const [feedbackRecord, setFeedbackRecord] = React.useState({})
  const { allstudents, getStudentDetails } = React.useContext(GlobalContext);
  const [ openFeedbackFormDialog, setOpenFeedbackFormDialog  ] = React.useState(false);
  const [feedbackMessageFormStatus, setFeedbackMessageFormStatus] = React.useState("");
  const [ openFeedbackMessageFormDialog, setOpenFeedbackMessageFormDialog ] = React.useState(false);
  const { studentFeedbackForm, sendFeedbackForm, encodeDecodeString } = React.useContext(GlobalContext);
  const [ disableButton, setDisableButton ] = React.useState(false);
  React.useEffect(() => {
    setData(allstudents);
    setTimeout(() => setSpinner(false), 3000)
  }, [allstudents]);

  React.useEffect(() => {
    try {
      let searched = allstudents.filter((row) => {
        let searchItem = `${row.name.toLowerCase()} ${row.program.toLowerCase()} ${row.centerCode.toLowerCase()} ${row.type.toLowerCase()} ${row?.approval_status?.status?.toLowerCase()}`;
        return searchItem
          .toLowerCase()
          .includes(searchTerm.toString().trim().toLowerCase());
      });
      if (searchTerm === "") {
        getStudentDetails(selectedStatusType);
        setData(searched);
      } else {
        setData(searched);
      }
    } catch (error) { }
  }, [searchTerm]);

  const filterStudents = (ev) => {
    const { value } = ev.target;
    setSpinner(true);
    setSelectedStatusType(value);
    getStudentDetails(value);
  }

  const handleSearch = event => {
    setSearchTerm(event.target.value);
  };

  const openFeedBackPopup = (val, id) => {
    setOpenFeedbackFormDialog(val);
    var formData = allstudents.filter(item => item._id === id)
    setFeedbackRecord(formData);
  }


  const showPayments = (e, id) => {
    setPayment(true)
    var data1 = allstudents.filter(item => item._id === id)
    setNewdata1(data1[0])
  };

  const editStudent = (e, id) => {
    setEditStudent(true)
    var data1 = allstudents.filter(item => item._id === id)
    setNewdata1({ ...data1[0], fromListing: true })
  };

  const goToAddClass = (e, id) => {
    setAddingClass(true)
    var data1 = allstudents.filter(item => item._id === id)
    setNewdata1({ ...data1[0], fromListing: true })
  };

  const goToOrderBook = (e, id) => {
    setOrderingBook(true)
    var data1 = allstudents.filter(item => item._id === id)
    setNewdata1({ ...data1[0], fromListing: true })
  };

  const postFeedbackForm = () => {
    setDisableButton(true);
    if (feedbackRecord.length){
      let obj = {}
      obj.email = feedbackRecord[0].parent.email ? feedbackRecord[0].parent.email : "";
      obj.parent = feedbackRecord[0].parent.name ? feedbackRecord[0].parent.name : "";
      obj.student = feedbackRecord[0].name ? feedbackRecord[0].name : "";
      obj.program = feedbackRecord[0].program ? feedbackRecord[0].program : "";
      obj.mobile = feedbackRecord[0].parent && feedbackRecord[0].parent.mobileNo ? feedbackRecord[0].parent.mobileNo : "";
      obj.faculty = feedbackRecord[0].preferredFaculty && feedbackRecord[0].preferredFaculty.name ? feedbackRecord[0].preferredFaculty.name : "";
      obj.centerCode = feedbackRecord[0].centerCode ? feedbackRecord[0].centerCode : ""; 
      obj.lastClassId = feedbackRecord[0].debits && feedbackRecord[0].debits.length ? feedbackRecord[0].debits[feedbackRecord[0].debits.length - 1] : "";
      sendFeedbackForm(obj)
      setTimeout(() => {
        setOpenFeedbackFormDialog(false);
        console.log("studentFeedbackForm: ", studentFeedbackForm)
        if(studentFeedbackForm){
          setFeedbackMessageFormStatus("Feedback form sent successfully.");
        } else{
          setFeedbackMessageFormStatus("Something went wrong. Please try again later.");
        }
        setOpenFeedbackMessageFormDialog(true);
      }, 1000);
    } else {
      setFeedbackMessageFormStatus("Something went wrong. Please try again later.");
      setOpenFeedbackMessageFormDialog(true);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = allstudents.map((n) => n.user.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleClose = () => {
    setOpenFeedbackFormDialog(false);
    setFeedbackRecord({});
  }

  const handleClosePopup = () => {
    setOpenFeedbackFormDialog(false);
    setOpenFeedbackMessageFormDialog(false);
    setFeedbackMessageFormStatus(null);
    setDisableButton(false);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const isAdmin = encodeDecodeString(window.localStorage.getItem("user"),'decode');
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, allstudents.length - page * rowsPerPage);
  if (onpayment) { return <SingleStudentData showPayments={newdata1} /> }
  if (editingStudent) { return <EditStudent Parent={null} Student={newdata1} /> }
  if (addingClass) { return <EditClass student={newdata1} /> }
  if (orderingBook) { return <AddOrder student={newdata1} /> }
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.search} style={{ display: "inline-block", marginTop: "14px" }}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
            inputProps={{ "aria-label": "search" }}
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        <div style={{ float: "right", marginRight: "36px", marginTop: "14px", width: "150px" }}>
          <FormControl fullWidth>
            <InputLabel style={{fontSize: "15px"}} id="class-status-label">Select status</InputLabel>
            <Select style={{fontSize: "13px", width:"70%"}}
              labelId="class-status-label"
              id="class-status"
              value={selectedStatusType || 'Active'}
              onChange={filterStudents}
              input={<Input />}
            >
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="On Hold">On Hold</MenuItem>
              <MenuItem value="Opted Out">Opted Out</MenuItem>
            </Select>
          </FormControl>
        </div>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={allstudents.length}
            />

            {spinner ?
              <TableBody style={{ position: "relative" }}>
                <TableRow>
                  <TableCell>
                    <CircularProgress
                      style={{ padding: "5px", position: "absolute", left: "50%" }}
                      color="primary"
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
              :
              <TableBody>
                {stableSort(data, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow className={classes.tbrow} key={index} >
                        <TableCell padding="checkbox"></TableCell>
                        <TableCell component="th" scope="row" padding="none">{row?.name ? row?.name : "N/A"}</TableCell>
                        <TableCell >{row?.program ? row?.program : "N/A"}</TableCell>
                        <TableCell >{row?.classCount ? row?.classCount : 0}</TableCell>
                        <TableCell>{row?.lastCompletedClass?.length ? moment(row.lastCompletedClass.toString()).format("DD/MM/YYYY HH:mm") : '-'}</TableCell>
                        <TableCell >{isAdmin !== "faculty" ? row?.parentCredits ? row?.parentCredits : 0 : "-"}</TableCell>
                        <TableCell >{row?.cancelledClassCount ? row?.cancelledClassCount : 0}</TableCell>
                        <TableCell >{isAdmin !== "faculty" ? row?.scheduleClassPoints ? row?.scheduleClassPoints : 0 : "-"}</TableCell>
                        <TableCell >{row?.completedHours ? row?.completedHours : 0}</TableCell>
                        <TableCell className={`${classes.statusCircle} ${row?.overallStatus === 'good' ? classes.good : classes.bad ? row?.overallStatus === 'fair' ? classes.fair : classes.bad : classes.bad}`}></TableCell>
                        <TableCell >{isAdmin !== "faculty" ? row?.dob ? moment(row?.dob).format("DD/MM/yyyy") : "Unavailable" : "-"}</TableCell>
                        <TableCell>
                          <Button
                            onClick={e => goToAddClass(e.currentTarget.value, row._id)}
                            type="submit"
                            size="medium"
                            pt={2}
                            variant="contained"
                            color="primary"
                            disabled={row.approval_status.status != 'Active' || isAdmin === 'coo' || isAdmin === 'academics' || isAdmin === 'faculty'}
                          >
                            Book Class
                        </Button>
                        </TableCell>
                        {/* <TableCell >{row?.approval_status?.status ? row?.approval_status?.status : "N/A"}</TableCell> */}
                        
                        
                        <TableCell>
                        {isAdmin !== "operations" && isAdmin !== "coo" && isAdmin !== "academics" && isAdmin !== "marketing" &&
                          <Tooltip title="Edit" arrow>
                            <IconButton className="bg5" onClick={e => editStudent(e.currentTarget.value, row._id)}>
                              <Edit />
                            </IconButton>
                          </Tooltip> }
                          <Tooltip title="View" arrow>
                            <IconButton disabled={isAdmin === 'faculty'} className="bg5" onClick={e => showPayments(e.currentTarget.value, row._id)}>
                              <VisibilitySharp />
                            </IconButton>
                          </Tooltip>
                        <Tooltip title="Order" arrow>
                          <IconButton disabled={row.approval_status.status != 'Active' || isAdmin === 'coo' || isAdmin === 'academics' || isAdmin === 'faculty'} className="bg5" onClick={e => goToOrderBook(e.currentTarget.value, row._id)}>
                            <AddShoppingCartIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="FeedBack" arrow>
                          <IconButton disabled={row.approval_status.status != 'Active' || isAdmin === 'coo' || isAdmin === 'academics' || isAdmin === 'faculty'} className="bg5" onClick={e => openFeedBackPopup(true, row._id)}>
                            <FormatAlignJustifyIcon />
                          </IconButton>
                        </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}

              </TableBody>
            }
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 75, 100, 500]}
          component="div"
          count={allstudents.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        <Dialog
          open={openFeedbackFormDialog}
          onClose={handleClose}
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
          aria-labelledby="responsive-dialog-title"
          maxWidth={false}
        >
          <DialogTitle align="center" id="responsive-dialog-title">
            {"Are you sure you want to send form?"}
          </DialogTitle>
          {feedbackRecord && feedbackRecord.length && feedbackRecord.map((record) => (
          <DialogContent>
            <div><strong>Student: {record.name}</strong></div>
            <div><strong>Program: {record.program}</strong></div>
            <div><strong>Center Code: {record.centerCode}</strong></div>
            <div><strong>Parent: {record.parent.name}</strong></div>
          </DialogContent>
          ))}
          <DialogActions style={{ justifyContent: "center" }}>
            <Button onClick={() => handleClose(false)} color="primary">
              Cancel
            </Button>
            <Button disabled={disableButton} onClick={() => postFeedbackForm()} autoFocus color="primary">
              Send
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openFeedbackMessageFormDialog}
          onClose={handleClose}
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
          aria-labelledby="responsive-dialog-title"
          maxWidth={false}
        >
          <DialogTitle align="center" id="responsive-dialog-title">
            {"Message"}
          </DialogTitle>
          {feedbackRecord && feedbackRecord.length && feedbackRecord.map((record) => (
          <DialogContent>
            {feedbackMessageFormStatus}
          </DialogContent>
          ))}
          <DialogActions style={{ justifyContent: "center" }}>
            <Button onClick={() => handleClosePopup(false)} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </div>
  );
}
