import React from "react";
import { Paper, Grid, Typography, Box, Button} from "@material-ui/core";
import NormalDetails from "./NormalDetails";
import AddressDetails from "./AddressDetails";
import BankDetails from "./BankDetails";
import { GlobalContext } from "../../context/GlobalState";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import AllFaculty from "./AllFaculty";

function AddFaculty() {
  const [open, setOpen] = React.useState(false);
  const [back, setBack] = React.useState(false);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [profile, setProfile] = React.useState("");
  const [mobileNo, setMobile] = React.useState("");
  const [acnumber, setAcnumber] = React.useState("");
  const [ifsccode, setIfsccode] = React.useState("");
  const [bankname, setBankname] = React.useState("");
  const [aadhar, setAadhar] = React.useState("");
  const [pan, setPan] = React.useState("");
  const [houseno, setHouseno] = React.useState("");
  const [area, setArea] = React.useState("");
  const [pincode, setPincode] = React.useState("");
  const [landmark, setLandmark] = React.useState("");
  const [catchmentradius, setCatchmentRadius] = React.useState("");
  const [type, setType] = React.useState("");
  const [paymentstatus, setPaymentStatus] = React.useState("No");
  const [activestatus, setActiveStatus] = React.useState("Active");
  const [employmentstatus, setEmploymentStatus] = React.useState("On Roll");
  const [centercode, setCentercode] = React.useState("");
  const [statename, setStateName] = React.useState("");
  const [city, setCity] = React.useState({});
  const [trd,setTrd]= React.useState([]);
  const [dataValue, setDataValue] = React.useState("karnataka");

  const { addFacultyDetails, err, addfaculty } = React.useContext(GlobalContext);

  const backToFaculty = () =>{
    setBack(true)
  }

  const handleClose = () => {
    setOpen(false);
    if(!err){setBack(true)}
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let facultyArray = []
    facultyArray.push(type)
    const addfac = {
      name: name,
      email: email.toLowerCase().trim(),
      mobileNo: mobileNo,
      pincode: pincode,
      house_no: houseno,
      area: area,
      city: city,
      state: statename,
      landmark: landmark,
      status: activestatus,
      type: employmentstatus,
      catchmentRadius: catchmentradius,
      facultyClassType: type,
      centerCode: centercode,
      aadhar: aadhar,
      pan: pan,
      trainedInProgram: trd,
      bank: {
        acNumber: acnumber,
        IFSCCode: ifsccode,
        bankName: bankname,
        bankChequeLeaf: false,
      },
      holdPayment: paymentstatus === "Yes" ? true : false
    };
    addFacultyDetails(addfac);
    setTimeout(function() {setOpen(true)},800);
    //setOpen(true);
  };

  if (back) {
    return <AllFaculty />;
  }

 
  return (
    <React.Fragment>
      <Box pb={2}>
        <Button
          onClick={backToFaculty}
          type='submit'
          size='large'
          pt={2}
          variant='contained'
          color='primary'
        >
          <KeyboardBackspaceIcon />
        </Button>
      </Box>
      <form onSubmit={onSubmit}>
        <Box boxShadow={3} mb={1}>
          <Grid container component={Paper}>
            <Grid item xs={12}>
              <Box p={2}>
                <Typography variant='h5'>Add Faculty</Typography>
              </Box>
            </Grid>
            <NormalDetails
              name={name}
              email={email}
              mobileNo={mobileNo}
              setName={setName}
              setEmail={setEmail}
              setMobile={setMobile}
              activestatus={activestatus}
              setActiveStatus={setActiveStatus}
              employmentstatus={employmentstatus}
              setEmploymentStatus={setEmploymentStatus}
              type={type}
              setType={setType}
              paymentstatus={paymentstatus}
              setPaymentStatus={setPaymentStatus}
            />
            <AddressDetails
              trd={trd}
              setTrd={setTrd}
              houseno={houseno}
              setHouseno={setHouseno}
              area={area}
              setArea={setArea}
              landmark={landmark}
              setLandmark={setLandmark}
              pincode={pincode}
              setPincode={setPincode}
              catchmentradius={catchmentradius}
              setCatchmentRadius={setCatchmentRadius}
              statename={statename}
              setStateName={setStateName}
              city={city}
              setCity={setCity}
              dataValue={dataValue}
              setDataValue={setDataValue}
              centercode={centercode}
              setCentercode={setCentercode}
            />
            <BankDetails
              bankname={bankname}
              setBankname={setBankname}
              acnumber={acnumber}
              setAcnumber={setAcnumber}
              ifsccode={ifsccode}
              setIfsccode={setIfsccode}
              aadhar={aadhar}
              setAadhar={setAadhar}
              pan={pan}
              setPan={setPan}
            />
            <Grid item xs={12}>
              <Box p={2}>
                <Button
                  type='submit'
                  size='large'
                  pt={2}
                  variant='contained'
                  color='primary'
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </form>

      <Dialog
      id="popup"
        open={open}
        onClose={handleClose}
        aria-labelledby='responsive-dialog-title-new-faculty'
      >
        <DialogTitle
          align='center'
          style={{ background: "antiquewhite" }}
          id='responsive-dialog-title-new-faculty'
        >
          {err ? err : "New Faculty is Added"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button onClick={handleClose} variant='contained' color='primary'>
            Back
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default AddFaculty;
