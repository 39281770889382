import checkValidity from "./FieldValidator";

export const isValidatedForm = (jsonform) => {
  let formIsValid = true;
  let elementValid = true;
  let form = jsonform;
  form.forEach((element) => {

    let { isValid, errorText } = checkValidity(
      element.value,
      element.validation
    );
    elementValid = isValid;
    formIsValid = elementValid && formIsValid;
    element.valid = isValid;
    element.errorText = errorText;
    //updated element's touched property
    element.touched = 1;
  });

  return { formValidity: formIsValid, validatedForm: form };
};
export const formValidityCheck = (jsonform) => {
  let formIsValid = true;
  let elementValid = true;
  let form = jsonform;
  form.forEach((element) => {
    let { isValid } = checkValidity(element.value, element.validation);
    elementValid = isValid;
    formIsValid = elementValid && formIsValid;
  });

  return { formValidity: formIsValid };
};

export const DEFAULT_JSON = (json) => {
  if (json?.length) {
    return json?.map((item) => {
      (item.value = item.type === "checkbox" ? Boolean(item.value) : item.type === "dropdown" ? item.multiple ? [] : "" : "");
      (item.touched = 0);
      item.valid = true;
      return item;
    });
  }
};

