import React from "react";
import { Paper, Grid, Typography , Box, Button} from "@material-ui/core";
import CHNormalDetails from "./CHNormalDetails";
import CHAddressDetails from "./CHAddressDetails";
import CHBankDetails from "./CHBankDetails";
import { GlobalContext } from "../../context/GlobalState";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
//import AllFaculty from "./AllFaculty";
import AllCenterHead from "./AllCenterHead";

function AddCenterHead() {
  const [open, setOpen] = React.useState(false);
  const [back, setBack] = React.useState(false);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [profile, setProfile] = React.useState("");
  const [mobileNo, setMobile] = React.useState("");
  const [activestatus, setActiveStatus] = React.useState("");
  const [acnumber, setAcnumber] = React.useState("");
  const [ifsccode, setIfsccode] = React.useState("");
  const [bankname, setBankname] = React.useState("");
  const [aadhar, setAadhar] = React.useState("");
  const [pan, setPan] = React.useState("");
  const [houseno, setHouseno] = React.useState("");
  const [area, setArea] = React.useState("");
  const [pincode, setPincode] = React.useState("");
  const [landmark, setLandmark] = React.useState("");
  const [catchmentradius, setCatchmentRadius] = React.useState("");
  const [type, setType] = React.useState("");
  const [employmentstatus, setEmploymentStatus] = React.useState("");
  const [centercode, setCentercode] = React.useState("");
  const [statename, setStateName] = React.useState("");
  const [city, setCity] = React.useState({});
  //const [trd,setTrd]= React.useState([]);
  const [dataValue, setDataValue] = React.useState("karnataka");

  const { addCenterHeadDetails, err } = React.useContext(GlobalContext);

  const handleClose = () => {
    setOpen(false);
   if(!err){setBack(true)}
  };

  const backToCenterHead = () =>{
    setBack(true)
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const addhead = {
      latitude : 0,
      longitude : 0,
      name: name,
      email: email.toLowerCase().trim(),
      mobileNo: mobileNo,
      password: password,
      pincode: pincode,
      status: activestatus,
      house_no: houseno,
      area: area,
      city: city,
      state: statename,
      landmark: landmark,
      centerCode: centercode,
      aadhar: aadhar,
      pan: pan,
      bank: {
        acNumber: acnumber,
        IFSCCode: ifsccode,
        bankName: bankname,
        bankChequeLeaf: false,
      },
    };
    addCenterHeadDetails(addhead);
    setTimeout(function() {setOpen(true)},800);
  };

  if (back) {
    return <AllCenterHead />;
  }

  return (
    <React.Fragment>
      <Box pb={2}>
        <Button
          onClick={backToCenterHead}
          type='submit'
          size='large'
          pt={2}
          variant='contained'
          color='primary'
        >
          <KeyboardBackspaceIcon />
        </Button>
      </Box>
      <form onSubmit={onSubmit}>
        <Box boxShadow={3} mb={1}>
          <Grid container component={Paper}>
            <Grid item xs={12}>
              <Box p={2}>
                <Typography variant='h5'>Add Center Head</Typography>
              </Box>
            </Grid>
            <CHNormalDetails
              name={name}
              email={email}
              mobileNo={mobileNo}
              password={password}
              status={activestatus}
              setName={setName}
              setEmail={setEmail}
              setMobile={setMobile}
              setPassword={setPassword}
              setActiveStatus={setActiveStatus}
              formType="addCenterHead"
            />
            <CHAddressDetails
              houseno={houseno}
              setHouseno={setHouseno}
              area={area}
              setArea={setArea}
              landmark={landmark}
              setLandmark={setLandmark}
              pincode={pincode}
              setPincode={setPincode}
              statename={statename}
              setStateName={setStateName}
              city={city}
              setCity={setCity}
              dataValue={dataValue}
              setDataValue={setDataValue}
              centercode={centercode}
              setCentercode={setCentercode}
            />
            <CHBankDetails
              bankname={bankname}
              setBankname={setBankname}
              acnumber={acnumber}
              setAcnumber={setAcnumber}
              ifsccode={ifsccode}
              setIfsccode={setIfsccode}
              aadhar={aadhar}
              setAadhar={setAadhar}
              pan={pan}
              setPan={setPan}
            />
            <Grid item xs={12}>
              <Box p={2}>
                <Button
                  type='submit'
                  size='large'
                  pt={2}
                  variant='contained'
                  color='primary'
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </form>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='centerhead-dialog-title'
      >
        <DialogTitle
          align='center'
          style={{ background: "antiquewhite" }}
          id='centerhead-dialog-title'
        >
          {err ? err : "New Center Head is added"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button onClick={handleClose} variant='contained' color='primary'>
            Back
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default AddCenterHead;
