import React from "react";
import moment from 'moment';
import { Paper, Grid, Typography, Box, Button, Table, TableBody,TableRow, TableCell, TableHead, Checkbox } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import SingleStudentData from "./SingleStudentData";
import SingleParentData from "../Parents/SingleParentData";
import FormRender from "../../common/FormHelper/FormRender";
import checkValidity from "../../common/FormHelper/FieldValidator";
import { isValidatedForm, DEFAULT_JSON } from "../../common/FormHelper/FormValidSetter";
import { GlobalContext } from "../../context/GlobalState";
import Config from "../../common/config";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AllStudents from "./AllStudents";
//JSON
import BasicJson from "./Schema/BasicDetails.json";
import AtHomeJson from "./Schema/AtHomeRate.json";
import OnlineJson from "./Schema/OnlineRate.json";
import ModuleFilterJson from "./Schema/ModuleFilter.json";

const headCells = [
  { id: 'month', numeric: false,  label: 'Month' },
  { id: 'week', numeric: false,  label: 'Week' },
  { id: 'title', numeric: false,  label: 'Title' },
  { id: 'description', numeric: false,  label: 'Description' },
  { id: 'selection', numeric: true,  label: 'Completed' },
]
class EditStudent extends React.Component {
  static contextType = GlobalContext;
  constructor(props) {
    super(props);
    this.state = {
      basicJson: DEFAULT_JSON(BasicJson),
      moduleFilterJson: DEFAULT_JSON(ModuleFilterJson),
      HomeJson: DEFAULT_JSON(AtHomeJson),
      isHomeRequired: false,
      isOnlineRequired: false,
      OnlineJson: DEFAULT_JSON(OnlineJson),
      isBack: false,
      dialogMsg: "",
      openDialog: false,
      Parent :  props?.Parent,
      Student :  props?.Student,
      allStateCity: [],
      cityOptions: [],
      allModulesData: [],
      selectedModules: [],
      completedModules: []
    };
  }
  componentDidMount() {
    this.fillDetails();
    if (this.state.Student?.completedModules.length){
      let finishedModules = []
      for (const k in this.state.Student?.completedModules) {
        finishedModules.push(this.state.Student?.completedModules[k].moduleId)
      }
      this.setState({completedModules: finishedModules})
    }
  }
 
  fillDetails = () => {
    const { Student } = this.props;
    const { specialRates } = Student;
    const { basicJson, HomeJson, OnlineJson } = this.state;
    const copyBasic = [...basicJson];
    copyBasic.forEach( item => {
      item.value = Student[item?.name] ? item?.type === "date" ? moment(Student[item?.name]).format('yyyy-MM-DD') : Student[item?.name] : "";
      if(item.name === "preferredFaculty" && Student?.preferredFaculty?.name) {
        item.value = Student?.preferredFaculty?.name
      } else if(item.name === "status" && Student?.approval_status?.status) {
        item.value = Student?.approval_status?.status
      }
    });
    let copyHome = [...HomeJson];
    let copyOnline = [...OnlineJson];
    let isValueOnline = false;
    let isValueHome = false;
 
    if(specialRates?.length){
      const homeIndex = specialRates?.findIndex(e => e?.classType === "At Home");
      const onlineIndex = specialRates?.findIndex(e => e?.classType === "Online");
      if(homeIndex !== -1){
        copyHome.forEach( item => {
          item.value = (specialRates[homeIndex][item?.name] === 0 || specialRates[homeIndex][item?.name]) ? (item?.type === "date" ? moment(specialRates[homeIndex][item?.name]).format('yyyy-MM-DD') : specialRates[homeIndex][item?.name]) : "";
        });
         //Check Commission
         copyHome = this.checkCommisionErr(copyHome);
         isValueHome = this.checkAllRequired(copyHome);
      }

      if(onlineIndex !== -1){
        copyOnline.forEach( item => {
          item.value = (specialRates[onlineIndex][item?.name] === 0 || specialRates[onlineIndex][item?.name]) ? (item?.type === "date" ? moment(specialRates[onlineIndex][item?.name]).format('yyyy-MM-DD') : specialRates[onlineIndex][item?.name]) : "";
        });
        //Check Commission
        copyOnline = this.checkCommisionErr(copyOnline);
        isValueOnline = this.checkAllRequired(copyOnline);
      }

    }

    this.setState({ isHomeRequired: isValueHome, isOnlineRequired: isValueOnline, basicJson : copyBasic, OnlineJson: copyOnline, HomeJson: copyHome});
  }
  addYears = (dt,n) =>{
    const date = new Date(dt);
  return moment(new Date(date.setFullYear(date.getFullYear() + n))).format('yyyy-MM-DD');      
  }
  isCommisionErr = (copyJSON) => {
    let isValid = true;
    if(copyJSON[0]?.value && copyJSON[1]?.value && copyJSON[2]?.value){
      let rate = parseInt(copyJSON[0]?.value);
      let maxloss = parseInt(Config?.maxLoss);
      let faculty = parseInt(copyJSON[1]?.value);
      let centerHead = parseInt(copyJSON[2]?.value);
      let commission = (faculty + centerHead);
      if(commission > (rate + maxloss)){
        isValid = false;
      }
      else{
        isValid = true;
      }
    }
    return isValid;
  }
  checkCommisionErr = (copyJSON) => {
    if(copyJSON[0]?.value && copyJSON[1]?.value && copyJSON[2]?.value){
      let rate = parseInt(copyJSON[0]?.value);
      let maxloss = parseInt(Config?.maxLoss);
      let faculty = parseInt(copyJSON[1]?.value);
      let centerHead = parseInt(copyJSON[2]?.value);
      let commission = (faculty + centerHead);
  
      if(commission > (rate + maxloss)){
        copyJSON[1].errorText = `${Config?.max_loss_validation_text} ${rate + maxloss}`;
        copyJSON[2].errorText = `${Config?.max_loss_validation_text} ${rate + maxloss}`;
      }
      else{
        copyJSON[1].errorText = ``;
        copyJSON[2].errorText = ``;
      }
    }
    return copyJSON;
  }
  InputBasicChanger = (event, elemIndex) => {
    const copyJSON = [...this.state.basicJson];
    const updatedElement = copyJSON[elemIndex];
    if (updatedElement.type === "file") {
      updatedElement.value = event.target.value;
      updatedElement.file = event.target.files[0];
    } else {
      updatedElement.value = event.target.value;
    }
    const { isValid, errorText } = checkValidity(
      updatedElement.value,
      updatedElement.validation
    );
    updatedElement.valid = isValid;
    updatedElement.errorText = errorText;
    updatedElement.touched = 1;
    this.setState({ basicJson: copyJSON }, () => {});
  };

  InputModuleChanger = async (event, elemIndex) => {
    const copyJSON = [...this.state.moduleFilterJson];
    const updatedElement = copyJSON[elemIndex];
    if (updatedElement.type === "file") {
      updatedElement.value = event.target.value;
      updatedElement.file = event.target.files[0];
    } else {
      updatedElement.value = event.target.value;
    }
    const { isValid, errorText } = checkValidity(
      updatedElement.value,
      updatedElement.validation
    );
    updatedElement.valid = isValid;
    updatedElement.errorText = errorText;
    updatedElement.touched = 1;
    if (elemIndex === 0){
      const startVal = parseInt(copyJSON[0]?.value) * 4 - 3
      const endVal = parseInt(copyJSON[0]?.value) * 4
      let options = []
      for (let i = startVal; i <= endVal; i++) {
        options.push(i)
      }
      options = [...options]
      copyJSON[1].options = options
      copyJSON[1].valid = true
      copyJSON[1].errorText = "" 
      copyJSON[1].touched = 0
    }
    this.setState({ moduleFilterJson: copyJSON }, () => {});
    if (copyJSON[0]?.value && copyJSON[1]?.value) {
      const { getModuleDetails } = this.context;
      const { Student } = this.state;
      await getModuleDetails({month: parseInt(copyJSON[0]?.value), week: parseInt(copyJSON[1]?.value), program: Student.program });
      const { allModules } = this.context;
      this.setState({allModulesData: allModules})
    }
  };

  InputOnlineChanger = (event, elemIndex) => {
    const  { OnlineJson } = this.state;
    let copyJSON = [...OnlineJson];
    let updatedElement = copyJSON[elemIndex];
    if (updatedElement.type === "file") {
      updatedElement.value = event.target.value;
      updatedElement.file = event.target.files[0];
    } else {
      updatedElement.value = event.target.value;
    }
    if(updatedElement?.name === "from"){
      const toElement = copyJSON[4];
      toElement.value = this.addYears(event.target.value, 4);
      toElement.minValue = event.target.value;
      toElement.errorText = "";
    }
    let isValue = this.validateSpecialRateJson(copyJSON);
    if(isValue === false){
      copyJSON = DEFAULT_JSON(copyJSON);
      updatedElement.value = event.target.value;
    }
    const { isValid, errorText } = checkValidity(
      updatedElement.value,
      updatedElement.validation,
      isValue
    );
    updatedElement.valid = isValid;
    updatedElement.errorText = errorText;
    updatedElement.touched = 1;
    //Check Commission
    copyJSON = this.checkCommisionErr(copyJSON);
    this.setState({ isOnlineRequired: isValue, OnlineJson: copyJSON });
  };

  InputHomeChanger = (event, elemIndex) => {
    const { HomeJson } = this.state;
    let copyJSON = [...HomeJson];
    const updatedElement = copyJSON[elemIndex];
    if (updatedElement.type === "file") {
      updatedElement.value = event.target.value;
      updatedElement.file = event.target.files[0];
    } else {
      updatedElement.value = event.target.value;
    }
    if(updatedElement?.name === "from"){
      const toElement = copyJSON[4];
      toElement.value = this.addYears(event.target.value, 4);
      toElement.minValue = event.target.value;
      toElement.errorText = "";
    }
    let isValue = this.validateSpecialRateJson(copyJSON);
    if(isValue === false){
      copyJSON = DEFAULT_JSON(copyJSON);
      updatedElement.value = event.target.value;
    }
    const { isValid, errorText } = checkValidity(
      updatedElement.value,
      updatedElement.validation,
      isValue
    );

    updatedElement.valid = isValid;
    updatedElement.errorText = errorText;
    updatedElement.touched = 1;
    //Check Commission
    copyJSON = this.checkCommisionErr(copyJSON);
    this.setState({ isHomeRequired : isValue, HomeJson: copyJSON });
  };
  validateSpecialRateJson = (Json) => {
    let isValue = false;
    if(Json?.length){
      Json.forEach( item => {
        if(item?.value){
          isValue = true;
        }
      })
    }
    return isValue;
  }
  checkAllRequired = (Json) => {
    let isValue = true;
    if(Json?.length){
      Json.forEach( item => {

        if(item?.name !== "clear" && (!!item?.value)){
          isValue = true;
        }
        else if(item?.name !== "clear" && (!(!!item?.value))){
          isValue = false;
        }
      })
    }
    return isValue;
  }
  handleSave = () => {
    const { basicJson, HomeJson, isHomeRequired, isOnlineRequired, OnlineJson } = this.state;
    const { updateStudentDetails } = this.context;
    const { Parent, Student } = this.state;
    const homeValid = this.isCommisionErr(HomeJson);
    const onlineValid = this.isCommisionErr(OnlineJson);
    const homeAll = this.checkAllRequired(HomeJson);
    const onlineAll = this.checkAllRequired(OnlineJson);
    const basic = isValidatedForm(basicJson);
    const home = (homeValid && isHomeRequired) ? isValidatedForm(HomeJson) : {formValidity: true, validatedForm: null};
    const online = (onlineValid && isOnlineRequired) ? isValidatedForm(OnlineJson) : {formValidity: true, validatedForm: null};
    this.setState({
      basicJson: basic?.validatedForm,
      HomeJson: home?.validatedForm ? home?.validatedForm : HomeJson,
      OnlineJson: online?.validatedForm ? online?.validatedForm : OnlineJson
    });
    if (basic?.formValidity && homeValid && home?.formValidity && onlineValid && online?.formValidity) {
      const postObj = {};
      postObj._id = Student?._id;
      postObj.name = basicJson[0]?.value;
      postObj.dob = basicJson[1]?.value;
      postObj.program = basicJson[2]?.value;
      postObj.type = basicJson[3]?.value;
      postObj.approval_status = {status: basicJson[5]?.value};
      postObj.specialRates = [];
      if(homeAll && home?.formValidity){
        const obj = {};
        obj.classType = "At Home";
        obj.rate = HomeJson[0]?.value;
        obj.toFaculty = HomeJson[1]?.value;
        obj.toCenterhead = HomeJson[2]?.value;
        obj.from = HomeJson[3]?.value;
        obj.to = HomeJson[4]?.value;
        postObj.specialRates.push(obj);
      }
      if(onlineAll && online?.formValidity){
        const obj = {};
        obj.classType = "Online";
        obj.rate = OnlineJson[0]?.value;
        obj.toFaculty = OnlineJson[1]?.value;
        obj.toCenterhead = OnlineJson[2]?.value;
        obj.from = OnlineJson[3]?.value;
        obj.to = OnlineJson[4]?.value;
        postObj.specialRates.push(obj);
      }
      updateStudentDetails(postObj);
      setTimeout(() => {
        const {err, updateStudent} = this.context || {};
        if(err){
          this.handleDialogOpen(err);
        }
        else if(updateStudent?.status === 200){
          const copyParent = {...Parent};
          if(Parent){
            const studentIndex = copyParent?.student?.findIndex(e => e?._id === Student?._id);
            copyParent.student[studentIndex] = updateStudent?.data;
          }
          this.setState({Parent : Parent ? copyParent : null, Student: updateStudent?.data});
          this.handleDialogOpen(updateStudent?.message);
        }
      }, 1000);
    }
  };
  backToParent = () => {
    this.setState({ isBack: true });
  };
  handleDialogOpen = (msg = "") => {
    this.setState({dialogMsg : msg, openDialog : true});
  }
  handleCloseBtn = () => {
    const { updateStudent }  = this.context;
    if(updateStudent){
      this.setState({openDialog : false, isBack: true});
    }
    else{
      this.setState({openDialog : false});
    }
  }
  handleClose = () => {
    this.setState({openDialog : false});
  }
  resetHome = () => {
    this.setState({isHomeRequired: false, HomeJson: DEFAULT_JSON(AtHomeJson)});
  }
  resetOnline = () => {
    this.setState({isOnlineRequired : false, OnlineJson: DEFAULT_JSON(OnlineJson)});
  }

  removeComplete = (event, id) => {
    event.stopPropagation();
    const { removeCompleteModule } = this.context;
    removeCompleteModule({module: id, student_id: this.state.Student._id});
    setTimeout(() => {
      const {removeStudentModule, err} = this.context || {};
      let filteredArray = this.state.completedModules.filter(item => item !== id)
      this.setState({completedModules: filteredArray});
      if(err){
        this.handleDialogOpen(err);
      } else if (removeStudentModule){
        this.handleDialogOpen(removeStudentModule);
      } else {
        this.handleDialogOpen("Modules Updated");
      }
    }, 1000);
  }

  markCompleted = async (event) => {
    event.stopPropagation();
    if (this.state.selectedModules.length){
      const { updateCompletedModule } = this.context;
      await updateCompletedModule({modules: this.state.selectedModules, student_id: this.state.Student._id});
      setTimeout(() => {
        const {updateStudentModule, err} = this.context || {};
        this.setState({completedModules: [...this.state.selectedModules, ...this.state.completedModules]});
        this.setState({selectedModules: []});
        if(err){
          this.handleDialogOpen(err);
        } else if (updateStudentModule){
          this.handleDialogOpen(updateStudentModule);
        } else {
          this.handleDialogOpen("Modules Updated");
        }
      }, 1000);
    } 
  }

  handleClick = (event, id) => {
    event.stopPropagation();
    let checkedModules = this.state.selectedModules;
    if (event.target.checked) {
      this.setState({ selectedModules: [...checkedModules, id] });
    } else {
      let filteredArray = this.state.selectedModules.filter(item => item !== id)
      this.setState({selectedModules: filteredArray});
      console.log(this.state.selectedModules);
    }
  };

  render() {
    const { encodeDecodeString } = this.context;
    const { basicJson, openDialog, dialogMsg, HomeJson, isBack, moduleFilterJson, allModulesData, selectedModules, completedModules } = this.state;
    const isAdmin = encodeDecodeString(window.localStorage.getItem("user"),'decode');
    const { Parent, Student } = this.state;
    let homeClear
    let onlineClear
    if (isAdmin === "centerhead" || isAdmin === "faculty") {
      homeClear = (
        <Button
        disabled
          onClick={this.resetHome}
          size="medium"
          variant={"outlined"}
          color="primary"
        >
          {"Reset"}
        </Button>
    )

    onlineClear = (
      <Button
      disabled
        onClick={this.resetOnline}
        size="medium"
        variant={"outlined"}
        color="primary"
      >
           {"Reset"}
      </Button>
    )
    } else {
      homeClear = (
        <Button
        onClick={this.resetHome}
        size="medium"
        variant={"outlined"}
        color="primary"
      >
        {"Reset"}
      </Button>
      )
      onlineClear =  (
        <Button
          onClick={this.resetOnline}
          size="medium"
          variant={"outlined"}
          color="primary"
        >
             {"Reset"}
        </Button>
      )
    }
    
    if (isBack) {
      if(this.props?.Student?.fromListing) {
        return <AllStudents />
      } else if (Parent === null) {
        return <SingleStudentData showPayments={Student} />;
      } else if (Parent) {
        return <SingleParentData showPayments={Parent} />;
      }
    }
    const d = new Date();
    const minValue = d.getFullYear() + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2);
    HomeJson[3].minValue = minValue;
    HomeJson[4].minValue = minValue;
    OnlineJson[3].minValue = minValue;
    OnlineJson[4].minValue = minValue;
    return (
      <React.Fragment>
        <Box pb={2}>
          <Button
            onClick={this.backToParent}
            type="submit"
            size="large"
            pt={2}
            variant="contained"
            color="primary"
          >
            <KeyboardBackspaceIcon />
          </Button>
        </Box>
        <Box boxShadow={3} mb={1}>
          <Grid container component={Paper}>
            <Grid item xs={12}>
              <Box p={2}>
                <Typography variant="h5">Update Student</Typography>
              </Box>
            </Grid>
            <FormRender isDisable={isAdmin === "faculty" ? true : false} form={basicJson} onChange={this.InputBasicChanger} />

            <Grid item xs={12}>
              <Box p={2}>
                <Typography variant="h5">Update Student Module</Typography>
              </Box>
            </Grid>
            <FormRender form={moduleFilterJson} onChange={this.InputModuleChanger} />
            {allModulesData.length ?
        <div style={{margin: "15px", border: "1px solid", borderRadius: "6px", width: "100%"}}>
        <Table style={{backgroundColor: "#fff"}}>
        <TableHead style={{backgroundColor: "#3f51b5", color: "#fff"}}>
      <TableRow style={{color: "#fff"}}>
            { headCells.map((headCell) => (
          <TableCell style={{color: "#fff"}}
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
          >        
          {headCell.label}
          </TableCell>
        ))}
      </TableRow>
      </TableHead>
      <TableBody>
      {allModulesData.map((value, index)=>{
  return (
    <TableRow key={index+1}>
           <TableCell align="left">{value.month}</TableCell>
           <TableCell align="left">{value.week}</TableCell>
           <TableCell align="left">{value.title}</TableCell>
           <TableCell align="left">{value.description}</TableCell>
           <TableCell align="left" className="selectCheckbox" style={{display: 'flex'}}>
           <Checkbox align="center" onChange={event => this.handleClick(event, value._id)} value={value._id} className="selectCheckbox" disabled={completedModules.includes(value._id)} checked={completedModules.includes(value._id) || selectedModules.includes(value._id)}/>
           {isAdmin !== "faculty" &&
           <Button style={!completedModules.includes(value._id) ? {display: 'none'} : {display: 'block', float: 'left','text-decoration': 'underline'}} onClick={event => this.removeComplete(event, value._id)}>Remove</Button>}
           </TableCell>
    </TableRow>  
    );
})}
</TableBody>
</Table>
<div><Button size="large" pt={2} variant="contained" color="primary" onClick={event => this.markCompleted(event)} style={{float: "right", margin: "20px 10px 20px 20px"}}>Mark Completed</Button></div>
</div> : null }
          {isAdmin === "faculty" ? null :
            <Grid item xs={12}>
              <Box ml={1} p={2}>
                <Typography variant="button">
                  {"AT HOME SPECIAL RATE"}
                </Typography>
              </Box>
            </Grid> }
            {isAdmin === "centerhead" || isAdmin === "coo" || isAdmin === "academics" ? 
            <FormRender isDisable={true} sectionMultipleBlock={homeClear} form={HomeJson} onChange={this.InputHomeChanger} />  :
            isAdmin === "faculty" ? null :
            <FormRender sectionMultipleBlock={homeClear} form={HomeJson} onChange={this.InputHomeChanger} /> }
            {isAdmin === "faculty" ? null :
            <Grid item xs={12}>
              <Box ml={1} p={2}>
                <Typography variant="button">
                  {"ONLINE SPECIAL RATE"}
                </Typography>
              </Box>
            </Grid> }
            {isAdmin === "centerhead" || isAdmin === "coo" || isAdmin === "academics" ? 
            <FormRender isDisable={true} sectionMultipleBlock={onlineClear} form={OnlineJson} onChange={this.InputOnlineChanger} /> :
            isAdmin === "faculty" ? null :
            <FormRender sectionMultipleBlock={onlineClear} form={OnlineJson} onChange={this.InputOnlineChanger} /> }
            <Grid item xs={12}>
              <Box p={2}>
                <Button
                  size="large"
                  pt={2}
                  disabled={isAdmin === "faculty" ? true : false}
                  onClick={this.handleSave}
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Dialog
          open={openDialog}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle align="center" id="responsive-dialog-title">
            {"Message"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button onClick={this.handleCloseBtn} autoFocus color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default EditStudent;
