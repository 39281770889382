import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { Box, Grid, Button } from '@material-ui/core';
// import ClassDetail from './ClassDetail'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import AllClasses from './AllClasses';
import { GlobalContext } from "../../context/GlobalState";
import EditClass from './EditClass';
import ClassDetails from './ClassDetails';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //backgroundColor: theme.palette.background.paper,
    backgroundColor: "antiquewhite"
  },
}));

export default function ViewClass({ classData }) {
  const classes = useStyles();
  const { getSingleParentClassDetails, getParentDetails } = React.useContext(GlobalContext);
  const [value, setValue] = React.useState(0);
  const [back, setBack] = React.useState(false);
  const [editClass, setEditClass] = React.useState(false);
  const [selectedClass, setSelectedClass] = React.useState(null);

  const _id = classData._id

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    getSingleParentClassDetails(_id)
    getParentDetails()
    window.scrollTo(0, 0)
  }, []);

  const backToAllFaculty = () => {
    setBack(true)
  }

  const showData = (arg, id) => {
    setEditClass(true);
    var data = classData;
    setSelectedClass(data);
  };

  if (back) { return <AllClasses /> }

  if (editClass) {
    return <EditClass classData={selectedClass} />;
  }
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Box pb={2}>
          <Button
            onClick={backToAllFaculty}
            type="submit"
            size="large"
            pt={2}
            variant="contained"
            color="primary"
          >
            <KeyboardBackspaceIcon />
          </Button>
          <Typography variant="h6" style={{ display: "inline-block", paddingLeft: "10px", textTransform: "uppercase" }}>{classData.name}</Typography>
          {/* <Tooltip title="Edit" arrow>
            <IconButton
              className="bg5"
              style={{ float: "right" }}
              onClick={e => showData(e.currentTarget.value, _id) } >
              <EditIcon />
            </IconButton>
          </Tooltip> */}
        </Box>

      </Grid>
      <div className={classes.root}>
        <AppBar position="static">
          <Tabs variant="fullWidth" value={value} onChange={handleChange} aria-label="simple tabs example">
            <Tab label="GENERAL INFORMATION" {...a11yProps(0)} />
          </Tabs>
        </AppBar>
        <TabPanel style={{ backgroundColor: "#fff" }} value={value} index={0}>
          <ClassDetails
            classDetail={classData}
          />
        </TabPanel>
      </div>
    </React.Fragment>
  );
}
