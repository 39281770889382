import React from "react";
import { Paper, Grid, Typography, Box, Button } from "@material-ui/core";
import CHNormalDetails from "./CHNormalDetails";
import CHAddressDetails from "./CHAddressDetails";
import CHPaymentDetails from "./CHPaymentDetails";
import CHBankDetails from "./CHBankDetails";
import AllCenterHead from "./AllCenterHead"
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { GlobalContext } from "../../context/GlobalState";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import CenterHeadAllData from './CenterHeadAllData'

function UpdateCenterHead({ showData }) {
 // const v = showData.address.state
  const _id = showData._id;
  // const acnumber = showData.bank.acNumber;
  // const ifsccode = showData.bank.IFSCCode;
  // const bankname = showData.bank.bankName;
  // const aadhar = showData.aadhar;
  // const pan = showData.pan;
  //const location = showData.address.location;
 
  const [name, setName] = React.useState(showData.name);
  const [email, setEmail] = React.useState(showData.email);
  const [mobileNo, setMobile] = React.useState(showData.mobileNo);
  const [password, setPassword] = React.useState(showData.password);
  const [acnumber, setAcnumber] = React.useState(showData.bank.acNumber);
  const [ifsccode, setIfsccode] = React.useState(showData.bank.IFSCCode);
  const [bankname, setBankname] = React.useState(showData.bank.bankName);
  const [aadhar, setAadhar] = React.useState(showData.aadhar);
  const [pan, setPan] = React.useState(showData.pan);
  const [houseno, setHouseno] = React.useState(showData.address.house_no || '');
  const [area, setArea] = React.useState(showData.address.area);
  const [pincode, setPincode] = React.useState(showData.address.pincode);
  const [landmark, setLandmark] = React.useState(showData.address.landmark);
 // const [catchmentradius, setCatchmentRadius] = React.useState(showData.catchmentRadius);
 // const [type, setType] = React.useState(showData.type);
 // const [employmentstatus, setEmploymentStatus] = React.useState(showData.employmentStatus);
  const [centercode, setCentercode] = React.useState(showData.centerCode);
  const [statename, setStateName] = React.useState(showData.address.state);
  const [city, setCity] = React.useState(showData.address.city);
  const [activestatus, setActiveStatus] = React.useState(
    showData.status
  );
  const [open, setOpen] = React.useState(false);
  const [back, setBack] = React.useState(false);
  const [transactionRefNo, setTransactionRefNo]  = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [paymentDate, handlePaymentDate] = React.useState(new Date());
  const [remarks, setRemarks] = React.useState("");

  const { updateCenterHeadDetails, err,  getCenterHeadDetails, updatecenterhead } = React.useContext(GlobalContext);

  const backToCenterHead = () =>{
    setBack(true)
  }
  
  const handleClose = () => {
    setOpen(false);
    if(!err){setBack(true);}
  };

  React.useEffect(() => {
	  getCenterHeadDetails();
  }, []);

  const onSubmit = e => {
    e.preventDefault();
    if ((amount === "" && transactionRefNo !== "") || (amount !== "" && transactionRefNo === "")){
      alert("Please input both Transaction Ref No and Amount or keep both empty");
      return;
    }
    const updatecthead = {
      centerhead_id: _id,
      name: name,
      email: email.toLowerCase().trim(),
      mobileNo: mobileNo,
      latitude:0,
      longitude:0,
      pincode: pincode,
      house_no: houseno ,
      area: area,
      city: city,
      state: statename,
      landmark: landmark,
      centerCode: centercode,
      aadhar: aadhar,
      status: activestatus,
      pan: pan,
      bank: {
        acNumber: acnumber,
        IFSCCode: ifsccode,
        bankName: bankname,
        bankChequeLeaf: false
      },
      debits: [{
        amount: amount,
        bankTransactionRefNum: transactionRefNo,
        date: paymentDate,
        remarks: remarks
      }],
    };
    updateCenterHeadDetails(updatecthead, _id);
    //getCenterHeadDetails();
    setTimeout(function() {setOpen(true)},800);
    //setOpen(true);
  };

  // if(back){ return <CenterHeadAllData showPayments={showData} /> }
  if(back){ 
    if(showData.fromListing) {
      return <AllCenterHead /> 
    }
    if(Object.keys(updatecenterhead).length === 0){
     return <CenterHeadAllData showPayments={showData}/>
    }
     else{
       return <CenterHeadAllData showPayments={updatecenterhead}/> 
     }
   }

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Box pb={2}>
          <Button
            onClick={backToCenterHead}
            type="submit"
            size="large"
            variant="contained"
            color="primary"
          >
          <KeyboardBackspaceIcon/>
          </Button>
        </Box>
      </Grid>
      <form onSubmit={onSubmit}>
        <Box boxShadow={3} mb={1}>
          <Grid container component={Paper}>
            <Grid item xs={12}>
              <Box p={2}>
                <Typography variant="h5">Update Center Head</Typography>
              </Box>
            </Grid>
            <CHNormalDetails
              name={name}
              email={email}
              mobileNo={mobileNo}
              password={password}
              setEmail={setEmail}
              setName={setName}
              setMobile={setMobile}
              setPassword={setPassword}
              activestatus={activestatus}
              setActiveStatus={setActiveStatus}
              formType="updateCenterHead"
            />
            <CHAddressDetails
              houseno={houseno}
              setHouseno={setHouseno}
              area={area}
              setArea={setArea}
              landmark={landmark}
              setLandmark={setLandmark}
              pincode={pincode}
              setPincode={setPincode}
              statename={statename}
              setStateName={setStateName}
              city={city}
              setCity={setCity}
              centercode={centercode}
              setCentercode={setCentercode}
            />
            <CHBankDetails
              bankname={bankname}
              setBankname={setBankname}
              acnumber={acnumber}
              setAcnumber={setAcnumber}
              ifsccode={ifsccode}
              aadhar={aadhar}
              setAadhar={setAadhar}
              pan={pan}
              setPan={setPan}
              setIfsccode={setIfsccode}
            />

            <CHPaymentDetails 
            transactionRefNo={transactionRefNo}
            setTransactionRefNo={setTransactionRefNo}
            amount={amount}
            setAmount={setAmount}
            paymentDate={paymentDate}
            handlePaymentDate={handlePaymentDate}
            remarks={remarks}
            setRemarks={setRemarks}
            />
            <Grid item xs={12}>
              <Box p={2}>
                <Button
                  type="submit"
                  size="large"
                  
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </form>

       <Dialog
        
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle align="center" style={{background: 'antiquewhite'}} id="responsive-dialog-title">{err ? err :"Center Head is updated"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{justifyContent: "center"}} >
          <Button onClick={handleClose} variant="contained" color="primary" >
            Back
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default UpdateCenterHead;
