import React, { useState } from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Grid } from "@material-ui/core";
import FormRender from "../../common/FormHelper/FormRender";
import checkValidity from "../../common/FormHelper/FieldValidator";
import { isValidatedForm, DEFAULT_JSON } from "../../common/FormHelper/FormValidSetter";
import { GlobalContext } from "../../context/GlobalState";

//JSON
import ClassJson from "./Schema/Class.json";

function CancelClass({ classData, handleCloseDialog }) {

  let json = []
  if (classData.students?.length) {
    const checkboxItem = JSON.parse(JSON.stringify(ClassJson.cancelCharge[0]))
    classData.students.forEach(element => {
      json.push({ ...checkboxItem, sm: 6, xs: 6, name: String(element.id._id), label: element.id.name, value: false })
    });
  }
  json = [...json, ...JSON.parse(JSON.stringify(ClassJson.cancel))]

  const { apiCall } = React.useContext(GlobalContext);
  const [formValid, setFormValid] = useState(false);
  const [classJson, setClassJson] = useState(DEFAULT_JSON(json));
  const [cancelClassJson, setCancelClassJson] = useState(DEFAULT_JSON(JSON.parse(JSON.stringify(ClassJson.cancelCharge))));
  const [dialogMsg, setDialogMsg] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [canceClassDialog, setCanceClassDialog] = useState(false);
  const [isChargeble, setIsChargeble] = useState(false);

  React.useEffect(() => { changeStatus(); }, []);

  const onChangeValue = (event, elemIndex) => {
    const copyJSON = [...classJson];
    const updatedElement = copyJSON[elemIndex];
    updatedElement.value = event.target.value;
    const { isValid, errorText } = checkValidity(
      updatedElement.value,
      updatedElement.validation
    );
    updatedElement.valid = isValid;
    updatedElement.errorText = errorText;
    updatedElement.touched = 1;
    const { formValidity } = isValidatedForm(JSON.parse(JSON.stringify(copyJSON.filter(item => item.display !== false))))
    setClassJson(copyJSON);
    setFormValid(formValidity);
  };

  const changeStatus = async () => {
    try {
      const val = await apiCall('GET', `/admin/classes/cancel/${classData?._id}`)
      // setDialogMsg(`Are you sure want to cancel the Koala class?${val.isChargeble ? ' As it is going to be chargeable.' : ''}`);
      // setCanceClassDialog(true);
      if (val.isChargeble) {
        setIsChargeble(true)
      }
    } catch (error) {
      console.log('ERROR for changeStatus', error)
    }
  }

  const handleDialogOpen = (msg = "") => {
    setDialogMsg(msg);
    setCanceClassDialog(false);
    setOpenDialog(true);
  }

  const handleClose = () => {
    setCanceClassDialog(false);
    setOpenDialog(false);
  }

  const handleCloseCancelClassBtn = async () => {
    try {
      let classJsonObj = JSON.parse(JSON.stringify(classJson))
      handleClose()
      const reqData = {
        status: 'cancelled',
        cancellationReason: classJsonObj[classJsonObj.length - 1].value,
        students: classJsonObj.filter(item => item.type == 'checkbox' && item.value == true).map(item => ({ _id: Number(item.name) })),
        chargeable: isChargeble
      }
      if (cancelClassJson[0].value == true) {
        reqData['chargeable'] = false
      }
      const val = await apiCall('post', `/admin/classes/cancel/${classData?._id}`, reqData)
      if (val.status == 200) {
        handleDialogOpen(val.message)
      }
    } catch (error) {
      handleDialogOpen(error)
    }
  }

  const getDialogView = (isCancelClass) => {
    return (
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle align="center" id="responsive-dialog-title">
          {"Message"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {dialogMsg}
          </DialogContentText>
          {/* {isCancelClass && dialogMsg.includes("chargeable") &&
            <FormRender
              form={cancelClassJson}
              onChange={(event) => {
                let cancelClassJsonCopy = [...cancelClassJson]
                cancelClassJsonCopy[0].value = event.target.value
                setCancelClassJson(cancelClassJsonCopy)
              }} />
          } */}
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          {!isCancelClass && <Button onClick={handleCloseDialog} autoFocus color="primary">OK</Button>}
          {isCancelClass &&
            <>
              <Button onClick={handleClose} color="primary">No</Button>
              <Button onClick={handleCloseCancelClassBtn} color="primary">Yes</Button>
            </>
          }
        </DialogActions>
      </Dialog>

    )
  }

  const selectedStudents = classJson.filter(item => item.type == 'checkbox' && item.value === true)
  return (
    <React.Fragment>
      {
        canceClassDialog || openDialog ?
          getDialogView(canceClassDialog)
          :
          <Dialog
            fullWidth={true}
            open={true}
            onClose={handleCloseDialog}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle align="center" id="responsive-dialog-title">
              {"Cancel Class"}
            </DialogTitle>
            <DialogContent>
              <Grid container>
                <FormRender
                  form={classJson}
                  onChange={(onChangeValue)} />
                {isChargeble &&
                  <>
                    <p style={{ marginBottom: 0, padding: '0 16px' }}>This class will be chargeable</p>
                    <FormRender
                      form={cancelClassJson}
                      onChange={(event) => {
                        let cancelClassJsonCopy = [...cancelClassJson]
                        cancelClassJsonCopy[0].value = event.target.value
                        setCancelClassJson(cancelClassJsonCopy)
                      }} />
                  </>
                }
              </Grid>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
              <Button onClick={handleCloseDialog} color="primary">No</Button>
              <Button disabled={!(formValid && selectedStudents.length)} onClick={() => handleCloseCancelClassBtn()} color="primary">Yes</Button>
            </DialogActions>
          </Dialog>
      }
    </React.Fragment>
  );
}

export default CancelClass;
