import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { Box, Grid, Button, TextField } from '@material-ui/core';
import ParentPersonalDetails from './ParentPersonalDetails'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import AllParents from './AllParents'
import { GlobalContext } from "../../context/GlobalState";
import Updateparent from "./Updateparent";
import EditStudent from '../Students/EditStudent';
import AddStudent from "../Students/AddStudent";
import Config from "../../common/config";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //backgroundColor: theme.palette.background.paper,
    backgroundColor: "antiquewhite"
  },
}));

export default function SingleParentData({ showPayments }) {
  const classes = useStyles();
  const { allparents, err, clearErrStudent, deleteStudent, deleteStudentDetails, getSingleParentClassDetails, getParentDetails } = React.useContext(GlobalContext);
  const [value, setValue] = React.useState(0);
  const [allData, setAllData] = React.useState(showPayments);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogMsg, setDialogMsg] = React.useState("");
  const [onupdate, setUpdate] = React.useState(false);
  const [newdata, setNewdata] = React.useState({});
  const [back, setBack] = React.useState(false);
  const [reason, setReason] = React.useState("");
  const [editStudent, setEditStudent] = React.useState(false);
  const [deleteStatus, setDeleteStatus] = React.useState(false);
  const [selectedStudent, setSelectedStudent] = React.useState(null);
  const [addStudent, setAddStudent] = React.useState(false);
  const _id = showPayments._id

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    getSingleParentClassDetails(_id)
    getParentDetails()
    window.scrollTo(0, 0)
  }, []);
  React.useEffect(() => {
    if (err && err !== null) {
      handleDialogOpen(err);
      clearErrStudent();
    }
    else if (deleteStudent !== null && deleteStudent?.status === 200) {
      const copyParent = { ...allData };
      const findIndex = copyParent.student.findIndex(e => e?._id === selectedStudent?._id);
      copyParent.student.splice(findIndex, 1);
      setAllData(copyParent);
      handleDialogOpen(deleteStudent?.message);
      clearErrStudent();
    }
  }, [deleteStudent, err]);
  const backToAllFaculty = () => {
    setBack(true)
  }
  const handleDelete = () => {
    const Obj = {};
    Obj.id = selectedStudent?._id;
    Obj.reason = reason;
    deleteStudentDetails(Obj);
    handleClose();

  }
  const handleClose = () => {
    setDeleteStatus(false);
    setReason("");
  }
  const showData = () => {
    setUpdate(true);
    var data = showPayments;
    setNewdata(data);
  };
  const handleDialogOpen = (msg = "") => {
    setOpenDialog(true);
    setDialogMsg(msg);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  if (onupdate) {
    return <Updateparent showData={newdata} />;
  }

  if (back) { return <AllParents /> }

  if (editStudent) {
    return <EditStudent Parent={showPayments} Student={selectedStudent} />;
  }
  if (addStudent) {
    return <AddStudent Parent={showPayments} />
  }
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Box pb={2}>
          <Button
            onClick={backToAllFaculty}
            type="submit"
            size="large"
            pt={2}
            variant="contained"
            color="primary"
          >
            <KeyboardBackspaceIcon />
          </Button>
          <Typography variant="h6" style={{ display: "inline-block", paddingLeft: "10px", textTransform: "uppercase" }}>{showPayments.name}</Typography>
          {/* <Tooltip title="Edit" arrow>
            <IconButton
              className="bg5"
              style={{ float: "right" }}
              onClick={e =>
                showData(e.currentTarget.value, _id)
              }
            >
              <EditIcon />
            </IconButton>
          </Tooltip> */}
        </Box>

      </Grid>
      <div className={classes.root}>
        <AppBar position="static">
          <Tabs variant="fullWidth" value={value} onChange={handleChange} aria-label="simple tabs example">
            <Tab label="GENERAL INFORMATION" {...a11yProps(0)} />
          </Tabs>
        </AppBar>
        <TabPanel style={{ backgroundColor: "#fff" }} value={value} index={0}>
          <ParentPersonalDetails
            showPayments={allData}
            setEditStudent={setEditStudent}
            setDeleteStudent={setDeleteStatus}
            setAddStudent={setAddStudent}
            setSelectedStudent={setSelectedStudent}
          />
        </TabPanel>
        {deleteStatus && <Dialog
          open={deleteStatus}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={'xs'}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle align="center" id="responsive-dialog-title">
            {`Delete ${selectedStudent?.name}`}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {Config?.delete_conform_text}
            </DialogContentText>
            <TextField
              variant="outlined"
              value={reason}
              label={"Reason"}
              margin="normal"
              fullWidth
              onChange={(e) => setReason(e?.target?.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDelete} autoFocus color="primary">
              Yes
            </Button>
            <Button onClick={handleClose} autoFocus color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>}
        {openDialog && <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle align="center" id="responsive-dialog-title">
            {"Message"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button onClick={handleCloseDialog} autoFocus color="primary">
              OK
                 </Button>
          </DialogActions>
        </Dialog>
        }
      </div>
    </React.Fragment>
  );
}
