import React from "react";
import { Grid, Box, TextField, MenuItem, FormControl, InputLabel, Select, Typography } from "@material-ui/core";
import PhoneInput from 'material-ui-phone-number';
import 'react-phone-input-2/lib/material.css'
import { GlobalContext } from "../../context/GlobalState";

function NormalDetails(props) {
const {encodeDecodeString} = React.useContext(GlobalContext);
const isAdmin = encodeDecodeString(window.localStorage.getItem("user"),'decode');
 const { name, email, mobileNo, setName, setEmail, setMobile, employmentstatus, setEmploymentStatus, type, setType, activestatus, setActiveStatus, paymentstatus, setPaymentStatus } = props
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Box pt={2} px={2}>
          <Typography variant='h6'>Basic Details:</Typography>
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box px={2}>
          <TextField
            label="Name"
            id="fac-name"
            variant="outlined"
            type="text"
            margin="normal"
            fullWidth
            required
            value={name}
            onChange={e => setName(e.target.value)}
            name="name"
            disabled={isAdmin === 'faculty'}
          />
        </Box>
      </Grid>

      <Grid item xs={12} sm={6} style={{ display: "flex", alignItems: "center" }}>
        <Box mt={1} px={2} style={{ width: "100%" }}>
          <PhoneInput
            country={"in"}
            variant="outlined"
            masks={{in:'..........'}}
            value={mobileNo}
            onChange={mobileNo => setMobile(`+${mobileNo}`)}
            required
            name="mobileNo"
            disabled={isAdmin === 'faculty'}
          />
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box px={2}>
          <TextField
            label="Email"
            id=""
            type="email"
            variant="outlined"
            margin="normal"
            fullWidth
            required
            value={email}
            onChange={e => setEmail(e.target.value)}
            name="email"
            disabled={isAdmin === 'faculty'}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box px={2} mt={2}>
          <FormControl variant='outlined' fullWidth required disabled={isAdmin === 'faculty'}>
            <InputLabel id='demo-simple-select-filled-label1' required>
              Employment Status
            </InputLabel>
            <Select
              labelId='demo-simple-select-filled-label1'
              id='demo-simple-select-filled1'
              value={employmentstatus}
              onChange={(e) => setEmploymentStatus(e.target.value)}
              name='employmentStatus'
              label='Employment Status'
            >
              <MenuItem value={"On Roll"}>On Roll </MenuItem>
              <MenuItem value={"Leap"}>Leap</MenuItem>
              <MenuItem value={"Trainee"}>Trainee</MenuItem>
              {/* <MenuItem value={"Left"}>Left</MenuItem> */}
             
            </Select>
          </FormControl>
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box px={2} mt={2}>
          <FormControl variant='outlined' fullWidth required disabled={isAdmin === 'faculty'}>
            <InputLabel id='demo-simple-select-filled-label' required>
              Faculty Type
            </InputLabel>
            <Select
              labelId='demo-simple-select-filled-label'
              id='demo-simple-select-filled'
              value={type || []}
              multiple={true}
              onChange={(e) => setType(e.target.value)}
              name='type'
              label='Faculty Type'
            >
              <MenuItem value={"At Home"}>At Home</MenuItem>
              <MenuItem value={"Online"}>Online </MenuItem>
              <MenuItem value={"Home Pod"}>Home Pod </MenuItem>
              <MenuItem value={"Online Pod"}>Online Pod </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box px={2} mt={2}>
          <FormControl variant='outlined' fullWidth required disabled={isAdmin === 'faculty'}>
            <InputLabel id='activestatus1' required>
              Active Status
            </InputLabel>
            <Select
              labelId='activestatus1'
              id='activestatus1-filled1'
              value={activestatus}
              onChange={(e) => setActiveStatus(e.target.value)}
              name='activeStatus'
              label='Active Status'
            >
              <MenuItem value={"Active"}>Active </MenuItem>
              <MenuItem value={"On Hold"}>On Hold</MenuItem>
              <MenuItem value={"Resigned"}>Resigned</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box px={2} mt={2}>
          <FormControl variant='outlined' style={{ display: isAdmin === "faculty" ? "none" : "flex" }}>
            <InputLabel id='paymentstatus' required>
              Hold Payment
            </InputLabel>
            <Select
              labelId='holdpayment'
              id='holdpayment-filled1'
              value={paymentstatus}
              onChange={(e) => setPaymentStatus(e.target.value)}
              name='activeStatus'
              label='Hold Payment'
            >
              <MenuItem value={"Yes"}>Yes</MenuItem>
              <MenuItem value={"No"}>No</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Grid>

    </React.Fragment>
  );
}

export default NormalDetails;
