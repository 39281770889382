import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { fade, lighten, makeStyles } from '@material-ui/core/styles';
import { Table, InputBase, CircularProgress, Tooltip, IconButton, FormControl, InputLabel, Select, Input, MenuItem } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import SearchIcon from "@material-ui/icons/Search";
import { GlobalContext } from "../../context/GlobalState";
import { VisibilitySharp, Cancel } from '@material-ui/icons';
import ViewClass from './ViewClass';
import EditClass from './EditClass';
import CancelClass from './CancelClass';
import moment from "moment";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'students', disablePadding: true, label: 'Student' },
  { id: 'faculty', label: 'Faculty' },
  { id: 'classPrice', numeric: false, disablePadding: true, label: 'Total Class Price' },
  { id: 'classType', numeric: false, disablePadding: true, label: 'Class Type' },
  { id: 'duration', numeric: false, disablePadding: true, label: 'Duration' },
  { id: 'isDemo', numeric: false, disablePadding: true, label: 'Demo Class' },
  { id: 'classStatus', numeric: false, label: 'Class Status' },
  { id: 'program', numeric: false, disablePadding: true, label: 'Program' },
  { id: 'timeSlotFrom', numeric: false, disablePadding: true, label: 'Date / Time' },
  { id: 'action', label: 'Action' }
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">

        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto"
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit",
    border: '1px solid #dddd',
    margin: '6px 6px 0px 6px',
    borderRadius: '6px'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch"
      }
    }
  },
  tbrow: {
    '&:nth-of-type(odd)': {
      backgroundColor: "#faebd761",
    },
    "&:hover": {
      boxShadow: "0px 2px 5px lightsteelblue",
    },
  }
}));

export default function AllClasses() {
  const classes = useStyles();
  const [selectedStatusType, setSelectedStatusType] = useState("upcoming");
  const [data, setData] = useState([]);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [onpayment, setPayment] = React.useState(false)
  const [spinner, setSpinner] = React.useState(true);
  const [newdata1, setNewdata1] = React.useState({})
  const { allclasses, getClassesDetails, encodeDecodeString } = React.useContext(GlobalContext);
  const [classData, setClassData] = React.useState(false);
  const [viewingClass, setViewClass] = React.useState(false);
  const [editingClass, setEditClass] = React.useState(false);
  const [cancellingClass, setCancelClass] = React.useState(false);
  const isAdmin = encodeDecodeString(window.localStorage.getItem("user"),'decode');
  React.useEffect(() => {
    setData(allclasses);
    setTimeout(() => setSpinner(false), 1000)
  }, [allclasses]);

  React.useEffect(() => {

    let searched = allclasses.filter(
      row =>
        row.classType.includes(searchTerm.toLowerCase()) ||
        row.status.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
        row?.facultyId?.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
        row.program.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
        row?.students?.some(std =>
          std.id.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
        )
    );

    if (searchTerm === "") {
      getClassesDetails(selectedStatusType);
      setData(searched);
    } else {
      setData(searched);
    }
  }, [searchTerm]);

  const filterClasses = (ev) => {
    const { value } = ev.target;
    setSpinner(true);
    setSelectedStatusType(value);
    getClassesDetails(value);
  }

  const handleSearch = event => {
    setSearchTerm(event.target.value);
  };


  const showPayments = (e, id) => {
    setPayment(true)
    //setDetails(false)
    var data1 = allclasses.filter(item => item._id == id)
    setNewdata1(data1[0])
    //getSingleFacultyClassDetails(id)
  };

  const viewClass = (e, classInfo) => {
    setViewClass(true)
    setClassData(classInfo)
  }

  const editClass = (e, classInfo) => {
    setEditClass(true)
    setClassData(classInfo ? classInfo : { adding: true })
  }

  const cancelClass = (e, classInfo) => {
    setCancelClass(true)
    setClassData(classInfo ? classInfo : { adding: true })
  }

  const closeCancelDialog = (e) => {
    setCancelClass(false)
    getClassesDetails(selectedStatusType)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = allclasses.map((n) => n.user.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, allclasses.length - page * rowsPerPage);
  if (viewingClass) { return <ViewClass classData={classData} /> }
  if (editingClass) { return <EditClass classData={{...classData, fromListing: true}} /> }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.search} style={{ display: "inline-block", marginTop: "14px" }}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        <div style={{ float: "right", marginRight: "36px", marginTop: "14px", width: "150px" }}>
          <FormControl fullWidth>
            <InputLabel style={{fontSize: "15px"}} id="class-status-label">Select status</InputLabel>
            <Select style={{fontSize: "13px"}}
              labelId="class-status-label"
              id="class-status"
              value={selectedStatusType || 'upcoming'}
              onChange={filterClasses}
              input={<Input />}
            >
              <MenuItem value="ongoing">Ongoing</MenuItem>
              <MenuItem value="upcoming">Upcoming</MenuItem>
              <MenuItem value="completed">Completed</MenuItem>
              <MenuItem value="cancelled">Cancelled</MenuItem>
            </Select>
          </FormControl>
        </div>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={allclasses.length}
            />

            {spinner ? (
              <TableBody style={{ position: "relative" }}>
                <TableRow>
                  <TableCell>
                    <CircularProgress
                      style={{ padding: "5px", position: "absolute", left: "50%" }}
                      color="primary"
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
                <TableBody>
                  {stableSort(data, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const filteredStudent = row?.students?.filter(e => e?.approval_status?.status !== "Opted Out");
                      let num = filteredStudent?.length > 1 ? ` +${filteredStudent?.length - 1}` : "";
                      return (
                        <TableRow
                          className={classes.tbrow}
                          key={row._id}
                          style={{ cursor: "pointer" }}
                          hover
                          onClick={(e) =>
                            showPayments(e.currentTarget.value, row._id)
                          }
                        >
                          <TableCell padding="checkbox"></TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            {row.students[0].id.name + num}
                          </TableCell>
                          <TableCell>
                          {row?.facultyId?.centerCode}-{row?.facultyId?.name?.toLowerCase()
                              .replace(/\b./g, function (a) {
                                return a.toUpperCase();
                              })}
                          </TableCell>
                          <TableCell>{isAdmin === "faculty" ? "-" : row.totalClassPoints }</TableCell>
                          <TableCell component="th" scope="row" padding="none">{row.classType}</TableCell>
                          <TableCell component="th" scope="row" padding="none">{row.duration}</TableCell>
                          <TableCell component="th" scope="row" padding="none">{row.isDemo ? "Yes" : "No"}</TableCell>
                          <TableCell>{row.status.toLowerCase()
                            .replace(/\b./g, function (a) {
                              return a.toUpperCase();
                            })}</TableCell>
                          <TableCell>
                            {row.program
                              .toLowerCase()
                              .replace(/\b./g, function (a) {
                                return a.toUpperCase();
                              })}
                          </TableCell>
                          <TableCell>{row?.timeSlotFrom?.length ? moment(row.timeSlotFrom.toString()).format("DD/MM/YYYY HH:mm") : 'N/A'}</TableCell>
                          <TableCell>
                            {/* {!(row.status == 'completed' || row.status == 'cancelled') &&
                              <Tooltip title="Edit" arrow>
                                <IconButton disabled={(row.status == 'completed' || row.status == 'cancelled')} className="bg5" className="bg5" onClick={e => editClass(e, row)}>
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                            } */}

                            {!(row.status === 'completed' || row.status === 'cancelled' || row.status === 'ongoing') && isAdmin !== "operations" && isAdmin !== "coo" && isAdmin !== "academics" && isAdmin !== "faculty" &&
                              <Tooltip title="Cancel" arrow>
                                <IconButton
                                  className="bg5" className="bg5" onClick={e => cancelClass(e, row)}>
                                  <Cancel />
                                </IconButton>
                              </Tooltip>
                            }
                            {isAdmin !== "faculty" &&
                            <Tooltip title="View" arrow>
                              <IconButton className="bg5" onClick={e => viewClass(e, row)}>
                                <VisibilitySharp />
                              </IconButton>
                            </Tooltip>
                            }
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 75, 100, 500]}
          component="div"
          count={allclasses.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      { cancellingClass && <CancelClass classData={classData} handleCloseDialog={closeCancelDialog} /> }
    </div>
  );
}
