import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { Box, Grid, Button } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import AllCenterCode from './AllCenterCode';
import Paper from "@material-ui/core/Paper";
import { red } from "@material-ui/core/colors";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //backgroundColor: theme.palette.background.paper,
    backgroundColor: "antiquewhite"
  },
}));

const ccdClassesStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
  },
  pap: {
    backgroundColor: "antiquewhite",
    boxShadow:
      "0px 2px 3px -1px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 1px 0px rgba(0,0,0,0.12)",
    borderRadius: "6px",
    padding: "15px 0px",
  },
  stdname: {
    fontWeight: "500",
    display: "inline-block",
    textTransform: "capitalize",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function ViewCenterCode({ centerCodeData }) {
  const centerCodes = useStyles();
  const ccdClasses = ccdClassesStyles();
  const [value, setValue] = React.useState(0);
  const [back, setBack] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const backToAllFaculty = () => {
    setBack(true)
  }

  if (back) {
    return <AllCenterCode />;
  }

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Box pb={2}>
          <Button
            onClick={backToAllFaculty}
            type="submit"
            size="large"
            pt={2}
            variant="contained"
            color="primary"
          >
            <KeyboardBackspaceIcon />
          </Button>
          <Typography variant="h6" style={{ display: "inline-block", paddingLeft: "10px", textTransform: "uppercase" }}>{centerCodeData.name}</Typography>
        </Box>
      </Grid>
      <div className={centerCodes.root}>
        <AppBar position="static">
          <Tabs variant="fullWidth" value={value} onChange={handleChange} aria-label="simple tabs example">
            <Tab label="GENERAL INFORMATION" {...a11yProps(0)} />
          </Tabs>
        </AppBar>
        <TabPanel style={{ backgroundColor: "#fff" }} value={value} index={0}>
          <div className={ccdClasses.root}>
            <Grid container spacing={3} className={ccdClasses.pap}>
              <Grid item xs={12} sm={6}>
                Area
            <Paper className={ccdClasses.paper}>{centerCodeData?.area}</Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                Center Code
            <Paper className={ccdClasses.paper}>{centerCodeData?.centerCode}</Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                City
            <Paper className={ccdClasses.paper}>{centerCodeData?.city}</Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                State
            <Paper className={ccdClasses.paper}>{centerCodeData?.state}</Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                Status
            <Paper className={ccdClasses.paper}>{centerCodeData?.status}</Paper>
              </Grid>
            </Grid>
          </div>
        </TabPanel>
      </div>
    </React.Fragment>
  );
}
