import React from "react";
import { Paper, Grid, Typography, Box, Button} from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import FormRender from "../../common/FormHelper/FormRender";
import checkValidity from "../../common/FormHelper/FieldValidator";
import { isValidatedForm } from "../../common/FormHelper/FormValidSetter";
import { GlobalContext } from "../../context/GlobalState";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AllInventory from "./AllInventory";
//JSON
import UpdateInventoryData from './Schema/UpdateInventory.json';
class UpdateInventory extends React.Component {
  static contextType = GlobalContext;
  constructor(props){
    super(props);
    this.state = {
      updateInventoryJson : UpdateInventoryData,
      isBack: false,
      dialogMsg: "",
      Parent: props.showData,
      openDialog: false,
      allStateCity: [],
      cityOptions: [],
      disabled : false
    };
  }
  componentDidMount(){
    this.fillDetails();
  }
  fillDetails = () => {
    const { showData } = this.props;
    const { updateInventoryJson } = this.state;
    const copyInventory = [...updateInventoryJson];
        copyInventory.forEach( item => {
            item.value = showData[item?.name] ? showData[item?.name] : "";
        });
      
    this.setState({  updateInventoryJson: copyInventory});
  }

  InputInventoryChanger = (event, elemIndex) => {
    const copyJSON = [...this.state.updateInventoryJson];
    const updatedElement = copyJSON[elemIndex];
    if (updatedElement.type === "file") {
      updatedElement.value = event.target.value;
      updatedElement.file = event.target.files[0];
    } else {
      updatedElement.value = event.target.value;
    }
    const { isValid, errorText } = checkValidity(
      updatedElement.value,
      updatedElement.validation
      );
    updatedElement.valid = isValid;
    updatedElement.errorText = errorText;
    updatedElement.touched = 1;
    this.setState({ updateInventoryJson: copyJSON }, () => {});
}

  handleSave = () => {
    this.setState({disabled: true});
    const { updateInventoryJson } = this.state;
    const { showData } = this.props;
    const { updateInventoryDetails } = this.context;
    
    const inventoryValid = isValidatedForm(updateInventoryJson);
    this.setState({
      updateInventoryJson: inventoryValid?.validatedForm
    });
    if( inventoryValid?.formValidity){
      const postObj = {};
      postObj._id = parseInt(updateInventoryJson[0]?.value)
      postObj.pricing = parseInt(updateInventoryJson[3]?.value)
      postObj.qty_in_stock = parseInt(updateInventoryJson[5]?.value)
      updateInventoryDetails(postObj);
      setTimeout(() => {
        const {err, updateInventory} = this.context || {};
        if(err){
          this.handleDialogOpen(err);
          this.setState({disabled: false});
        }
        else if(updateInventory){
          this.setState({Parent : updateInventory});
          this.handleDialogOpen(`Inventory updated successfully.`);
        }
      }, 1000);
    }
  }
  backToParent = () => {
    this.setState({ isBack : true});
  }
  handleDialogOpen = (msg = "") => {
    this.setState({dialogMsg : msg, openDialog : true});
  }
  handleCloseBtn = () => {
    const { updateInventory }  = this.context;
    if(updateInventory){
      this.setState({openDialog : false, isBack: true});
    }
    else{
      this.setState({openDialog : false});
    }
  }
  handleClose = () => {
    this.setState({openDialog : false});
  }
  render() {
    const { updateInventoryJson, openDialog, dialogMsg, isBack } = this.state;

    if (isBack) {
      // return  this.props.showData?.fromListing ? <AllParents /> : <SingleParentData showPayments={Parent} />;
      return <AllInventory /> ;
    }
    return (
      <React.Fragment>
        <Box pb={2}>
          <Button
            onClick={this.backToParent}
            type="submit"
            size="large"
            pt={2}
            variant="contained"
            color="primary"
          >
            <KeyboardBackspaceIcon />
          </Button>
        </Box>
        <Box boxShadow={3} mb={1}>
          <Grid container component={Paper}>
            <Grid item xs={12}>
              <Box p={2}>
                <Typography variant="h5">Update Inventory</Typography>
              </Box>
            </Grid>
            
            <Grid item xs={12}>
              {/* <Box p={2}>
                <Typography variant="h5">Inventory</Typography>
              </Box> */}
            </Grid>
            <FormRender
              form={updateInventoryJson}
              onChange={this.InputInventoryChanger}
            />
            <Grid item>
              <Box p={2}>
                <Button
                  disabled={this.state.disabled}
                  size="large"
                  pt={2}
                  onClick={this.handleSave}
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </Box>
            </Grid>
            <Grid item>
              <Box p={2}>
                <Button
                  onClick={this.backToParent}
                  type="submit"
                  size="large"
                  pt={2}
                  variant="contained"
                  color="primary"
                >
                  Back
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Dialog
          open={openDialog}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
        >
          <DialogTitle align="center" id="responsive-dialog-title">
            {"Message"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button onClick={this.handleCloseBtn} autoFocus color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }

}

export default UpdateInventory;
