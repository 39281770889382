import React from "react";
import moment from 'moment';
import { Paper, Grid, Typography, Box, Button } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import SingleParentData from "../Parents/SingleParentData";
import FormRender from "../../common/FormHelper/FormRender";
import checkValidity from "../../common/FormHelper/FieldValidator";
import { isValidatedForm, DEFAULT_JSON } from "../../common/FormHelper/FormValidSetter";
import { GlobalContext } from "../../context/GlobalState";
import Config from "../../common/config";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

//JSON
import BasicJson from "./Schema/BasicDetails.json";
import AtHomeJson from "./Schema/AtHomeRate.json";
import OnlineJson from "./Schema/OnlineRate.json";

class AddStudent extends React.Component {
  static contextType = GlobalContext;
  constructor(props) {
    super(props);
    this.state = {
      basicJson: DEFAULT_JSON(BasicJson),
      HomeJson: DEFAULT_JSON(AtHomeJson),
      isHomeRequired: false,
      isOnlineRequired: false,
      OnlineJson: DEFAULT_JSON(OnlineJson),
      isBack: false,
      dialogMsg: "",
      openDialog: false,
      Parent :  props?.Parent,
      allStateCity: [],
      cityOptions: [],
    };
  }
  addYears = (dt,n) =>{
    const date = new Date(dt);
  return moment(new Date(date.setFullYear(date.getFullYear() + n))).format('yyyy-MM-DD');      
  }
  checkCommisionErr = (copyJSON) => {
    if(copyJSON[0]?.value && copyJSON[1]?.value && copyJSON[2]?.value){
      let rate = parseInt(copyJSON[0]?.value);
      let maxloss = parseInt(Config?.maxLoss);
      let faculty = parseInt(copyJSON[1]?.value);
      let centerHead = parseInt(copyJSON[2]?.value);
      let commission = (faculty + centerHead);
      if(commission > (rate + maxloss)){
        copyJSON[1].errorText = `${Config?.max_loss_validation_text} ${rate + maxloss}`;
        copyJSON[2].errorText = `${Config?.max_loss_validation_text} ${rate + maxloss}`;
      }
      else{
        copyJSON[1].errorText = ``;
        copyJSON[2].errorText = ``;
      }
    }
    return copyJSON;
  }
  isCommisionErr = (copyJSON) => {
    let isValid = true;
    if(copyJSON[0]?.value && copyJSON[1]?.value && copyJSON[2]?.value){
      let rate = parseInt(copyJSON[0]?.value);
      let maxloss = parseInt(Config?.maxLoss);
      let faculty = parseInt(copyJSON[1]?.value);
      let centerHead = parseInt(copyJSON[2]?.value);
      let commission = (faculty + centerHead);
      if(commission > (rate + maxloss)){
        isValid = false;
      }
      else{
        isValid = true;
      }
    }
    return isValid;
  }
  InputBasicChanger = (event, elemIndex) => {
    const copyJSON = [...this.state.basicJson];
    const updatedElement = copyJSON[elemIndex];
    if (updatedElement.type === "file") {
      updatedElement.value = event.target.value;
      updatedElement.file = event.target.files[0];
    } else {
      updatedElement.value = event.target.value;
    }
    const { isValid, errorText } = checkValidity(
      updatedElement.value,
      updatedElement.validation
    );
    updatedElement.valid = isValid;
    updatedElement.errorText = errorText;
    updatedElement.touched = 1;
    this.setState({ basicJson: copyJSON }, () => {});
  };

  InputOnlineChanger = (event, elemIndex) => {
    const  { OnlineJson } = this.state;
    let copyJSON = [...OnlineJson];
    let updatedElement = copyJSON[elemIndex];
    if (updatedElement.type === "file") {
      updatedElement.value = event.target.value;
      updatedElement.file = event.target.files[0];
    } else {
      updatedElement.value = event.target.value;
    }
    if(updatedElement?.name === "from"){
      const toElement = copyJSON[4];
      toElement.value = this.addYears(event.target.value, 4);
      toElement.minValue = event.target.value;
      toElement.errorText = "";
    }
    let isValue = this.validateSpecialRateJson(copyJSON);
    if(isValue === false){
      copyJSON = DEFAULT_JSON(copyJSON);
      updatedElement.value = event.target.value;
    }
    const { isValid, errorText } = checkValidity(
      updatedElement.value,
      updatedElement.validation,
      isValue
    );
    updatedElement.valid = isValid;
    updatedElement.errorText = errorText;
    updatedElement.touched = 1;
    //Check Commission
    copyJSON = this.checkCommisionErr(copyJSON);
    this.setState({ isOnlineRequired: isValue, OnlineJson: copyJSON });
  };

  InputHomeChanger = (event, elemIndex) => {
    const { HomeJson } = this.state;
    let copyJSON = [...HomeJson];
    const updatedElement = copyJSON[elemIndex];
    if (updatedElement.type === "file") {
      updatedElement.value = event.target.value;
      updatedElement.file = event.target.files[0];
    } else {
      updatedElement.value = event.target.value;
    }
    if(updatedElement?.name === "from"){
      const toElement = copyJSON[4];
      toElement.value = this.addYears(event.target.value, 4);
      toElement.minValue = event.target.value;
      toElement.errorText = "";
    }
    let isValue = this.validateSpecialRateJson(copyJSON);
    if(isValue === false){
      copyJSON = DEFAULT_JSON(copyJSON);
      updatedElement.value = event.target.value;
    }
    const { isValid, errorText } = checkValidity(
      updatedElement.value,
      updatedElement.validation,
      isValue
    );

    updatedElement.valid = isValid;
    updatedElement.errorText = errorText;
    updatedElement.touched = 1;
    //Check Commission
    copyJSON = this.checkCommisionErr(copyJSON);
    this.setState({ isHomeRequired : isValue, HomeJson: copyJSON });
  };
  validateSpecialRateJson = (Json) => {
    let isValue = false;
    if(Json?.length){
      Json.forEach( item => {
        if(item?.value){
          isValue = true;
        }
      })
    }
    return isValue;
  }
  handleSave = () => {
    const { basicJson, HomeJson, isHomeRequired, isOnlineRequired, OnlineJson } = this.state;
    const { addStudentDetails } = this.context;
    const { Parent } = this.state;
    const homeValid = this.isCommisionErr(HomeJson);
    const onlineValid = this.isCommisionErr(OnlineJson);
    const basic = isValidatedForm(basicJson);
    const home = (isHomeRequired && homeValid) ? isValidatedForm(HomeJson) : {formValidity: true, validatedForm: null};
    const online = (isOnlineRequired && onlineValid) ? isValidatedForm(OnlineJson) : {formValidity: true, validatedForm: null};

    this.setState({
      basicJson: basic?.validatedForm,
      HomeJson: home?.validatedForm ? home?.validatedForm : HomeJson,
      OnlineJson: online?.validatedForm ? online?.validatedForm : OnlineJson
    });
    if (basic?.formValidity && home?.formValidity && homeValid && online?.formValidity && onlineValid) {
      const postObj = {};
      postObj.parent_id = Parent?._id;
      postObj.name = basicJson[0]?.value;
      postObj.dob = basicJson[1]?.value;
      postObj.program = basicJson[2]?.value;
      postObj.type = basicJson[3]?.value;
      postObj.specialRates = [];
      if(isHomeRequired && home?.formValidity){
        const obj = {};
        obj.classType = "At Home";
        obj.rate = HomeJson[0]?.value;
        obj.toFaculty = HomeJson[1]?.value;
        obj.toCenterhead = HomeJson[2]?.value;
        obj.from = HomeJson[3]?.value;
        obj.to = HomeJson[4]?.value;
        postObj.specialRates.push(obj);
      }
      if(isOnlineRequired && online?.formValidity){
        const obj = {};
        obj.classType = "Online";
        obj.rate = OnlineJson[0]?.value;
        obj.toFaculty = OnlineJson[1]?.value;
        obj.toCenterhead = OnlineJson[2]?.value;
        obj.from = OnlineJson[3]?.value;
        obj.to = OnlineJson[4]?.value;
        postObj.specialRates.push(obj);
      }
      addStudentDetails(postObj);
      setTimeout(() => {
        const {err, addStudent} = this.context || {};
        if(err){
          this.handleDialogOpen(err);
        }
        else if(addStudent?.status === 200){
          const copyParent = {...this.state.Parent};
          copyParent.student.push(addStudent?.data);
          this.setState({Parent : copyParent});
          this.handleDialogOpen(addStudent?.message);
        }
      }, 1000);
    }
  };
  backToParent = () => {
    this.setState({ isBack: true });
  };
  handleDialogOpen = (msg = "") => {
    this.setState({dialogMsg : msg, openDialog : true});
  }
  handleCloseBtn = () => {
    const { addStudent }  = this.context;
    if(addStudent){
      this.setState({openDialog : false, isBack: true});
    }
    else{
      this.setState({openDialog : false});
    }
  }
  handleClose = () => {
    this.setState({openDialog : false});
  }
  resetHome = () => {
    this.setState({isHomeRequired: false, HomeJson: DEFAULT_JSON(AtHomeJson)});
  }
  resetOnline = () => {
    this.setState({isOnlineRequired : false, OnlineJson: DEFAULT_JSON(OnlineJson)});
  }
  render() {
    const { encodeDecodeString } = this.context;
    const { basicJson, Parent, openDialog, dialogMsg, HomeJson, isBack } = this.state;
    const isAdmin = encodeDecodeString(window.localStorage.getItem("user"),'decode');
    let homeClear
    let onlineClear
    if (isAdmin === "centerhead") {
      homeClear = (
        <Button
        disabled
          onClick={this.resetHome}
          size="medium"
          variant={"outlined"}
          color="primary"
        >
          {"Reset"}
        </Button>
    )

    onlineClear = (
      <Button
      disabled
        onClick={this.resetOnline}
        size="medium"
        variant={"outlined"}
        color="primary"
      >
           {"Reset"}
      </Button>
    )
    } else {
      homeClear = (
        <Button
        onClick={this.resetHome}
        size="medium"
        variant={"outlined"}
        color="primary"
      >
        {"Reset"}
      </Button>
      )
      onlineClear = (
        <Button
          onClick={this.resetOnline}
          size="medium"
          variant={"outlined"}
          color="primary"
        >
             {"Reset"}
        </Button>
      )
    }

    if (isBack) {
      return <SingleParentData showPayments={Parent} />;
    }
    return (
      <React.Fragment>
        <Box pb={2}>
          <Button
            onClick={this.backToParent}
            type="submit"
            size="large"
            pt={2}
            variant="contained"
            color="primary"
          >
            <KeyboardBackspaceIcon />
          </Button>
        </Box>
        <Box boxShadow={3} mb={1}>
          <Grid container component={Paper}>
            <Grid item xs={12}>
              <Box p={2}>
                <Typography variant="h5">Add New Student</Typography>
              </Box>
            </Grid>
            <FormRender form={basicJson} onChange={this.InputBasicChanger} />
            <Grid item xs={12}>
              <Box ml={1} p={2}>
                <Typography variant="button">
                  {"AT HOME SPECIAL RATE"}
                </Typography>
              </Box>
            </Grid>
            {isAdmin === "centerhead" ? <FormRender isDisable={true} sectionMultipleBlock={homeClear} form={HomeJson} onChange={this.InputHomeChanger} /> :
            <FormRender sectionMultipleBlock={homeClear} form={HomeJson} onChange={this.InputHomeChanger} /> }
            <Grid item xs={12}>
              <Box ml={1} p={2}>
                <Typography variant="button">
                  {"ONLINE SPECIAL RATE"}
                </Typography>
              </Box>
            </Grid>
            {isAdmin === "centerhead" ? <FormRender isDisable={true} sectionMultipleBlock={onlineClear} form={OnlineJson} onChange={this.InputOnlineChanger} /> :
            <FormRender sectionMultipleBlock={onlineClear} form={OnlineJson} onChange={this.InputOnlineChanger} /> }
            <Grid item xs={12}>
              <Box p={2}>
                <Button
                  size="large"
                  pt={2}
                  onClick={this.handleSave}
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Dialog
          open={openDialog}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle align="center" id="responsive-dialog-title">
            {"Message"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button onClick={this.handleCloseBtn} autoFocus color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default AddStudent;
