import React, { useContext, useState } from "react";
import { Container, Box } from "@material-ui/core";
//import logo from '../media/Logo-footer.png'
import { Typography, TextField, Button } from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import Avatar from "@material-ui/core/Avatar";
import { GlobalContext } from "../../context/GlobalState";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import "./MyLogin.css";
import Login from "./Login";
import {Link as LinkRouter} from 'react-router-dom'

export const EmailVerify = (props) => {
  const [email, setEmail] = React.useState("");
  const [resetpassword, setResetPassword] = React.useState("");
  const [code, setVerfCode] = React.useState("");
  const [back, setBack] = React.useState(false);
  const {
    EmailVerification,
    err,
    emailCheck,
    CodeVerification,
    codeCheck,
    PasswordVerification,
    passwordCheck
  } = useContext(GlobalContext);

  const onSubmit = e => {
    e.preventDefault();
    const userDetails = {
      email
    };
    EmailVerification(userDetails);
  };

  const onSubmitCode = e => {
    e.preventDefault();
    const verCode = {
      code: code,
      email: email
    };
    CodeVerification(verCode);
  };

  const onSubmitPassword = e => {
    e.preventDefault();
    const restPassword = {
      email: email,
      password: resetpassword
    };
    PasswordVerification(restPassword);
    // setBack(true);
  };

  const handleClose = () => {
    setBack(true);
    props.history.push("/");
  };

  if (back) {
    return <Login />;
  }

  if (emailCheck) {
    document.getElementById("first").style.display = "none";
  }
  if (codeCheck) {
    document.getElementById("second").style.display = "none";
  }
  return (
    <div className="bt">
      <Container maxWidth="xs" mt="2">
        <br></br> <br></br>
        <Box bgcolor="#ffffff" boxShadow="3" p={4} textAlign="center" borderRadius={16}>
          <Box pb={2} textAlign="left">
            <Button onClick={handleClose} type="submit" size="small">
              <KeyboardBackspaceIcon />
            </Button>
          </Box>
          <Avatar className="avatarFix" style={{ margin: "0 auto" }}>
            <LockIcon />
          </Avatar>
          <br></br>

          <form onSubmit={onSubmit} id="first">
            <Typography variant="h5" mt="2" pt="3">
              Email Verification
            </Typography>

            <TextField
              label="Email"
              required
              variant="outlined"
              margin="normal"
              fullWidth
              value={email}
              onChange={e => setEmail(e.target.value)}
              name="email"
            />

            <br></br><br></br>
            <Button fullWidth type="submit" size="large" pt={2} variant="contained" color="primary">
              Verify
            </Button>

            <h5 color="red">{err ? err : emailCheck}</h5>
          </form>

          {emailCheck ? (
            <form onSubmit={onSubmitCode} id="second">
              <Typography variant="h5" mt="2" pt="3">
                Code Verification
              </Typography>
              <TextField
                label="Verification Code"
                required
                variant="outlined"
                margin="normal"
                fullWidth
                value={code}
                onChange={e => setVerfCode(e.target.value)}
                name="code"
              />
              <br></br>
              <Button fullWidth type="submit" size="large" variant="contained" color="primary">
                Verify
              </Button>

              <h5 color="red">{err ? err : codeCheck}</h5>
            </form>
          ) : (
            ""
          )}

          {codeCheck ? (
            <form onSubmit={onSubmitPassword} id="third">
              <Typography variant="h5" mt="2" pt="3">
                Enter New Password
              </Typography>
              <TextField
                label="Password"
                type="password"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                value={resetpassword}
                onChange={e => setResetPassword(e.target.value)}
                name="password"
              />
              <br></br>
              <Button fullWidth type="submit" size="large" variant="contained" color="primary">
                submit
              </Button>

              <h5 color="red">{err ? err : passwordCheck}</h5>
            </form>
          ) : (
            ""
          )}
          {passwordCheck ? (<Typography>
              <LinkRouter to="/" style={{cursor:"pointer"}}>Back to Login</LinkRouter>
          </Typography>) : ("")}
        </Box>
      </Container>
    </div>
  );
};
export default EmailVerify;
