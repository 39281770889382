import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { fade, lighten, makeStyles } from "@material-ui/core/styles";
import {Table, InputBase, Box, Button, CircularProgress, Tooltip, IconButton, FormControl, InputLabel, Select, Input, MenuItem } from '@material-ui/core';
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import UpdateCenterHead from "./UpdateCenterHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import { GlobalContext } from "../../context/GlobalState";
import AddCenterHead from "./AddCenterHead";
import CenterHeadAllData from "./CenterHeadAllData";
import UpdateCenterCode from "../Center Code/UpdateCenterCode";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  { id: "name", label: "Name" },
  { id: "email", label: "Email" },
  { id: "mobileNo", label: "Contact" },
  { id: "city", label: "City" },
  { id: "centerCode", label: "Center Code" },
  { id: "status", label: "Status" },
  { id: "action", label: "Action" }
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85)
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark
      },
  title: {
    flex: "1 1 100%"
  }
}));

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto"
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit",
    border: '1px solid #dddd',
    margin: '6px 6px 0px 6px',
    borderRadius: '6px'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch"
      }
    }
  }
}));

export default function AllCenterHead() {
  const classes = useStyles();
  const { allcenterhead, getCenterHeadDetails, encodeDecodeString } = React.useContext(
    GlobalContext
  );
  const [selectedStatusType, setSelectedStatusType] = useState("Active");
  const [data, setData] = useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [spinner, setSpinner] = React.useState(true);
  const [onpayment, setPayment] = React.useState(false);
  const [newdata1, setNewdata1] = React.useState({});
  const [addcthead, setAddcthead] = React.useState(false);
  const [updatingCenterHead, setUpdatingCenterHead] = React.useState(false);
  const isAdmin = encodeDecodeString(window.localStorage.getItem("user"),'decode');
  const showPayments = (e, id) => {
    setPayment(true);
    var data1 = allcenterhead.filter(item => item._id == id);
    setNewdata1(data1[0]);
  };

  const editCenterHead = (e, id) => {
    var data1 = allcenterhead.filter(item => item._id == id);
    setNewdata1({...data1[0], fromListing: true})
    setUpdatingCenterHead(true)
  };

  React.useEffect(() => {
    setData(allcenterhead);
    setTimeout(() => setSpinner(false), 3000);
  }, [allcenterhead]);

  React.useEffect(() => {
    let searched = allcenterhead.filter(
      row =>
        row.mobileNo.includes(searchTerm) ||
        row.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
        row.email.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
        (row.address.city &&
          row.address.city.toLowerCase().indexOf(searchTerm.toLowerCase()) >
          -1) ||
        row.centerCode.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
    );

    if (searchTerm === "") {
      getCenterHeadDetails(selectedStatusType);
      setData(searched);
    } else {
      setData(searched);
    }
  }, [searchTerm]);

  const handleSearch = event => {
    setSearchTerm(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = allcenterhead.map(n => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const filterCenterhead = (ev) => {
    const { value } = ev.target;
    setSpinner(true);
    setSelectedStatusType(value);
    getCenterHeadDetails(value);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const goToAddcenterhead = () => {
    setAddcthead(true);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, allcenterhead.length - page * rowsPerPage);


  if (onpayment) {
    return <CenterHeadAllData showPayments={newdata1} />;
  }

  if (addcthead) {
    return <AddCenterHead />;
  }
  if(updatingCenterHead){ return <UpdateCenterHead showData={newdata1} /> }
  return (
    <div className={classes.root}>
      <Box display="flex" pb={2}>
        <Box flexGrow={1}>
        {isAdmin !== "operations" && isAdmin !== "centerhead" && isAdmin !== "coo" && isAdmin !== "academics" && isAdmin !== "marketing" ?
          <Button
            onClick={goToAddcenterhead}
            type="submit"
            size="large"
            pt={2}
            variant="contained"
            color="primary"
          >
            Add New Center Head
          </Button> : ""}
        </Box>
      </Box>

      <Paper className={classes.paper}>
        <div className={classes.search} style={{ display: "inline-block" }}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
            inputProps={{ "aria-label": "search" }}
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        <div style={{ float: "right", marginRight: "36px", marginTop: "14px", width: "150px" }}>
          <FormControl fullWidth>
            <InputLabel style={{fontSize: "15px"}} id="class-status-label">Select status</InputLabel>
            <Select style={{fontSize: "13px"}}
              labelId="class-status-label"
              id="class-status"
              value={selectedStatusType || 'Active'}
              onChange={filterCenterhead}
              input={<Input />}
            >
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="On Hold">On Hold</MenuItem>
              <MenuItem value="Resigned">Resigned</MenuItem>
            </Select>
          </FormControl>
        </div>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={allcenterhead.length}
            />
            {spinner ? (
              <TableBody style={{ position: "relative" }}>
                <TableRow>
                  <TableCell>
                    <CircularProgress
                      style={{ padding: "5px", position: "absolute", left: "50%" }}
                      color="primary"
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
                <TableBody>
                  {stableSort(data, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      // const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          key={row._id}
                          style={{ cursor: "pointer" }}
                          hover
                        >
                          <TableCell padding="checkbox"></TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.name}
                          </TableCell>
                          <TableCell>{row.email.toLowerCase()}</TableCell>
                          <TableCell>{row.mobileNo}</TableCell>
                          <TableCell>{row.address.city}</TableCell>
                          <TableCell>{row.centerCode}</TableCell>
                          <TableCell>{row.status}</TableCell>
                          <TableCell >
                            <Tooltip title="Edit" arrow>
                              <IconButton
                                disabled={isAdmin === 'marketing' || isAdmin === 'coo' || isAdmin === 'academics'}
                                className="bg5"
                                onClick={e => editCenterHead(e.currentTarget.value, row._id)} >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="View" arrow>
                              <IconButton
                                className="bg5"
                                onClick={e => showPayments(e.currentTarget.value, row._id)} >
                                <VisibilityIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 75, 100, 500]}
          component="div"
          count={allcenterhead.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
