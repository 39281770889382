import React from "react";
import { Paper, Grid, Typography, Box, Button} from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import FormRender from "../../common/FormHelper/FormRender";
import checkValidity from "../../common/FormHelper/FieldValidator";
import { isValidatedForm } from "../../common/FormHelper/FormValidSetter";
import { GlobalContext } from "../../context/GlobalState";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AllModules from "./AllModules";
//JSON
import UpdateModuleData from './Schema/UpdateModule.json';
class UpdateModule extends React.Component {
  static contextType = GlobalContext;
  constructor(props){
    super(props);
    this.state = {
      UpdateModuleJson : UpdateModuleData,
      isBack: false,
      dialogMsg: "",
      Parent: props.showData,
      openDialog: false,
      allStateCity: [],
      cityOptions: [],
      disabled : false
    };
  }
  componentDidMount(){
    this.fillDetails();
  }
  fillDetails = () => {
    const { showData } = this.props;
    const { UpdateModuleJson } = this.state;
    const copyInventory = [...UpdateModuleJson];
        copyInventory.forEach( item => {
            item.value = showData[item?.name] ? showData[item?.name] : "";
        });
    copyInventory[6].value = showData.active ? "Yes" : "No"
    this.setState({  UpdateModuleJson: copyInventory});
  }

  InputModuleChanger = (event, elemIndex) => {
    const copyJSON = [...this.state.UpdateModuleJson];
    const updatedElement = copyJSON[elemIndex];
    if (updatedElement.type === "file") {
      updatedElement.value = event.target.value;
      updatedElement.file = event.target.files[0];
    } else {
      updatedElement.value = event.target.value;
    }
    const { isValid, errorText } = checkValidity(
      updatedElement.value,
      updatedElement.validation
      );    
    updatedElement.valid = isValid;
    updatedElement.errorText = errorText;
    updatedElement.touched = 1;
    this.setState({ UpdateModuleJson: copyJSON }, () => {});
}

  handleSave = () => {
    this.setState({disabled: true});
    const { UpdateModuleJson } = this.state;
    const { showData } = this.props;
    const { updateModuleDetails } = this.context;
    
    const inventoryValid = isValidatedForm(UpdateModuleJson);
    this.setState({
      UpdateModuleJson: inventoryValid?.validatedForm
    });
    if( inventoryValid?.formValidity){
      const postObj = {};
      postObj._id = parseInt(UpdateModuleJson[0]?.value)
      postObj.title = UpdateModuleJson[4]?.value
      postObj.description = UpdateModuleJson[5]?.value
      postObj.active = UpdateModuleJson[6]?.value === "Yes" ? true : false
      updateModuleDetails(postObj);
      setTimeout(() => {
        const {err, updateModule} = this.context || {};
        if(err){
          this.handleDialogOpen(err);
          this.setState({disabled: false});
        }
        else if(updateModule){
          this.setState({Parent : updateModule});
          this.handleDialogOpen('Module updated successfully.');
        }
      }, 1000);
    }
  }
  backToParent = () => {
    this.setState({ isBack : true});
  }
  handleDialogOpen = (msg = "") => {
    this.setState({dialogMsg : msg, openDialog : true});
  }
  handleCloseBtn = () => {
    const { updateModule }  = this.context;
    if(updateModule){
      this.setState({openDialog : false, isBack: true});
    }
    else{
      this.setState({openDialog : false});
    }
  }
  handleClose = () => {
    this.setState({openDialog : false});
  }
  render() {
    const { UpdateModuleJson, openDialog, dialogMsg, isBack } = this.state;

    if (isBack) {
      return <AllModules /> ;
    }
    return (
      <React.Fragment>
        <Box pb={2}>
          <Button
            onClick={this.backToParent}
            type="submit"
            size="large"
            pt={2}
            variant="contained"
            color="primary"
          >
            <KeyboardBackspaceIcon />
          </Button>
        </Box>
        <Box boxShadow={3} mb={1}>
          <Grid container component={Paper}>
            <Grid item xs={12}>
              <Box p={2}>
                <Typography variant="h5">Update Module</Typography>
              </Box>
            </Grid>
            <FormRender
              form={UpdateModuleJson}
              onChange={this.InputModuleChanger}
            />
            <Grid item>
              <Box p={2}>
                <Button
                  disabled={this.state.disabled}
                  size="large"
                  pt={2}
                  onClick={this.handleSave}
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </Box>
            </Grid>
            <Grid item>
              <Box p={2}>
                <Button
                  onClick={this.backToParent}
                  type="submit"
                  size="large"
                  pt={2}
                  variant="contained"
                  color="primary"
                >
                  Back
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Dialog
          open={openDialog}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
        >
          <DialogTitle align="center" id="responsive-dialog-title">
            {"Message"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button onClick={this.handleCloseBtn} autoFocus color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }

}

export default UpdateModule;
