import React from "react";
import { Paper, Grid, Typography, Box, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import FormRender from "../../common/FormHelper/FormRender";
import checkValidity from "../../common/FormHelper/FieldValidator";
import { isValidatedForm, DEFAULT_JSON } from "../../common/FormHelper/FormValidSetter";
import { GlobalContext } from "../../context/GlobalState";
import moment from "moment";

//JSON
import ClassJson from "./Schema/Class.json";
import AllClasses from "./AllClasses";
import ViewClass from "./ViewClass";
import AllStudents from "../Students/AllStudents";

const classDuration = {
  "30 minutes": .5,
  "45 minutes": .75,
  "1 hour": 1,
  "1 hour 30 minutes": 1.5,
  "2 hours": 2,
  "3 hours": 3
}

class EditClass extends React.Component {
  static contextType = GlobalContext;
  constructor(props) {
    const classJson = JSON.parse(JSON.stringify(ClassJson.add))
    classJson[2].minValue = moment().format('yyyy-MM-DD');
    super(props);
    this.state = {
      lastUpcomingClass: [],
      isBack: false,
      formValid: false,
      classJson: DEFAULT_JSON(classJson)
    };
  }

  componentDidMount() {
    this.getDropDownInformation()
  }

  getDropDownInformation = async () => {
    let classJson = JSON.parse(JSON.stringify(this.state.classJson))
    let setStateObj = {}
    let classData = {}
    if (this.props?.student?._id) {
      classData.students = [{ name: this.props.student.name, value: this.props.student._id }]
      classData.program = this.props?.student.program
      const pgIndex = classJson.findIndex(item => item.name == "program")
      classJson[pgIndex]['disabled'] = true
      classJson[pgIndex]['touched'] = 1
    }
    const { getStudentDetails } = this.context;
    let query = [
      getStudentDetails()
    ]
    await Promise.all(query)
    let { allstudents } = this.context;
    classJson.forEach((item, index) => {
      if (item.display) {
        if (item.name === 'students' && Array.isArray(allstudents)) {
          allstudents = allstudents.filter(std => std.approval_status.status === 'Active')
          item.options = [...allstudents.map(f => ({ name: f.name, value: f._id }))]
          setStateObj = { ...setStateObj, allstudents }
        }
        item.value = classData[item.name] || item.value
      }
    })
    const { formValidity } = isValidatedForm(JSON.parse(JSON.stringify(classJson.filter(item => item.display !== false))))
    this.setState({ ...setStateObj, classJson, formValid: formValidity })
    this.getUpcomingClasses()
  }

  getUpcomingClasses = async () => {
    if (this.props?.student?._id) {
      try {
        const { apiCall } = this.context
        const classJsonCopy = JSON.parse(JSON.stringify(this.state.classJson))
        const upcomingReq = { students: [] }

        let students = classJsonCopy.filter(item => item.name === 'students')[0].value;
        if (students.length) {
          upcomingReq.students.push(...students.map(item => ({ _id: item.value })))
        } else if (this.props?.student?._id) {
          upcomingReq.students.push({ _id: this.props?.student?._id })
        }
        let res = await apiCall('POST', `/admin/classes/upcoming/student`, upcomingReq)
        if (res.data && res.data.length) {
          res = res.data;
          const resFirst = res[0];
          if (resFirst?.preferredFaculty?._id) {
            const options = [{ name: resFirst?.preferredFaculty?.name, value: resFirst?.preferredFaculty?._id }]
            classJsonCopy[9].options = options 
            classJsonCopy[9].value = options[0]
          }
          // if (resFirst?.last_upcoming_class?.classType?.length) {
          //   classJsonCopy[0].value = resFirst.last_upcoming_class.classType
          // }
          if (!classJsonCopy[0].value && resFirst?.last_upcoming_class?.classType) {
            classJsonCopy[0].value = resFirst.last_upcoming_class.classType
          }
          const lastUpcomingClass = []
          res.forEach(element => {
            const upComingStudent = { name: element.student?.name, time: "N/A" }
            upComingStudent.time = element?.last_upcoming_class ? moment(element?.last_upcoming_class?.timeSlotFrom.toString()).format("DD/MM/YYYY HH:mm") : 'No upcoming class'
            console.log(upComingStudent)
            lastUpcomingClass.push(upComingStudent)
          });
          this.setState({ classJson: classJsonCopy, lastUpcomingClass })
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  onChangeValue = (event, elemIndex) => {
    const copyJSON = [...this.state.classJson];
    const updatedElement = copyJSON[elemIndex];
    updatedElement.value = event.target.value;
    let { isValid, errorText } = checkValidity(
      updatedElement.value,
      updatedElement.validation
    );
    if (updatedElement.name === 'students' && event.target?.value?.length > 8) {
      isValid = false;
      errorText = "Max 8 students can be selected";
    }
    updatedElement.valid = isValid;
    updatedElement.errorText = errorText;
    updatedElement.touched = 1;
    copyJSON[elemIndex] = updatedElement;
    const { formValidity } = isValidatedForm(JSON.parse(JSON.stringify(copyJSON.filter(item => item.display !== false))))
    this.setState({ classJson: copyJSON, formValid: (formValidity && isValid) }, () => {
      if (!isValid || copyJSON.filter(item => item.name === 'students')[0]?.value?.length > 4) {
        return;
      }
      if (updatedElement.name !== 'facultyId' && (!this.props.classData?._id || (
        updatedElement.name === 'students' ||
        updatedElement.name === 'classType' ||
        updatedElement.name === 'duration' ||
        updatedElement.name === 'dateFrom' ||
        updatedElement.name === 'program'
      ))) {
        this.getFacultyDetailsForAddClassIfFormValidInCaseCreate()
      }
      if (updatedElement.name === 'students') {
        this.getUpcomingClasses()
      }
    });
  }

  getFacultyDetailsForAddClassIfFormValidInCaseCreate = async () => {
    let classJson = JSON.parse(JSON.stringify(this.state.classJson))
    let students = classJson.filter(item => item.name === 'students')[0].value
    let classType = classJson.filter(item => item.name === 'classType')[0].value
    let dateFrom = classJson.filter(item => item.name === 'dateFrom')[0].value
    let classCount = classJson.filter(item => item.name === 'classCount')[0].value
    let excludeWeekend = classJson.filter(item => item.name === 'excludeWeekend')[0].value
    let isDemo = classJson.filter(item => item.name === 'isDemo')[0].value
    let timeFrom = classJson.filter(item => item.name === 'timeFrom')[0].value
    let duration = classJson.filter(item => item.name === 'duration')[0].value
    let program = classJson.filter(item => item.name === 'program')[0].value
    let valid = true
    if (!(
      (students.length) &&
      (classType.length) &&
      (dateFrom.length) &&
      (timeFrom.length) &&
      (classCount > 0) &&
      (duration.length) &&
      (program.length)
    )) {
      valid = false
    }
    if (valid) {
      const reqParams = {
        students: students.map(std => ({ _id: std.value, parent: this.state.allstudents.filter(item => item._id === std.value)[0]?.parent?._id })),
        classType: classType,
        dateFrom: dateFrom,
        classCount: Number(classCount),
        excludeWeekend: excludeWeekend,
        isDemo: isDemo,
        timeFrom: timeFrom,
        duration: classDuration[duration],
        program: program,
        preferredFaculty: classJson[9].value?.value
      }
      const { getFacultyDetailsForAddClass } = this.context;
      await getFacultyDetailsForAddClass(reqParams)
      const { allFacultyForAddClass, err } = this.context;
      setTimeout(() => {
        const classJsonCopy = JSON.parse(JSON.stringify(this.state.classJson))
        if (allFacultyForAddClass.length) {
          // let options = allFacultyForAddClass.length ? new Set([...allFacultyForAddClass.map(f => ({ name: f.name, value: f._id })), ...classJsonCopy[7].options]) : []
          // let options = allFacultyForAddClass.length ? new Set([...allFacultyForAddClass.map(f => ({ name: f.name, value: f._id })), ...classJsonCopy[7].options]) : []
          let options = allFacultyForAddClass.length ? new Set([...allFacultyForAddClass.map(f => ({ name: f.name, value: f._id }))]) : []
          options = [...options]
          classJsonCopy[9].options = options 
          classJsonCopy[9].value = options.length ? options[0] : classJsonCopy[9].value
          classJsonCopy[9].valid = true
          classJsonCopy[9].errorText = "" 
          classJsonCopy[9].touched = 0
        } else {
          classJsonCopy[9].value = ""
          classJsonCopy[9].options = []
          if (err) {
            classJsonCopy[9].valid = false 
            classJsonCopy[9].errorText = err 
            classJsonCopy[9].touched = 1
          }
        }

        const { formValidity } = isValidatedForm(JSON.parse(JSON.stringify(classJsonCopy.filter(item => item.display !== false))))
        this.setState({ classJson: classJsonCopy, formValid: formValidity })
      }, 100);
    }

  }

  checkAllRequired = (json) => {
    let isValue = true;
    if (json?.length) {
      json.forEach(item => {
        if (item?.name !== "clear" && (!!item?.value)) {
          isValue = true;
        }
        else if (item?.name !== "clear" && (!(!!item?.value))) {
          isValue = false;
        }
      })
    }
    return isValue;
  }

  createClass = async (scheduleAvailableClasses = false) => {
    
    this.setState({ formValid: false })
    let classJson = JSON.parse(JSON.stringify(this.state.classJson))
    classJson = classJson.filter(item => item.display)
    const reqData = {
      scheduleAvailableClasses,
      classType: classJson.filter(item => item.name === 'classType')[0]?.value,
      dateFrom: classJson.filter(item => item.name === 'dateFrom')[0]?.value,
      classCount: classJson.filter(item => item.name === 'classCount')[0]?.value,
      timeFrom: classJson.filter(item => item.name === 'timeFrom')[0]?.value,
      duration: classJson.filter(item => item.name === 'duration')[0]?.value,
      status: 'upcoming',
      program: classJson.filter(item => item.name === 'program')[0]?.value,
      excludeWeekend: classJson.filter(item => item.name === 'excludeWeekend')[0]?.value,
      isDemo: classJson.filter(item => item.name === 'isDemo')[0]?.value,
      students: classJson.filter(item => item.name === 'students')[0].value.map(item => ({ id: item.value, parentId: this.state.allstudents.filter(itemm => itemm._id === item.value)[0]?.parent?._id })),
      facultyId: classJson.filter(item => item.name === 'facultyId')[0].value?.value
    }
    reqData.classCount = Number(reqData.classCount)
    reqData.duration = classDuration[reqData.duration]
    const { addUpdateClassDetails } = this.context;
    await addUpdateClassDetails(reqData);
    setTimeout(() => {
      const { err, addUpdateClass } = this.context || {};
      if (err && err.includes("Selected Faculty not available for all selected class dates")) {
        this.handleConfirmAddClassDialogOpen(err);
        this.setState({ formValid: true })
      } else if (err) {
        this.handleDialogOpen(err);
        this.setState({ formValid: true })
      } else if (addUpdateClass?.status === 200) {
        this.handleDialogOpen(addUpdateClass?.message);
      }
    }, 1000);
  }

  handleConfirmAddClassDialogOpen = (msg = "") => {
    this.setState({ dialogMsg: msg, openConfirmAddClassDialog: true });
  }

  handleDialogOpen = (msg = "") => {
    this.setState({ dialogMsg: msg, openDialog: true });
  }

  handleCloseBtn = () => {
    const { addUpdateClass } = this.context;
    if (addUpdateClass) {
      this.setState({ openDialog: false, isBack: true });
    }
    else {
      this.setState({ openDialog: false });
    }
  }

  handleClose = () => {
    this.setState({ openDialog: false, openConfirmAddClassDialog: false });
  }

  handleCloseConfirmAddClassBtn = async () => {
    this.createClass(true)
  }

  backToParent = () => {
    this.setState({ isBack: true });
  }

  resetClass = () => { }

  render() {
    if (this.state.isBack) {
      if (this.props.classData?.fromListing) {
        return <AllClasses />;
      } else if (this.props.student?._id) {
        return <AllStudents />;
      }
      return <ViewClass classData={this.props.classData} />
    }
    return (
      <React.Fragment>
        <Box pb={2}>
          <Button
            onClick={this.backToParent}
            type="submit"
            size="large"
            pt={2}
            variant="contained"
            color="primary"
          >
            <KeyboardBackspaceIcon />
          </Button>
        </Box>
        <Box boxShadow={3} mb={1}>
          <Grid container component={Paper}>
            <Grid item xs={12}>
              <Box p={2} pb={0}>
                <Typography variant="h5"> {this.props?.classData?._id ? 'Update ' : 'Book '} Class </Typography>
                <Typography variant="h6">Last upcoming class:</Typography>
                {this.state?.lastUpcomingClass?.map((item, index) => (
                  <p key={`upcoming-${index}-${item.time}`}>{item.name}: {item.time}</p>
                ))}
              </Box>
            </Grid>
            <FormRender
              form={this.state.classJson}
              onChange={this.onChangeValue} />
            <Grid item xs={12}>
              <Box p={2}>
                <Button
                  size="large"
                  pt={2}
                  onClick={() => this.createClass()}
                  variant="contained"
                  color="primary"
                  disabled={!this.state.formValid}
                >
                  Book
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Dialog
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
          open={!!this.state?.openDialog}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle align="center" id="responsive-dialog-title">
            {"Message"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.state?.dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button onClick={this.handleCloseBtn} autoFocus color="primary">OK</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
          open={!!this.state?.openConfirmAddClassDialog}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle align="center" id="responsive-dialog-title">
            {"Message"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.state?.dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button onClick={this.handleClose} color="primary">No</Button>
            <Button onClick={this.handleCloseConfirmAddClassBtn} color="primary">Yes</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default EditClass;
