
import React, { useState } from "react";
import moment from "moment";
import MomentUtils from '@date-io/moment';
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { IconButton, InputAdornment } from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import EventSharpIcon from '@material-ui/icons/EventSharp';

const constantText = {
  date_format_placeholder: "DD/MM/YYYY",
  date_format_placeholder_with_time: "DD/MM/YYYY HH:mm",
}

function UiDatePicker({
  type,
  name,
  minDate,
  checkOn,
  value,
  label,
  format = constantText.date_format_placeholder,
  onBlur = () => { },
  onChange,
  disabled,
  error,
  withTime = false,
  required = false,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const handleDateChange = (arg) => {
    if (!arg) {
      return onChange({ target: { value: null, triggerAutoSave: true } });
    }
    let event = { target: { value: arg } };
    if (minDate) {
      let { value: dateValue } = event.target;
      const condition = checkOn ? checkOn : moment().format("YYYY-MM-DD");
      const Now = moment().format("YYYY-MM-DD");
      const sameOrAfter = checkOn ? (moment(dateValue).isSameOrBefore(Now) && moment(dateValue).isSameOrAfter(condition)) : moment(dateValue).isSameOrAfter(condition);
      const sameOrBefore = checkOn ? (moment(dateValue).isSameOrBefore(Now) && moment(dateValue).isSameOrBefore(condition)) : moment(dateValue).isSameOrBefore(condition);
      const After = checkOn ? (moment(dateValue).isBefore(Now) && moment(dateValue).isAfter(condition)) : moment(dateValue).isAfter(condition);
      const Before = checkOn ? (moment(dateValue).isBefore(Now) && moment(dateValue).isBefore(condition)) : moment(dateValue).isBefore(condition);
      if (
        (minDate === "sameOrAfter" && sameOrAfter) ||
        (minDate === "after" && After) ||
        (minDate === "sameOrBefore" && sameOrBefore) ||
        (minDate === "before" && Before)
      ) {
        onChange(event);

      }
    } else {
      onChange(event);
    }
  }
  const handleBlur = () => {
    value && onBlur();
  }
  const minDateSelect = (mDate) => {
    if (mDate === 'sameOrAfter' || mDate === 'after') {
      return true
    }
  }
  const maxDateSelect = (mDate) => {
    if (mDate === 'sameOrBefore' || mDate === 'before') {
      return true;
    }
  }
  const getPicker = (PickerType, hideTab = false) => {
    return (
      <PickerType
        name={name}
        label={label}
        variant="inline"
        inputVariant="outlined"
        disablePast={minDateSelect(minDate)}
        disableFuture={maxDateSelect(minDate)}
        error={error}
        required={required}
        emptyLabel={withTime ? constantText.date_format_placeholder_with_time : constantText.date_format_placeholder}
        format={value ? (withTime ? constantText.date_format_placeholder_with_time : format) : ""}
        disabled={disabled}
        value={value || null}
        onClose={handleBlur}
        onChange={handleDateChange}
        hideTabs={hideTab}
        open={isOpen}
        KeyboardButtonProps={{
          onFocus: e => {
            setIsOpen(true);
          }
        }}
        PopoverProps={{
          disableRestoreFocus: true,
          onClose: () => {
            setIsOpen(false);
            handleBlur();
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => onChange({ target: { value: "", triggerAutoSave: true } })} >
                <ClearIcon />
              </IconButton>
              <IconButton onClick={e => setIsOpen(true)}>
                <EventSharpIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    )
  }
  return (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        {
          type === 'datetime-local' ? getPicker(DateTimePicker, true) : getPicker(DatePicker)
        }
      </MuiPickersUtilsProvider>
    </ >

  );
}

export default UiDatePicker;
