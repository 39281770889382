import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2)
    //   textAlign: 'center',
    //   color: theme.palette.text.secondary,
  },
  pap: {
    backgroundColor: "antiquewhite",
    boxShadow:
      "0px 2px 3px -1px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 1px 0px rgba(0,0,0,0.12)",
    borderRadius: "6px",
    padding: "15px 0px"
  }
}));

function PersonalDetails({ showPayments }) {
  const classes = useStyles();
  return (
    
      <div className={classes.root}>
        <Grid container spacing={3} className={classes.pap}>
          <Grid item xs={12} sm={6}>
            {/* <Typography variant="h6">Name</Typography> */}
            Name
            <Paper className={classes.paper}>{showPayments.name}</Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            Contact
            <Paper className={classes.paper}>{showPayments.mobileNo}</Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            Email
            <Paper className={classes.paper}>{showPayments.email}</Paper>
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            Active Status
            <Paper className={classes.paper}>{showPayments.activeStatus}</Paper>
          </Grid> */}
        </Grid>
        <br></br>
        <br></br>
        <Grid container spacing={3} className={classes.pap}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6">ADDRESS</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            Address
            <Paper className={classes.paper}>
              {showPayments.address.house_no}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            Area
            <Paper className={classes.paper}>{showPayments.address.area}</Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            State
            <Paper className={classes.paper}>
              {showPayments.address.state}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            City
            <Paper className={classes.paper}>{showPayments.address.city}</Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            Landmark
            <Paper className={classes.paper}>
              {showPayments.address.landmark}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            Pincode
            <Paper className={classes.paper}>
              {showPayments.address.pincode}
            </Paper>
          </Grid>
          {/* <Grid item xs={12} sm={6}>
          <Button variant="contained" color="primary" onClick={() => showMap()}>location</Button>
        </Grid> */}
        </Grid>
        <br></br>
        <br></br>
        <Grid container spacing={3} className={classes.pap}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6">Other Information</Typography>
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            Trained In Conducting Class
            <Paper className={classes.paper}>
              {JSON.stringify(showPayments.trainedInConductingClass).replace(/[\[\]"]+/g," ")}
            </Paper>
          </Grid> */}
          <Grid item xs={12} sm={6}>
            Catchment Radius
            <Paper className={classes.paper}>
              {showPayments.catchmentRadius}
            </Paper>
          </Grid>
          <Grid item xs={6} sm={6}>
            Class Type
            <Paper className={classes.paper}>
              {showPayments?.facultyClassType?.toString()}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            Type
            <Paper className={classes.paper}>{showPayments.type}</Paper>
          </Grid>
          <Grid item xs={6} sm={6}>
            Employment Status
            <Paper className={classes.paper}>
              {showPayments.status}
            </Paper>
          </Grid>
          <Grid item xs={6} sm={6}>
            Trained In Program
            <Paper className={classes.paper}>
              {showPayments?.trainedInProgram?.toString()}
            </Paper>
          </Grid>
          <Grid item xs={6} sm={6}>
            Center Code
            <Paper className={classes.paper}>{showPayments.centerCode}</Paper>
          </Grid>
        </Grid>
        <br></br>
        <br></br>
        <Grid container spacing={3} className={classes.pap}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6">Bank Details</Typography>
          </Grid>
          <Grid item xs={6} sm={6}>
            Bank Name
            <Paper className={classes.paper}>
              {showPayments.bank.bankName}
            </Paper>
          </Grid>
          <Grid item xs={6} sm={6}>
            Account No
            <Paper className={classes.paper}>
              {showPayments.bank.acNumber}
            </Paper>
          </Grid>
          <Grid item xs={6} sm={6}>
            IFSCode
            <Paper className={classes.paper}>
              {showPayments.bank.IFSCCode}
            </Paper>
          </Grid>
          <Grid item xs={6} sm={6}>
            Aadhaar
            <Paper className={classes.paper}>{showPayments.aadhar}</Paper>
          </Grid>
          <Grid item xs={6} sm={6}>
            PAN
            <Paper className={classes.paper}>{showPayments.pan}</Paper>
          </Grid>
        </Grid>
        <br></br>
        <br></br>
        <Grid container spacing={3} className={classes.pap}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6">Work Hours</Typography>
          </Grid>
          {showPayments.workHours.map(wk => {
            return (
              <React.Fragment>
                <Grid item xs={12} sm={4}>
                  From
                  <Paper className={classes.paper}>{wk.from}</Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                  To
                  <Paper className={classes.paper}>{wk.to}</Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                  Class type
                  <Paper className={classes.paper}>{wk.classType}</Paper>
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
      </div>
    
  );
}

export default PersonalDetails;
