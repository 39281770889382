import React from "react";
import { GlobalContext } from "../../context/GlobalState";
import { CSVLink } from "react-csv";
import {
  Paper, Grid, Typography, Box, Button, MenuItem, FormControl, Select,
  InputLabel, CircularProgress, TableHead, Dialog, Table, DialogActions,
  DialogContent, DialogContentText, DialogTitle, TableBody, TableCell, TableRow, TextField
  } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function PaymentHistory() {
  const [back, setBack] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [user, setUser] = React.useState("");
  const [dbutton, setDbutton] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [paymentDate, setPaymentDate] = React.useState(new Date());
  const [usersOptions, setUsersOptions] = React.useState([]);
  const [userName, setUserName] = React.useState("");
  const [startdate, setStartdate] = React.useState(new Date());
  const [enddate, setEnddate] = React.useState(new Date());
  const {encodeDecodeString} = React.useContext(GlobalContext);
  const isAdmin = encodeDecodeString(window.localStorage.getItem("user"),'decode');
  const {
    paymentStatement,
    allparents,
    allstudents,
    getParentDetails,
    getStudentDetails,
    getFacultyDetails,
    allfaculty,
    getCenterHeadDetails,
    allcenterhead,
    getPaymentStatements,
    err
  } = React.useContext(GlobalContext);

  const handleClose = () => {
    setOpen(false);
    setBack(true);
  };

  const handleStartDateChange = date => {
    setStartdate(date);
    setEnddate(date);
  };

  const handleEndDateChange = date => {
    setEnddate(date);
  };

  React.useEffect(() => {
    if (isAdmin && isAdmin === "faculty"){
      getFacultyDetails();
    } else {
      getCenterHeadDetails();
      getFacultyDetails();
      getParentDetails();
      getStudentDetails();
    }
  }, []);

  const onChangeUser = e => {
    const value = e.target.value ? e.target.value : "";
    setUser(value);
    console.log(value);
    value === "centerhead"
      ? setUsersOptions(allcenterhead)
      : value === "faculty"
      ? setUsersOptions(allfaculty)
      : value === "parent"
      ? setUsersOptions(allparents)
      : setUsersOptions(allstudents)
  };

  const headCells = [
    { id: 'date', numeric: false, label: 'Class Date/Trans Date' },
    { id: 'credit', numeric: false, label: 'Log Date' },
    { id: 'credit', numeric: false, label: 'Credit' },
    { id: 'payment', numeric: false, label: 'Debit' },
    { id: 'type', numeric: false, label: 'Class Type' },
    { id: 'duration', numeric: false, label: 'Duration' },
    { id: 'faculty', numeric: false, label: 'Center' },
    { id: 'center', numeric: false, label: 'Faculty' },
    { id: 'student', numeric: false, label: 'Student' },
    { id: 'parent', numeric: false, label: 'Parent' },
    { id: 'parent', numeric: false, label: 'Trans Ref' },
    { id: 'remarks', numeric: false, label: 'Remarks' }
  ]

  const onSubmit = async e => {
    setDbutton(true);
    setLoading(true);
    e.preventDefault();
    const statement = {
      id: userName,
      user: user,
      fromDate: moment(startdate).format("YYYY-MM-DD"),
      toDate: moment(enddate).format("YYYY-MM-DD")
    };
    await getPaymentStatements(statement);
    setDbutton(false);
    setLoading(false);
  };
  let filterPaymentData = paymentStatement.filter((item)=>(
    item[0] !== "Class Date/Trans Date"
  ))
  const csvData = paymentStatement;

  return (
    <React.Fragment>
      <Box boxShadow={3} mb={1}>
        <form onSubmit={onSubmit}>
          <Grid container component={Paper}>
            <Grid
              item
              xs={12}
              style={{ background: "antiquewhite", marginBottom: "16px" }}
            >
              <Box p={2}>
                <Typography variant="h6">Generate Payment Statement</Typography>
              </Box>
            </Grid>

            <Grid item xs={12} md={3}>
              <Box px={1} mt={2} pl={2}>
                <FormControl variant="outlined" fullWidth required>
                  <InputLabel id="user">User</InputLabel>
                  <Select
                    labelId="user"
                    value={user}
                    onChange={onChangeUser}
                    name="user"
                    label="User"
                  >
                    {isAdmin && isAdmin !== "faculty" ? <MenuItem value={"centerhead"}>Center Head</MenuItem> : ""}
                    <MenuItem value={"faculty"}>Faculty</MenuItem>
                    {isAdmin && isAdmin !== "faculty" ? <MenuItem value={"parent"}>Parent</MenuItem> : ""}
                    {isAdmin && isAdmin !== "faculty" ? <MenuItem value={"student"}>Student</MenuItem> : ""}
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={12} md={3}>
              <Box px={2} mt={2}>
                <FormControl variant="outlined" fullWidth={true} required>
                  <Autocomplete
                    fullWidth={true}
                    autoHighlight
                    id="user-name"
                    options={usersOptions}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option) => option.id}
                    getOptionSelected={(option, value) => option.name === value.name }
                    onChange={(event, newValue) => {
                      if (newValue && newValue._id) {
                        setUserName(newValue._id);
                      }
                    }}
                    renderInput={(params) => <TextField fullWidth={true} placeholder="Type to search..." required {...params} label="Name" variant="outlined" />}
                  />
                </FormControl>
              </Box>
            </Grid>

          <Grid item xs={12} md={3}>
            <Box px={2} bgcolor="white" display="flex" justifyContent="center">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              disableFuture
              autoOk
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              label="Transaction Start Date"
              value={startdate}
              name="startdate"
              onChange={handleStartDateChange}
            />
            </MuiPickersUtilsProvider>
            </Box>
          </Grid>

          <Grid item xs={12} md={3}>
            <Box px={2} bgcolor="white" display="flex" justifyContent="center">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              disableFuture
              autoOk
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              label="Transaction End Date"
              value={enddate}
              name="enddate"
              onChange={handleEndDateChange}
            />
            </MuiPickersUtilsProvider>
            </Box>
          </Grid>

            <Grid item xs={12}>
            <Box p={2}>
            <Button style={{float:"right"}}
                  type="submit"
                  size="large"
                  variant="contained"
                  color="primary"
                >
                  {loading ? (
                    <>
                      Loading
                      <CircularProgress
                        style={{ color: "#fff", marginLeft: "15px" }}
                        size={20}
                      />
                    </>
                  ) : (
                    "Generate Statement"
                  )}
                </Button>
              <Button
                disabled={dbutton}
                id="download"
                size="large"
                variant="contained"
                color="primary"
              >
                <CSVLink
                  data={csvData}
                  filename={`${user}-payment-statements-${moment().format("DD-MM-YYYY")}.csv`}
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  Download{" "}
                </CSVLink>
              </Button>
              
            </Box>
            </Grid>
          </Grid>
        </form>
        {filterPaymentData.length ?
        <div style={{margin: "15px", border: "1px solid", borderRadius: "6px"}}>
        <Table style={{backgroundColor: "#fff"}}>
        <TableHead style={{backgroundColor: "#3f51b5", color: "#fff"}}>
      <TableRow style={{color: "#fff"}}>
      {headCells.map((headCell) => (
          <TableCell style={{color: "#fff"}}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'default'}
          >        
          {headCell.label}
          </TableCell>
        ))}

      </TableRow>
      </TableHead>
      <TableBody>
      {filterPaymentData.map((value, index)=>{
  return (
  <TableRow key={index+1}>
  <TableCell align="center">{value[0] ? value[0] : ''}</TableCell>
  <TableCell align="center">{value[1] ? value[1] : ''}</TableCell>
  <TableCell align="center">{value[2] ? value[2] : ''}</TableCell>
  <TableCell align="center">{value[3] ? value[3] : ''}</TableCell>
  <TableCell align="center">{value[4] ? value[4] : ''}</TableCell>
  <TableCell align="center">{value[5] ? value[5] : ''}</TableCell>
  <TableCell align="center">{value[6] ? value[6] : ''}</TableCell>
  <TableCell align="center">{value[7] ? value[7] : ''}</TableCell>
  <TableCell align="center">{value[8] ? value[8] : ''}</TableCell>
  <TableCell align="center">{value[9] ? value[9] : ''}</TableCell>
  <TableCell align="center">{value[10] ? value[10] : ''}</TableCell>
  <TableCell align="center">{value[11] ? value[11] : ''}</TableCell>
  </TableRow>   
  );
})}
</TableBody>
</Table></div> : null }
        {/* <Grid container component={Paper}>
          <Grid item xs={12}>
            <Box p={2}>
              <Button
                disabled={dbutton}
                id="download"
                size="large"
                variant="contained"
                color="primary"
              >
                <CSVLink
                  data={csvData}
                  filename={`${user}-payment-statements-${moment().format("DD-MM-YYYY")}.csv`}
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  Download{" "}
                </CSVLink>
              </Button>
            </Box>
          </Grid>
        </Grid> */}
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          align="center"
          style={{ background: "antiquewhite" }}
          id="responsive-dialog-title"
        >
          {err ? err : "Payment report is generated"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button onClick={handleClose} variant="contained" color="primary">
            Back
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
