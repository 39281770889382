import React from "react";
import {
  Paper,
  Grid,
  Typography,
  Container,
  Box,
  TextField,
  Button,
  Link,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from "@material-ui/core";
import PhoneInput from 'material-ui-phone-number';
import 'react-phone-input-2/lib/material.css'

function CHNormalDetails(props) {
 const { name, email, mobileNo, password, setName, setEmail, setMobile, setPassword, formType, activestatus,
  setActiveStatus } = props

 
  return (
    <React.Fragment>
      <Grid item xs={12} sm={6}>
        <Box px={2}>
          <TextField
            label="Name"
            id="fac-name"
            variant="outlined"
            type="text"
            margin="normal"
            fullWidth
            required
            value={name}
            onChange={e => setName(e.target.value)}
            name="name"
          />
        </Box>
      </Grid>

      <Grid item xs={12} sm={6} style={{ display: "flex", alignItems: "center" }}>
        <Box mt={1} px={2} style={{ width: "100%" }}>
          <PhoneInput
            country={"in"}
            variant="outlined"
            enableSearch={true}
            masks={{in:'..........'}}
            value={mobileNo}
            onChange={mobileNo => setMobile(`${mobileNo}`)}
            required
            name="mobileNo"
          />
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box px={2}>
          <TextField
            label="Email"
            type="email"
            variant="outlined"
            margin="normal"
            fullWidth
            required
            value={email}
            onChange={e => setEmail(e.target.value)}
            name="email"
          />
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box px={2} mt={2}>
          <FormControl variant='outlined' fullWidth required>
            <InputLabel id='activestatus1'>
              Active Status
            </InputLabel>
            <Select
              labelId='activestatus1'
              id='activestatus1-filled1'
              value={activestatus}
              onChange={(e) => setActiveStatus(e.target.value)}
              name='activeStatus'
              label='Active Status'
            >
              <MenuItem value={"Active"}>Active </MenuItem>
              <MenuItem value={"On Hold"}>On Hold</MenuItem>
              <MenuItem value={"Resigned"}>Resigned</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Grid>
     
     
      { formType == "addCenterHead" && <Grid item xs={12} sm={6}>
        <Box px={2}>
       <TextField
            label="Password"
            type="password"
            variant="outlined"
            margin="normal"
            fullWidth
            required
            value={password}
            onChange={e => setPassword(e.target.value)}
            name="password"
          />
        
        </Box>
      </Grid>
      }
    </React.Fragment>
  );
}

export default CHNormalDetails;
