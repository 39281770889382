import React from "react";
import { GlobalContext } from "../../context/GlobalState";
import moment from 'moment';
import Chart from "react-apexcharts";
import {
  Paper, Grid, Typography, Box, Button, MenuItem, FormControl, Select,
  InputLabel, CircularProgress, Dialog, DialogContentText, DialogActions, DialogContent, DialogTitle, Radio, FormLabel, RadioGroup, FormControlLabel} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
let counter = 0;
class Graph extends React.Component {
  static contextType = GlobalContext;
  constructor(props){
    super(props);
    this.state = {
        openDialog: false,
        dialogMsg: "",
        centercode: "All",
        searchtype: "daily",
        loading: false,
        startdate: new Date(),
        enddate: new Date(),
        open: false,
        dbutton: true,
        calenderFormat: "dd/MM/yyyy",
        radioVal: "hours",
        allcentercode: this.contextType,
        series: [],
        options: {
          grid: {
            xaxis: {
                lines: {
                    show: false
                }
            },   
            yaxis: {
                lines: {
                    show: false
                }
            }
        },
          chart: {
            height: 350,
            type: 'line',
            stacked: false
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [1] 
          },
          stroke: {
            width: [1, 1, 4]
          },
          title: {
            text: "Total Hours Comparison",
            align: 'center',
            offsetX: 110
          },
          xaxis: {
            categories: [],
          },
          yaxis: [
            {
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: '#000000'
              },
              labels: {
                style: {
                  colors: '#000000',
                },
                formatter: function (val) {
                  return val.toFixed(0)
                }
              },
              title: {
                text: "Completed Hours",
                style: {
                  color: '#000000',
                }
              },
              tooltip: {
                enabled: true
              },
            },
          ],
          tooltip: {
            enabled: false,
            fixed: {
              enabled: true,
              position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
              // offsetY: 30,
              // offsetX: 60
            },
          },
          legend: {
            horizontalAlign: 'left',
            offsetX: 40
          }
        },
    }
  }
  componentDidMount(){
    this.getCenterCode();
  }

  getCenterCode = async () => {
    const context = this.context;
    const { getCenterCodeDetails } = context;
    await getCenterCodeDetails();
    setTimeout(() => {
      this.updateState();
    }, 2000);
  }

  updateState = () => {
    const { allcentercode } = this.context;
    this.setState({allcentercode: allcentercode});
  }

changeCenterCode = (e) => {
    this.setState({
      centercode: e.target.value
      });
  };

onChangeType = e => {
    counter = 0
    this.setState({
      searchtype: e.target.value
      });
  };

  handleStartDateChange = (date) => {
    this.setState({
      startdate: date
      });
  };

  handleEndDateChange = date => {
    this.setState({
      enddate: date
      });
  };

  handleDialogOpen = (msg = "") => {
    this.setState({dialogMsg : msg, openDialog : true});
  }

  handleCloseBtn = () => {
    this.setState({openDialog : false});
  }

  handleRadioChange = (event) => {
    this.setState({radioVal: event.target.value});
    if(event.target.value === "hours") {
      this.setState({options: {title: {text:"Total Hours Comparison"}}})
    } else {
      this.setState({options: {title: {text:"Newly Added Student Comparison"}}});
    }
  };

handleCalenderFormat = () =>{
    if (this.state.searchtype === "weekly" || this.state.searchtype === "daily"){
        this.setState({
            calenderFormat: "dd/MM/yyyy"
        })
    } else {
        this.setState({
            calenderFormat: "MM/yyyy"
        })
    }
    return true;
}

disableWeekdays = date => {
  if(counter===0){
    counter = 1
      if (this.state.searchtype === "weekly" || this.state.searchtype === "daily") {
        this.handleCalenderFormat();
        return date.getDay() === 1 || date.getDay() === 2 || date.getDay() === 3 || date.getDay() === 4 || date.getDay() === 5;
      } else if (this.state.searchtype === "monthly") {
        this.handleCalenderFormat();
      }
    }
  }
  onSubmit = async e => {
    this.setState({dbutton: true})
    this.setState({loading: true})
    const { getGraphDataDetails } = this.context;
    e.preventDefault();
    const summary = {
      centercode: this.state.centercode,
      type: this.state.searchtype,
      fromDate: moment(this.state.startdate).format("YYYY-MM-DD"),
      toDate: moment(this.state.enddate).format("YYYY-MM-DD"),
      searchBy: this.state.radioVal,
    };
    await getGraphDataDetails(summary);
    this.setState({dbutton: false})
    this.setState({loading: false})
    setTimeout(() => {
      const {err, graphDataCategories, graphDataSeries} = this.context || {};
      this.setState({options: {xaxis: {categories: graphDataCategories} }})
      if (this.state.radioVal === 'hours'){
        this.setState({options: {yaxis: [ {title:{text:"Completed Hours"}} ]}})
      } else {
        this.setState({options:{yaxis: [{title: {text: "New Students"}} ]}})
      }
      this.setState({series: [
        {
          name: this.state.radioVal === 'hours' ? 'Hours' : 'Newly Added Student',
          type: 'column',
          data: graphDataSeries
        }, {
          name: this.state.radioVal === 'hours' ? 'Hours' : 'Newly Added Student',
          type: 'line',
          data: graphDataSeries
        }
      ]})
      if(err){
        this.handleDialogOpen(err);
      }
    }, 1000);
  };
  
  render() {
    const { searchtype, centercode, startdate, enddate, calenderFormat, radioVal } = this.state;
    return (
        <React.Fragment>
          <Box boxShadow={3} mb={1}>
            <form onSubmit={this.onSubmit}>
              <Grid container component={Paper}>
                <Grid
                  item
                  xs={12}
                  style={{ background: "antiquewhite", marginBottom: "16px" }}
                >
                  <Box p={2}>
                    <Typography variant="h6">Graph Data</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                <FormLabel style={{paddingLeft: "10px"}} component="legend">Search By:</FormLabel>
                <RadioGroup style={{paddingLeft: "10px"}} aria-label="search by" name="searchby" value={radioVal} onChange={this.handleRadioChange}>
                  <FormControlLabel value="hours" control={<Radio />} label="Completed Hours" />
                  <FormControlLabel value="student" control={<Radio />} label="Newly Added Student" />
                </RadioGroup>
                </Grid>
    
                <Grid item xs={12} md={3}>
                  <Box px={1} mt={2} pl={2}>
                    <FormControl variant="outlined" fullWidth required>
                      <InputLabel id="type">Type</InputLabel>
                      <Select
                        labelId="type"
                        value={searchtype}
                        onChange={this.onChangeType}
                        name="type"
                        label="Type"
                      >
                        <MenuItem value={"daily"}>Daily</MenuItem>
                        <MenuItem value={"weekly"}>Weekly</MenuItem>
                        <MenuItem value={"monthly"}>Monthly</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
    
    
        <Grid item xs={12} sm={3}>
            <Box px={2} mt={2}>
              <FormControl variant='outlined' required fullWidth>
                <InputLabel id='demo-simple-select-filled-label12' required>
                  Center Code
                </InputLabel>
                <Select
                  labelId='demo-simple-select-filled-label12'
                  id='demo-simple-select-filled12'
                  value={centercode}
                  onChange={this.changeCenterCode}
                  name='centerCode'
                  label='Center Code'
                >
                    <MenuItem key='all' value='All'>
                      All
                    </MenuItem>
                  {this.state.allcentercode && this.state.allcentercode.length && this.state.allcentercode.map((code) => (
                    <MenuItem key={code._id} value={code.centerCode}>
                      {code.centerCode}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
    
              <Grid item xs={12} md={3}>
                <Box px={2} bgcolor="white" display="flex" justifyContent="center">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  disableFuture
                  autoOk
                  variant="inline"
                  format={calenderFormat}
                  margin="normal"
                  label="Start Value"
                  value={startdate}
                  name="startdate"
                  onChange={this.handleStartDateChange}
                  shouldDisableDate={this.disableWeekdays}
                />
                </MuiPickersUtilsProvider>
                </Box>
              </Grid>
    
              <Grid item xs={12} md={3}>
                <Box px={2} bgcolor="white" display="flex" justifyContent="center">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  disableFuture
                  autoOk
                  variant="inline"
                  format={calenderFormat}
                  margin="normal"
                  label="End Value"
                  value={enddate}
                  name="enddate"
                  onChange={this.handleEndDateChange}
                  shouldDisableDate={this.disableWeekdays}
                />
                </MuiPickersUtilsProvider>
                </Box>
              </Grid>
    
                <Grid item xs={12}>
                <Box p={2}>
                <Button style={{float:"right"}}
                      type="submit"
                      size="large"
                      variant="contained"
                      color="secondary"
                    >
                      {this.state.loading ? (
                        <>
                          Loading
                          <CircularProgress
                            style={{ color: "#fff", marginLeft: "15px" }}
                            size={20}
                          />
                        </>
                      ) : (
                        "Generate Graph"
                      )}
                    </Button>
                </Box>
                </Grid>
              </Grid>
            </form>
            <div id="chart" style={{paddingTop: "20px"}}>
              <Chart options={this.state.options} series={this.state.series} type="line" height={350} />
            </div>
          </Box>
          <Dialog
          open={this.state.openDialog}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle align="center" id="responsive-dialog-title">
            {"Message"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.state.dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button onClick={this.handleCloseBtn} autoFocus color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
        </React.Fragment>
      );
  }
}
export default Graph;
