import React, { useEffect, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import clsx from "clsx";
import { fade, lighten, makeStyles } from "@material-ui/core/styles";
import { Table, InputBase, Box, Button } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import { GlobalContext } from "../../context/GlobalState";


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  { id: "date",  label: "Date" },
  { id: "facultyIdname",  label: "Faculty" },
  { id: "timeSlotFrom",  label: "Begin Time" },
  { id: "timeSlotTo",  label: "End Time" },
  { id: "classType",  label: "Class Type" },
  { id: "studname",  label: "Student" },
  { id: "parentId",  label: "Parent" }, 
  { id: "status",  label: "Status" }
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
         
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    flex: "1 1 100%"
  }
}));

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto"
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit",
    border: '1px solid #dddd',
    margin: '6px 6px 0px 6px',
    borderRadius: '6px'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch"
      }
    }
  }
}));



export default function FacultyWorkHours() {

  const { allfctyClass, getFacultyClassDetails } = React.useContext(GlobalContext); 

  const classes = useStyles();
  // const [rows, setData] = useState([]);
  const [data, setData] = useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("date");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [searchTerm, setSearchTerm] = React.useState("");
 
  React.useEffect(() => {
    setData(allfctyClass);
  }, [allfctyClass]);

  React.useEffect(() => {
    let searched = allfctyClass.filter(
      row =>  
         row.date.includes(searchTerm) ||
         row.parentId.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1  ||
         row.studentId.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1  ||
         row.facultyId.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1  ||
         row.beginTime.indexOf(searchTerm.toLowerCase()) > -1  ||
         row.endTime.indexOf(searchTerm.toLowerCase()) > -1  ||
         row.classType.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1    
    );

    if (searchTerm === "") {
      getFacultyClassDetails();
      setData(searched);
    } else {
      setData(searched);
    }
  }, [searchTerm]);

  const handleSearch = event => {
    setSearchTerm(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // const handleSelectAllClick = event => {
  //   if (event.target.checked) {
  //     const newSelecteds = allfctyClass.map(n => n.facultyId.name);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }

  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

 
//   const backToAllFaculty = () =>{
//     setBack(true)
//   }

//   const goToAddfaculty = () => {
//     setAddfaculty(true);
//   };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, allfctyClass.length - page * rowsPerPage);

    // if(onupdate){ return <Updatefaculty showData={newdata}/> }
    
    // if(onpayment){ return <FacultyPayments showPayments={newdata1}/> }

    // if(addfaculty){return <AddFaculty/>} 
    // if(back){ return <AllFaculty /> }
  return (
    <div className={classes.root}>
      {/* <Box display="flex" pb={2}>
     <Box  flexGrow={1}  ><Button
            onClick={backToAllFaculty}
            type="submit"
            size="large"
            pt={2}
            variant="contained"
            color="primary"
          >
         Add New Faculty
          </Button></Box></Box> */}

      <Paper className={classes.paper}>
       
        <div className={classes.search} style={{ display: "inline-block" }}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
            inputProps={{ "aria-label": "search" }}
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
            //  onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={allfctyClass.length}
            />
            <TableBody>
              {stableSort(data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover>
                      <TableCell padding="checkbox">
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {new Date(row.date).toLocaleDateString()}
                      </TableCell>
                      <TableCell >{row.facultyId.name}</TableCell>
                      <TableCell >{row.timeSlotFrom}</TableCell>
                      <TableCell >{row.timeSlotTo}</TableCell>
                      <TableCell >{row.classType}</TableCell>
                      <TableCell >{row.studentId.name}</TableCell>
                      <TableCell >{row.parentId.name}</TableCell>
                      <TableCell >{row.status}</TableCell>
                      
                      {/* <TableCell >
                      <Tooltip title="Edit" arrow>
                        <IconButton
                          className="bg5"
                          onClick={e =>
                            showData(e.currentTarget.value, row._id)
                          }
                        >
                          <VisibilityIcon />
                        </IconButton>
                        </Tooltip>
                        <Tooltip title="work hours" arrow>
                        <IconButton
                          className="bg5"
                          onClick={e =>
                            showPayments(e.currentTarget.value, row._id)
                          }
                        >
                          <QueryBuilderIcon />
                        </IconButton>
                        </Tooltip>
                      </TableCell> */}

                    </TableRow>
                  );
                })}
             
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 75, 100]}
          component="div"
          count={allfctyClass.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
