import React from "react";
import { GlobalContext } from "../context/GlobalState";
import {
  Card,
  Divider,
  List,
  ListItem,
  Grid,
  Avatar,
  ListItemAvatar,
  ListItemText,
  Typography,
  Table,
  TableRow,
  TableCell, TableBody
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import FaceIcon from "@material-ui/icons/Face";
import TodayIcon from "@material-ui/icons/Today";
import EventIcon from "@material-ui/icons/Event";
import indian from "../media/indian-rupee-24.png";
import Chart1 from './Chart1'
import Chart2 from './Chart2'
import Chart3 from './Chart3'

function MediaCard2() {
  const { faculty, getStatDetails, encodeDecodeString } = React.useContext(GlobalContext);
  const stat = JSON.parse(JSON.stringify(faculty));
  const isAdmin = encodeDecodeString(window.localStorage.getItem("user"),'decode');
  React.useEffect(() => {
    getStatDetails();
  }, []);

  return (
    <div>
      <Grid container spacing={3}>


        <Grid item sm={12} md={6} lg={4}>
          <Card>
            <Grid container>
              <Grid item xs={12} style={{ backgroundColor:'#dddddd', alignItems:'center', padding:'16px' }}>
                <Typography variant="h6" align="center" component="h6">
                  Total Classes: {stat.classes && stat.classes.completed + stat.classes.ongoing + stat.classes.upcoming}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar className="bg3">
                        <TodayIcon fontSize="large" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText secondary="Completed">
                      <Typography variant="h6" component="h6">
                        {stat.classes && stat.classes.completed}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6}>
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar className="bg3">
                        <TodayIcon fontSize="large" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText secondary="Upcoming">
                      <Typography variant="h6" component="h6">
                        {stat.classes && stat.classes.upcoming}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} style={{ padding:'16px' }}>
                <Chart3 stat={stat} />
              </Grid>
            </Grid>
          </Card>
        </Grid>


        <Grid item sm={12} md={6} lg={4}>
          <Card>
            <Grid container>
              <Grid item xs={12} style={{ backgroundColor:'#dddddd', alignItems:'center', padding:'16px' }}>
                <Typography variant="h6" align="center" component="h6">
                  Total Students: {stat.student && stat.student.active + stat.student.onhold + stat.student.inactive}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar className="bg3">
                        <FaceIcon fontSize="large" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText secondary="Active">
                      <Typography variant="h6" component="h6">
                        {stat.student && stat.student.active}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6}>
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar className="bg3">
                        <TodayIcon fontSize="large" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText secondary="Inactive">
                      <Typography variant="h6" component="h6">
                        {stat.student && stat.student.onhold + stat.student.inactive}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} style={{ padding:'16px' }}>
                <Chart2 stat={stat} />
              </Grid>
            </Grid>
          </Card>
        </Grid>



        <Grid item sm={12} md={6} lg={4}>
          <Card>
            <Grid container>
              <Grid item xs={12} style={{ backgroundColor:'#dddddd', alignItems:'center', padding:'16px' }}>
                <Typography variant="h6" align="center" component="h6">
                  Total Faculty: {stat.faculty && stat.faculty.active + stat.faculty.onhold + stat.faculty.resigned}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar className="bg3">
                        <FaceIcon fontSize="large" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText secondary="Active">
                      <Typography variant="h6" component="h6">
                        {stat.faculty && stat.faculty.active}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6}>
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar className="bg3">
                        <TodayIcon fontSize="large" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText secondary="Inactive">
                      <Typography variant="h6" component="h6">
                        {stat.faculty && stat.faculty.onhold + stat.faculty.resigned}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} style={{ padding:'16px' }}>
                <Chart1 stat={stat} />
              </Grid>
            </Grid>
          </Card>
        </Grid>


        {isAdmin === "admin" ?
        <Grid item xs={12}>
          <Card>
            <Grid container style={{ alignItems:'center'}}>
              <Grid item xs={4}>
                <Typography variant="h4" align="center" component="h4">
                  Revenue:
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar className="bg3">
                        <FaceIcon fontSize="large" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText secondary="Collection">
                      <Typography variant="h6" component="h6">
                        {new Intl.NumberFormat('en-IN', {
                          maximumSignificantDigits: 3
                        }).format(stat.revenue)}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={4}>
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar className="bg3">
                        <TodayIcon fontSize="large" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText secondary="Balance">
                      <Typography variant="h6" component="h6">
                        {new Intl.NumberFormat('en-IN', {
                          maximumSignificantDigits: 3
                        }).format(stat.credit)}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Card>
        </Grid> : ""}


      </Grid>
    </div>
  );
}

export default MediaCard2;
