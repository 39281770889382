import React from "react";
import { Paper, Grid, Typography, Box, Button} from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import AllModules from "./AllModules";
import FormRender from "../../common/FormHelper/FormRender";
import checkValidity from "../../common/FormHelper/FieldValidator";
import { isValidatedForm, DEFAULT_JSON } from "../../common/FormHelper/FormValidSetter";
import { GlobalContext } from "../../context/GlobalState";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

//JSON
import BasicJson from './Schema/AddModule.json';

class AddModule extends React.Component {
  static contextType = GlobalContext;
  constructor(props){
    super(props);
    this.state = {
      basicJson : DEFAULT_JSON(BasicJson),
      isBack: false,
      dialogMsg: "",
      openDialog: false
    };
  }
  componentDidMount(){}

  InputBasicChanger = (event, elemIndex) => {
      const copyJSON = [...this.state.basicJson];
      const updatedElement = copyJSON[elemIndex];
      if (updatedElement.type === "file") {
        updatedElement.value = event.target.value;
        updatedElement.file = event.target.files[0];
      }
      else {
        updatedElement.value = event.target.value;
      }
      const { isValid, errorText } = checkValidity(
        updatedElement.value,
        updatedElement.validation
        );
      updatedElement.valid = isValid;
      updatedElement.errorText = errorText;
      updatedElement.touched = 1;
      if (elemIndex === 2){
        const startVal = parseInt(copyJSON[2]?.value) * 4 - 3
        const endVal = parseInt(copyJSON[2]?.value) * 4
        let options = []
        for (let i = startVal; i <= endVal; i++) {
          options.push(i)
        }
        options = [...options]
        copyJSON[3].options = options
        copyJSON[3].valid = true
        copyJSON[3].errorText = "" 
        copyJSON[3].touched = 0
      }
      this.setState({ basicJson: copyJSON }, () => {});
  }



  handleSave = () => {
    const { basicJson } = this.state;
    const { addModuleDetails } = this.context;
    const basic  = isValidatedForm(basicJson);
    this.setState({
      basicJson: basic?.validatedForm
    });
    if(basic?.formValidity ){
      const postObj = {};
      postObj.program = basicJson[0]?.value;
      postObj.title = basicJson[1]?.value;
      postObj.month = parseInt(basicJson[2]?.value);
      postObj.week = parseInt(basicJson[3]?.value)
      postObj.description = basicJson[4]?.value
      
      addModuleDetails(postObj);
      setTimeout(() => {
        const {err, addModule} = this.context || {};
        if(err){
          this.handleDialogOpen(err);
        }
        else if(addModule){
          this.handleDialogOpen(addModule);
        }
      }, 1000);
    }
  }
  backToParent = () => {
    this.setState({ isBack : true});
  }
  handleDialogOpen = (msg = "") => {
    this.setState({dialogMsg : msg, openDialog : true});
  }
  handleCloseBtn = () => {
    const { addModule }  = this.context;
    if(addModule){
      this.setState({openDialog : false, isBack: true});
    }
    else{
      this.setState({openDialog : false});
    }
  }
  handleClose = () => {
    this.setState({openDialog : false});
  }
  render() {
    const { basicJson, openDialog, dialogMsg, isBack } = this.state;
    basicJson[1].value = basicJson[1].value || [] 
    console.log("BASIC JSON: ", basicJson)
    if (isBack) {
      return <AllModules />;
    }
    return (
      <React.Fragment>
        <Box pb={2}>
          <Button
            onClick={this.backToParent}
            type="submit"
            size="large"
            pt={2}
            variant="contained"
            color="primary"
          >
            <KeyboardBackspaceIcon />
          </Button>
        </Box>
        <Box boxShadow={3} mb={1}>
          <Grid container component={Paper}>
            <Grid item xs={12}>
              <Box p={2}>
                <Typography variant="h5">Add Module</Typography>
              </Box>
            </Grid>
            <FormRender form={basicJson} onChange={this.InputBasicChanger} />
            <Grid item xs={12}>
              <Box p={2}>
                <Button
                  size="large"
                  pt={2}
                  onClick={this.handleSave}
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Dialog
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
          open={openDialog}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle align="center" id="responsive-dialog-title">
            {"Message"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button onClick={this.handleCloseBtn} autoFocus color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }

}

export default AddModule;
