import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { GlobalContext } from "../../context/GlobalState";
import {
  TextField,
  Chip,
  Typography,
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  IconButton
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import SetSpecialRates from "./SetSpecialRates";
import HomeIcon from "@material-ui/icons/Home";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import moment from "moment";
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
  },
  pap: {
    backgroundColor: "antiquewhite",
    boxShadow:
      "0px 2px 3px -1px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 1px 0px rgba(0,0,0,0.12)",
    borderRadius: "6px",
    padding: "15px 0px",
  },
  stdname: {
    fontWeight: "500",
    display: "inline-block",
    textTransform: "capitalize",
  },
  box: {
    float: "left",
    height: "20px",
    width: "60px",
    marginBottom: "15px",
    border: "1px solid black",
    clear: "both"
  },
  markCompleted: {
    backgroundColor: "#47B950"
  },
  partialCompleted: {
    backgroundColor: "#E9B404"
  },
  marknotCompleted: {
    backgroundColor: "#FFFFFF"
  },
  divAlign: {
    marginLeft : "15px"
  },
  moduleStarted: {
    backgroundColor: "#B30301"
  },
  gridModule: {
    lineHeight : "28px"
  }
}));

function StudentDetail({ showPayments, editOrder }) {
  const classes = useStyles();
  const [data, setData] = React.useState([]);
  const [ids, setIds] = React.useState();
  const [onlineSRate, setOnlSRate] = React.useState("");
  const [atHomeSRate, setAhmSRate] = React.useState("");
  
  const [name, setName] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const {
    singleparentPayment,
    getParentDetails,
    getSingleParentPaymentDetails,
    updateParentPaymentsDetails,
  } = React.useContext(GlobalContext);

  const today = new Date();
  today.setDate(today.getDate() + 1);
  const [onlstartdate, setOnlstartdate] = React.useState(today);
  const [hmstartdate, setHmstartdate] = React.useState(today);
  const [onlenddate, setOnlenddate] = React.useState(today);
  const [hmenddate, setHmenddate] = React.useState(today);

  const { studentOrderDetails, getStudentOrderDetails } = React.useContext(GlobalContext);

  React.useEffect(() => {
    getStudentOrderDetails(showPayments._id)
  }, [showPayments._id]);

  const handleOlSDateChange = (date) => {
    setOnlstartdate(date);
  };

  const handleOlEDateChange = (date) => {
    setOnlenddate(date);
  };

  const handleAtmSDateChange = (date) => {
    setHmstartdate(date);
  };

  const handleAtmEDateChange = (date) => {
    setHmenddate(date);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditOrder = (order) => {
    editOrder(order)
  };

  let homeSpecialRate = showPayments?.specialRates.filter(
    (home) => home.classType === "At Home"
  );
  let onlineSpecialRate = showPayments?.specialRates.filter(
    (online) => online.classType === "Online"
  );

  return (
    <div className={classes.root}>
      <Grid container spacing={3} className={classes.pap}>
        <Grid
          item
          xs={12}
          sm={12}
          style={{ paddingBottom: "2px", paddingTop: "25px" }}
        >
          <Typography variant="h6">Student Information</Typography>
        </Grid>

        <React.Fragment>
          <Grid item xs={12} sm={3}>
            Name
            <Paper className={classes.paper}> {showPayments.name}</Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            Enrollment ID
            <Paper className={classes.paper}> {showPayments.centerCode}-{showPayments._id}</Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            Date of Birth
            <Paper className={classes.paper}>
              {" "}
              {moment(showPayments.dob).format("DD/MM/YYYY")}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            Total Hours
            <Paper className={classes.paper}>
              {showPayments.completedHours}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            Program Enrolled
            <Paper className={classes.paper}> {showPayments.program}</Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            Type
            <Paper className={classes.paper}> {showPayments.type}</Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            Status
            <Paper className={classes.paper}> {showPayments?.approval_status?.status}</Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            Preferred Faculty
            <Paper className={classes.paper}> {showPayments?.preferredFaculty?.name || "N/A"}</Paper>
          </Grid>

          <Grid item xs={12} sm={12} style={{ paddingBottom: "2px", paddingTop: "25px" }}>
            <Typography variant="h6">Module Status</Typography>
          </Grid>

          <Grid item xs={2} sm={2} className={classes.gridModule}>
            Month:<br></br>
            Actual:<br></br>
            Should be:
          </Grid>
          {showPayments?.moduleCompletionModules && showPayments?.moduleCompletionModules.map(value => (
            <Grid item xs={1} sm={1}>
              <div className={classes.divAlign}>M{value.month}</div>
              <div><div className={`${classes.box} ${value.moduleCompletionActual ? classes.markCompleted: classes.marknotCompleted ? value.moduleCompletionPartial? classes.partialCompleted: classes.marknotCompleted ? value.moduleCompletionStarted ? classes.moduleStarted : classes.marknotCompleted: classes.marknotCompleted : classes.marknotCompleted}`}></div></div>
              <div><div className={`${classes.box} ${value.shouldModuleCompletion ? classes.markCompleted : classes.marknotCompleted ? value.shouldModulePartial ? classes.partialCompleted: classes.marknotCompleted : classes.marknotCompleted}`}></div></div>
            </Grid>
          ))}
          <Grid
            container
            spacing={3}
            item
            xs={12}
            style={{ paddingRight: "0px" }}
          >
            <Grid item xs={12}>
              <Chip
                size="large"
                icon={<HomeIcon />}
                label="AT HOME SPECIAL RATE"
                color="primary"
                style={{ fontSize: "15px" }}
              />{" "}
              -{" "}
              <Typography className={classes.stdname}>
                {showPayments.name}
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              Student
              <Paper className={classes.paper}>
                {" "}
                {homeSpecialRate &&
                homeSpecialRate[0] &&
                homeSpecialRate[0].rate
                  ? homeSpecialRate[0].rate
                  : 0}
              </Paper>
            </Grid>
            <Grid item xs={12} md={2}>
              Faculty
              <Paper className={classes.paper}>
                {" "}
                {homeSpecialRate &&
                homeSpecialRate[0] &&
                homeSpecialRate[0].toFaculty
                  ? homeSpecialRate[0].toFaculty
                  : 0}
              </Paper>
            </Grid>
            <Grid item xs={12} md={2}>
              Center Head
              <Paper className={classes.paper}>
                {" "}
                {homeSpecialRate &&
                homeSpecialRate[0] &&
                homeSpecialRate[0].toCenterhead
                  ? homeSpecialRate[0].toCenterhead
                  : 0}
              </Paper>
            </Grid>
            <Grid item xs={12} md={2}>
              From Date
              <Paper className={classes.paper}>
                {homeSpecialRate &&
                homeSpecialRate[0] &&
                homeSpecialRate[0].from
                  ? moment(homeSpecialRate[0].from).format("DD/MM/yyyy")
                  : "N/A"}
              </Paper>
            </Grid>
            <Grid item xs={12} md={2}>
              To Date
              <Paper className={classes.paper}>
                {" "}
                {homeSpecialRate && homeSpecialRate[0] && homeSpecialRate[0].to
                  ? moment(homeSpecialRate[0].to).format("DD/MM/yyyy")
                  : "N/A"}
              </Paper>
            </Grid>

            <Divider variant="middle" width="98.5%" />
            <Grid item xs={12}>
              <Chip
                size="large"
                icon={<LocalLibraryIcon />}
                label="ONLINE SPECIAL RATE"
                color="primary"
                style={{ fontSize: "15px" }}
              />{" "}
              -{" "}
              <Typography className={classes.stdname}>
                {showPayments.name}
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              Student
              <Paper className={classes.paper}>
                {onlineSpecialRate &&
                onlineSpecialRate[0] &&
                onlineSpecialRate[0].rate
                  ? onlineSpecialRate[0].rate
                  : 0}
              </Paper>
            </Grid>
            <Grid item xs={12} md={2}>
              Faculty
              <Paper className={classes.paper}>
                {onlineSpecialRate &&
                onlineSpecialRate[0] &&
                onlineSpecialRate[0].toFaculty
                  ? onlineSpecialRate[0].toFaculty
                  : 0}
              </Paper>
            </Grid>
            <Grid item xs={12} md={2}>
              Center Head
              <Paper className={classes.paper}>
                {onlineSpecialRate &&
                onlineSpecialRate[0] &&
                onlineSpecialRate[0].toCenterhead
                  ? onlineSpecialRate[0].toCenterhead
                  : 0}
              </Paper>
            </Grid>
            <Grid item xs={12} md={2}>
              From Date
              <Paper className={classes.paper}>
                {onlineSpecialRate &&
                onlineSpecialRate[0] &&
                onlineSpecialRate[0].from
                  ? moment(onlineSpecialRate[0].from).format("DD/MM/yyyy")
                  : "N/A"}
              </Paper>
            </Grid>
            <Grid item xs={12} md={2}>
              To Date
              <Paper className={classes.paper}>
                {onlineSpecialRate &&
                onlineSpecialRate[0] &&
                onlineSpecialRate[0].to
                  ? moment(onlineSpecialRate[0].to).format("DD/MM/yyyy")
                  : "N/A"}
              </Paper>
            </Grid>
          </Grid>
          {studentOrderDetails?.length === 0 ?
            <Grid item xs={12} sm={12} style={{ paddingBottom: "2px", paddingTop: "3px", margin: "3px" }} >
              <Typography variant="body1">{"No orders Available."}</Typography>
            </Grid>
            :
            <Grid item xs={12} sm={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Order Date</TableCell>
                      <TableCell>Items</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Total Cost</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {studentOrderDetails?.map((order, i) => {
                      return (
                        <TableRow key={order._id + 'order' + i}>
                          <TableCell>{order.order_date ? moment(order.order_date).format("DD/MM/YYYY") : ""}</TableCell>
                          <TableCell component="th" scope="row">
                            {order.item_qty_cost?.map((item) => (item?.details?.name)).join(", ")}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {order.status || ""}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {order.total_cost || ""}
                          </TableCell>
                          <TableCell>
                            <Tooltip title="Reorder" arrow>
                              <IconButton className="bg5" onClick={() => handleEditOrder(order)}>
                                <AddShoppingCartIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          }
        </React.Fragment>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
              {"Update Special Rates for the classes"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Set Special Rate for Online Classes
              </DialogContentText>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <KeyboardDatePicker
                    disableToolbar
                    minDate={onlstartdate}
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    // id="date-picker-inline"
                    label="Start Date"
                    value={onlstartdate}
                    name="onlstartdate"
                    onChange={handleOlSDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                  <KeyboardDatePicker
                    disableToolbar
                    minDate={onlenddate}
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    //  id="date-picker-inline"
                    label="End Date"
                    value={onlenddate}
                    name="onlenddate"
                    onChange={handleOlEDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                  <TextField
                    fullWidth
                    name="onlineSRate"
                    label="Online Special Rate"
                    value={onlineSRate}
                    onChange={(e) => setOnlSRate(e.target.value)}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </DialogContent>

            <br></br>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Set Special Rate for At Home Classes
              </DialogContentText>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <KeyboardDatePicker
                    disableToolbar
                    minDate={hmstartdate}
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    // id="date-picker-inline"
                    label="Start Date"
                    value={hmstartdate}
                    name="hmstartdate"
                    onChange={handleAtmSDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                  <KeyboardDatePicker
                    disableToolbar
                    minDate={hmenddate}
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    // id="date-picker-inline"
                    label="End Date"
                    value={hmenddate}
                    name="hmenddate"
                    onChange={handleAtmEDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                  <TextField
                    fullWidth
                    name="atHomeSRate"
                    label="At Home Special Rate"
                    value={atHomeSRate}
                    onChange={(e) => setAhmSRate(e.target.value)}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </DialogContent>
            <DialogActions>
              <Button color="primary" variant="contained">
                Save
              </Button>
            </DialogActions>
        </Dialog>
      </Grid>
    </div>
  );
}

export default StudentDetail;
