import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { GlobalContext } from "../../context/GlobalState";
import { red } from "@material-ui/core/colors";
import { Delete, Edit } from '@material-ui/icons';

import {
  TextField,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, IconButton
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import SetSpecialRates from "./SetSpecialRates";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
  },
  pap: {
    backgroundColor: "antiquewhite",
    boxShadow:
      "0px 2px 3px -1px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 1px 0px rgba(0,0,0,0.12)",
    borderRadius: "6px",
    padding: "15px 0px",
  },
  stdname: {
    fontWeight: "500",
    display: "inline-block",
    textTransform: "capitalize",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

function ParentPersonalDetails({
  setSelectedStudent,
  setEditStudent,
  setDeleteStudent,
  setAddStudent,
  showPayments,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClick = (event, student) => {
    setAnchorEl(event.currentTarget);
    setSelectedStudent(student);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleMenuEdit = (student) => {
    // setAnchorEl(null);
    setSelectedStudent(student);
    setEditStudent(true);
  };
  const handleMenuDelete = (student) => {
    // setAnchorEl(null);
    setSelectedStudent(student);
    setDeleteStudent(true);
  }
  const [data, setData] = React.useState([]);
  const [ids, setIds] = React.useState();
  const [onlineSRate, setOnlSRate] = React.useState("");
  const [atHomeSRate, setAhmSRate] = React.useState("");

  const [name, setName] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [movenext, setMovenext] = React.useState(false);
  const [back, setBack] = React.useState(false);
  const [onupdate, setUpdate] = React.useState(false);
  const {
    singleparentPayment,
    getParentDetails,
    getSingleParentPaymentDetails,
    updateParentPaymentsDetails,
    encodeDecodeString
  } = React.useContext(GlobalContext);

  const today = new Date();
  today.setDate(today.getDate() + 1);
  const [onlstartdate, setOnlstartdate] = React.useState(today);
  const [hmstartdate, setHmstartdate] = React.useState(today);
  const [onlenddate, setOnlenddate] = React.useState(today);
  const [hmenddate, setHmenddate] = React.useState(today);
  const isAdmin = encodeDecodeString(window.localStorage.getItem("user"),'decode');
  const handleOlSDateChange = (date) => {
    setOnlstartdate(date);
  };

  const handleOlEDateChange = (date) => {
    setOnlenddate(date);
  };

  const handleAtmSDateChange = (date) => {
    setHmstartdate(date);
  };

  const handleAtmEDateChange = (date) => {
    setHmenddate(date);
  };

  const goToAddStudent = () => {
    setAddStudent(true);
  }
  const handleClose = () => {
    setOpen(false);
  };

  if (back) {
    return <ParentPersonalDetails />;
  }

  if (movenext) {
    return (
      <SetSpecialRates showPayments={showPayments} ids={ids} name={name} />
    );
  }
  const filteredStudent = showPayments?.student?.filter(e => (e?.approval_status?.status !== "" || e?.approval_status?.status !== null));
  return (
    <div className={classes.root}>
      <Grid container spacing={3} className={classes.pap}>
        <Grid item xs={12} sm={6}>
          Name
          <Paper className={classes.paper}>{showPayments?.name}</Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          Mobile No
          <Paper className={classes.paper}>{showPayments?.mobileNo}</Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          Email
          <Paper className={classes.paper}>{showPayments?.email}</Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          Points
          <Paper className={classes.paper}>{showPayments?.currentPoints}</Paper>
        </Grid>
      </Grid>
      <br></br>
      <br></br>

      <Grid container spacing={3} className={classes.pap}>
        <Grid
          item
          xs={12}
          sm={12}
          style={{ paddingBottom: "2px", paddingTop: "25px" }}
        >
          <Typography variant="h6">ADDRESS</Typography>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
        Trained In Conducting Class
          <Paper className={classes.paper}>{JSON.stringify(showPayments?.trainedInConductingClass).replace(/[\[\]"]+/g,' ')}</Paper>
        </Grid> */}

        <Grid item xs={12} sm={6}>
          Address
          <Paper className={classes.paper}>
            {showPayments?.residentalAddress?.house_no ? showPayments?.residentalAddress?.house_no : "N/A"}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          Area
          <Paper className={classes.paper}>
            {showPayments?.residentalAddress?.area ? showPayments?.residentalAddress?.area : "N/A"}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          State
          <Paper className={classes.paper}>
            {showPayments?.residentalAddress?.state ? showPayments?.residentalAddress?.state : "N/A"}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          City
          <Paper className={classes.paper}>
            {showPayments?.residentalAddress?.city ? showPayments?.residentalAddress?.city : "N/A"}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          Landmark
          <Paper className={classes.paper}>
            {showPayments?.residentalAddress?.landmark ? showPayments?.residentalAddress?.landmark : "N/A"}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          Pincode
          <Paper className={classes.paper}>
            {showPayments?.residentalAddress?.pincode ? showPayments?.residentalAddress?.pincode : "N/A"}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
        Center Code
          <Paper className={classes.paper}>
            {showPayments?.centerCode}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
        Coordinates (Lat, Long)
          <Paper className={classes.paper}>
            {showPayments?.residentalAddress?.location?.coordinates[1]}, {showPayments?.residentalAddress?.location?.coordinates[0]}
          </Paper>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <Button variant="contained" color="primary" onClick={() => showMap()}>location</Button>
        </Grid> */}
      </Grid>
      <br></br>
      <br></br>

      <Grid container spacing={3} className={classes.pap}>
        <Grid item sm={6} xs={6} >
          <Typography variant="h6">Student Information</Typography>
        </Grid>
        {isAdmin !== "operations" ?
        <Grid item sm={6} xs={6}>
          <Button
            onClick={goToAddStudent}
            style={{ float: "right" }}
            type="submit"
            size="large"
            pt={2}
            variant="contained"
            color="primary"
            disabled={isAdmin === 'marketing' || isAdmin === 'coo' || isAdmin === 'academics'}
          >
            Add New Student
            </Button>
        </Grid> : "" }
        {filteredStudent?.length === 0 ?
          <Grid item xs={12} sm={12} style={{ paddingBottom: "2px", paddingTop: "3px", margin: "3px" }} >
            <Typography variant="body1">{"No Student Available."}</Typography>
          </Grid>
          :
          <Grid item xs={12} sm={12}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Index</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Program</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredStudent?.map((std, i) => {
                    return (
                      <TableRow key={std.name + i}>
                        <TableCell>{i + 1}</TableCell>
                        <TableCell component="th" scope="row">
                          {std.name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {std.program}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {std?.approval_status?.status || 'N/A'}
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Edit" arrow>
                            <IconButton disabled={isAdmin === 'marketing' || isAdmin === 'coo' || isAdmin === 'academics'} className="bg5" className="bg5" onClick={() => handleMenuEdit(std)}>
                              <Edit />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="View" arrow>
                            <IconButton disabled={isAdmin === 'marketing' || isAdmin === 'coo' || isAdmin === 'academics'} className="bg5" onClick={() => handleMenuDelete(std)}>
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        }
        {/* {filteredStudent?.length === 0 ? (
          <Grid
            item
            xs={12}
            sm={12}
            style={{ paddingBottom: "2px", paddingTop: "3px", margin: "3px" }}
          >
            <Typography variant="body1">{"No Student Available."}</Typography>
          </Grid>
        ) : null}
        {filteredStudent?.map((std, i) => {
          return (
            <React.Fragment key={i}>
              <Grid key={i} item xs={12} sm={12}>
                Student : {i + 1}
                <Card className={classes.root}>
                  <CardHeader
                    action={
                      <IconButton
                        id={`dot-${i}`}
                        onClick={(e) => handleMenuClick(e, std)}
                        aria-label="settings"
                      >
                        <MoreVertIcon />
                      </IconButton>
                    }
                    title={
                      <Typography component={"body1"}>{std?.name}</Typography>
                    }
                    subheader={
                      <>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          {`Program : ${std?.program}`}
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          {`Status : ${
                            std?.approval_status?.status
                              ? std?.approval_status?.status
                              : "NA"
                          }`}
                        </Typography>
                      </>
                    }
                  />
                </Card>
                <Menu
                  id={`student-${i}`}
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem key={`edit-${i}`} onClick={handleMenuEdit}>Edit</MenuItem>
                  <MenuItem key={`delete-${i}`} onClick={handleMenuDelete}>Delete</MenuItem>
                </Menu>
              </Grid>
            </React.Fragment>
          );
        })} */}

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Update Special Rates for the classes"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Set Special Rate for Online Classes
              </DialogContentText>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justify="space-around">
                <KeyboardDatePicker
                  disableToolbar
                  minDate={onlstartdate}
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  // id="date-picker-inline"
                  label="Start Date"
                  value={onlstartdate}
                  name="onlstartdate"
                  onChange={handleOlSDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
                <KeyboardDatePicker
                  disableToolbar
                  minDate={onlenddate}
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  //  id="date-picker-inline"
                  label="End Date"
                  value={onlenddate}
                  name="onlenddate"
                  onChange={handleOlEDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
                <TextField
                  fullWidth
                  name="onlineSRate"
                  label="Online Special Rate"
                  value={onlineSRate}
                  onChange={(e) => setOnlSRate(e.target.value)}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </DialogContent>

          <br></br>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Set Special Rate for At Home Classes
              </DialogContentText>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justify="space-around">
                <KeyboardDatePicker
                  disableToolbar
                  minDate={hmstartdate}
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  // id="date-picker-inline"
                  label="Start Date"
                  value={hmstartdate}
                  name="hmstartdate"
                  onChange={handleAtmSDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
                <KeyboardDatePicker
                  disableToolbar
                  minDate={hmenddate}
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  // id="date-picker-inline"
                  label="End Date"
                  value={hmenddate}
                  name="hmenddate"
                  onChange={handleAtmEDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
                <TextField
                  fullWidth
                  name="atHomeSRate"
                  label="At Home Special Rate"
                  value={atHomeSRate}
                  onChange={(e) => setAhmSRate(e.target.value)}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary" variant="contained">
              Save
              </Button>
            {/* <Button onClick={handleClose} color="primary" >
            Agree
          </Button> */}
          </DialogActions>
        </Dialog>
      </Grid>
    </div>
  );
}

export default ParentPersonalDetails;
