import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { Box, Grid, Button } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Paper from "@material-ui/core/Paper";
import { red } from "@material-ui/core/colors";
import AllOrders from './AllOrders';
import moment from "moment";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "antiquewhite"
  },
}));

const classesStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
  },
  pap: {
    backgroundColor: "antiquewhite",
    borderRadius: "6px",
    padding: "15px 0px",
  },
  stdname: {
    fontWeight: "500",
    display: "inline-block",
    textTransform: "capitalize",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function ViewOrderDetails({ orderDetails }) {
  const style = useStyles();
  const classes = classesStyles();
  const [value, setValue] = React.useState(0);
  const [back, setBack] = React.useState(false);
  console.log("ORDER DETAILS: ", orderDetails)
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const backToAllOrders= () => {
    setBack(true)
  }

  if (back) {
    return <AllOrders />;
  }

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Box pb={2}>
          <Button
            onClick={backToAllOrders}
            type="submit"
            size="large"
            pt={2}
            variant="contained"
            color="primary"
          >
            <KeyboardBackspaceIcon />
          </Button>
          <Typography variant="h6" style={{ display: "inline-block", paddingLeft: "10px", textTransform: "uppercase" }}>{orderDetails.name}</Typography>
        </Box>
      </Grid>
      <div className={style.root}>
        <AppBar position="static">
          <Tabs variant="fullWidth" value={value} onChange={handleChange} aria-label="simple tabs example">
            <Tab label="ORDER INFORMATION" {...a11yProps(0)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <div className={classes.root}>
            <Grid container spacing={3} className={classes.pap}>
              <Grid item xs={12} sm={6}>
                Student
                <Paper className={classes.paper}>{orderDetails?.student_id?.name}</Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                Order Details
                <Paper className={classes.paper}>{orderDetails.inventory?.map((item) => (item?.name)).join(", ")}
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                Status
                <Paper className={classes.paper}>{orderDetails?.status}</Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                Order Price
                <Paper className={classes.paper}>{orderDetails?.total_cost}</Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                Order Placed On
                <Paper className={classes.paper}>{orderDetails?.order_date ? moment(orderDetails.order_date).format("DD/MM/YYYY") : "-"}</Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                Order Dispatched On
                <Paper className={classes.paper}>{orderDetails?.dispatch_date ? moment(orderDetails.dispatch_date).format("DD/MM/YYYY") : "-"}</Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                Order Log Date
                <Paper className={classes.paper}>{orderDetails?.log_date ? moment(orderDetails.log_date).format("DD/MM/YYYY") : "-"}</Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                Tracking Number
                <Paper className={classes.paper}>{orderDetails?.tracking_number ? orderDetails.tracking_number : "-"}</Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                Courier Company
                <Paper className={classes.paper}>{orderDetails?.courier_company ? orderDetails.courier_company : "-"}</Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                Delivery Method
                <Paper className={classes.paper}>{orderDetails?.delivery_method ? orderDetails.delivery_method : "-"}</Paper>
              </Grid>

      <Grid container spacing={3} className={classes.pap}>
        <Grid
          item
          xs={12}
          sm={12}
          style={{ paddingBottom: "2px", paddingTop: "25px" }}
        >
          <Typography variant="h6">ADDRESS</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          Address
          <Paper className={classes.paper}>
            {orderDetails?.deliveryAddress?.house_no ? orderDetails?.deliveryAddress?.house_no : "N/A"}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          Area
          <Paper className={classes.paper}>
            {orderDetails?.deliveryAddress?.area ? orderDetails?.deliveryAddress?.area : "N/A"}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          State
          <Paper className={classes.paper}>
            {orderDetails?.deliveryAddress?.state ? orderDetails?.deliveryAddress?.state : "N/A"}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          City
          <Paper className={classes.paper}>
            {orderDetails?.deliveryAddress?.city ? orderDetails?.deliveryAddress?.city : "N/A"}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          Landmark
          <Paper className={classes.paper}>
            {orderDetails?.deliveryAddress?.landmark ? orderDetails?.deliveryAddress?.landmark : "N/A"}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          Pincode
          <Paper className={classes.paper}>
            {orderDetails?.deliveryAddress?.pincode ? orderDetails?.deliveryAddress?.pincode : "N/A"}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
        Center Code
          <Paper className={classes.paper}>
            {orderDetails?.student_id?.centerCode}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
        Phone Number
          <Paper className={classes.paper}>
            {orderDetails?.parent?.mobileNo}
          </Paper>
        </Grid>
      </Grid>
            </Grid>
          </div>
        </TabPanel>
      </div>
    </React.Fragment>
  );
}
