import React from "react";
import {
  Grid,
  Typography,
  Box,
  TextField,
} from "@material-ui/core";

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from "@material-ui/pickers";

  import DateFnsUtils from "@date-io/date-fns";
  import { GlobalContext } from "../../context/GlobalState";
function PaymentDetails(props) {
  const {encodeDecodeString} = React.useContext(GlobalContext);
  const {transactionRefNo, setTransactionRefNo, amount, setAmount, paymentDate, handlePaymentDate, remarks, setRemarks} = props
  const isAdmin = encodeDecodeString(window.localStorage.getItem("user"),'decode');
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Box pt={2} px={2}>
          <Typography variant="h6">Payment Details</Typography>
        </Box>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Box px={2}>
          <TextField
          disabled={isAdmin === "faculty" || isAdmin === "centerhead"}
          label="Bank Transaction Ref No"
          type="text"
          variant="outlined"
          margin="normal"
          fullWidth
           value={transactionRefNo} 
           onChange={e => setTransactionRefNo(e.target.value)}
          name="transactionRefNo"
        />
        </Box>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Box px={2}>
          <TextField
            disabled={isAdmin === "faculty" || isAdmin === "centerhead"}
            label="Amount"
            type="number"
            variant="outlined"
            margin="normal"
            fullWidth
            inputProps={{
              maxLength: 7
            }}
            value={amount} onChange={e => setAmount(e.target.value)}
            name="amount"
          />
        </Box>
      </Grid>

      <Grid item xs={12} sm={4}>
            <Box px={2} bgcolor="white" display="flex" justifyContent="center">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disabled={isAdmin === "faculty" || isAdmin === "centerhead"}
              disableToolbar
              disableFuture
              autoOk
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              label="Date (DD/MM/YYYY)"
              value={paymentDate}
              name="paymentDate"
              onChange={handlePaymentDate}
            />
            </MuiPickersUtilsProvider>
            </Box>
          </Grid>

      <Grid item xs={12} sm={6}>
        <Box px={2}>
          <TextField
          disabled={isAdmin === "faculty" || isAdmin === "centerhead"}
            label="Remarks"
            variant="outlined"
            margin="normal"
            fullWidth
           value={remarks} onChange={e => setRemarks(e.target.value)}
            name="remarks"
          />
        </Box>
      </Grid>

    </React.Fragment>
  );
}

export default PaymentDetails;
