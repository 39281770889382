import React from "react";
import {
  Grid,
  Typography,
  Box,
  TextField,
} from "@material-ui/core";
import { GlobalContext } from "../../context/GlobalState";

function BankDetails(props) {
  const {encodeDecodeString} = React.useContext(GlobalContext);
  const {acnumber,setAcnumber, ifsccode, setIfsccode, bankname, setBankname, aadhar, setAadhar, pan, setPan} = props
  const isAdmin = encodeDecodeString(window.localStorage.getItem("user"),'decode');

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Box pt={2} px={2}>
          <Typography variant="h6">Bank Details:</Typography>
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box px={2}>
        {isAdmin === "centerhead" || isAdmin === "operations" || isAdmin === "coo" || isAdmin === "academics" || isAdmin === "marketing" ?
          <TextField
            disabled
            label="Account Number"
            type="text"
            variant="outlined"
            margin="normal"
            fullWidth
             value={acnumber} 
             onChange={e => setAcnumber(e.target.value)}
            name="acNumber"
          /> : <TextField
          label="Account Number"
          type="text"
          variant="outlined"
          margin="normal"
          fullWidth
           value={acnumber} 
           onChange={e => setAcnumber(e.target.value)}
          name="acNumber"
        /> }
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box px={2}>
        {isAdmin === "centerhead" || isAdmin === "coo" || isAdmin === "academics" || isAdmin === "marketing" ?
          <TextField
            disabled
            label="IFSCode"
            type="text"
            variant="outlined"
            margin="normal"
            fullWidth
            value={ifsccode} onChange={e => setIfsccode(e.target.value.toUpperCase())}
            name="IFSCCode"
          /> : <TextField
          label="IFSCode"
          type="text"
          variant="outlined"
          margin="normal"
          fullWidth
          value={ifsccode} onChange={e => setIfsccode(e.target.value.toUpperCase())}
          name="IFSCCode"
        />}
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box px={2}>
        {isAdmin === "centerhead" || isAdmin === "coo" || isAdmin === "academics" || isAdmin === "marketing" ?
          <TextField
          disabled
            label="Bank Name"
            type="text"
            variant="outlined"
            margin="normal"
            fullWidth
             value={bankname} 
             onChange={e => setBankname(e.target.value.toUpperCase())}
            name="bankName"
          /> : <TextField
          label="Bank Name"
          type="text"
          variant="outlined"
          margin="normal"
          fullWidth
           value={bankname} 
           onChange={e => setBankname(e.target.value.toUpperCase())}
          name="bankName"
        />}
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box px={2}>
        {isAdmin === "centerhead" || isAdmin === "coo" || isAdmin === "academics" || isAdmin === "marketing" ?
          <TextField
          disabled
            label="Aadhaar Number"
            variant="outlined"
            margin="normal"
            inputProps={{
              maxLength: 12
            }}
            fullWidth
           value={aadhar} onChange={e => setAadhar(e.target.value)}
            name="aadhar"
          /> : <TextField
          label="Aadhaar Number"
          required
          variant="outlined"
          margin="normal"
          inputProps={{
            maxLength: 12
          }}
          fullWidth
         value={aadhar} onChange={e => setAadhar(e.target.value)}
          name="aadhar"
        />}
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box px={2}>
        {isAdmin === "centerhead" || isAdmin === "coo" || isAdmin === "academics" || isAdmin === "marketing" ?
          <TextField
            disabled
            label="PAN Number"
            type="text"
            variant="outlined"
            margin="normal"
            inputProps={{
              maxLength: 10
            }}
            fullWidth
             value={pan} onChange={e => setPan(e.target.value.toUpperCase())}
            name="pan"
          /> : <TextField
          label="PAN Number"
          required
          type="text"
          variant="outlined"
          margin="normal"
          inputProps={{
            maxLength: 10
          }}
          fullWidth
           value={pan} onChange={e => setPan(e.target.value.toUpperCase())}
          name="pan"
        />}
        </Box>
        <br></br>
      </Grid>
    </React.Fragment>
  );
}

export default BankDetails;
