import React from "react";
import { GlobalContext } from "../context/GlobalState";
import { Bar, Line, Pie, Doughnut } from "react-chartjs-2";

function Chart1({stat}) {
  let active = stat.faculty && stat.faculty.active;
  let onhold = stat.faculty && stat.faculty.onhold;
  let resigned = stat.faculty && stat.faculty.resigned;

  const dat = {
    labels: ["Active", "On Hold", "Resigned"],
    datasets: [
      {
        label: "",
        data: [active, onhold, resigned],
        backgroundColor: ["rgba(116,96,238,0.8)", "rgba(200,200,200,0.8)", "rgba(50,50,50,0.8)"]
      }
    ]
  };
  
  return (
    <div>
      <Pie data={dat} options={{ maintainAspectRatio: true }} />
    </div>
  );
}

export default Chart1;
