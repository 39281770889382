import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CssBaseline, Box } from "@material-ui/core";
import Appbar from "./Appbar";
import Drawers from "./Drawers";
import MediaCard2 from "./MediaCard2";
import AllParents from "./Parents/AllParents";
import AllStudents from './Students/AllStudents';
import AllClasses from "./Classes/AllClasses";
import AllFaculty from "./Faculty/AllFaculty";
import FacultyWorkHours from "./Faculty/FacultyWorkHours";
import AddFaculty from  './Faculty/AddFaculty'
import AllCenterCode from './Center Code/AllCenterCode'
import AddCenterCode from './Center Code/AddCenterCode'
import AllCenterHead from './Center Head/AllCenterHead'
import BankFile from './Payments/BankFile'
import PaymentHistory from './Payments/PaymentHistory'
import CenterPaymentHistory from './Payments/CenterPaymentHistory'
import BulkImport from './Payments/BulkImport'
import DaySummary from "./Payments/DaySummary";
import AddressDump from "./Payments/AddressDump";
import AllInventory from "./Inventory/AllInventory";
import AllOrders from "./Orders/AllOrders";
import Finance from './Payments/Finance';
import AllModules from "./Modules/AllModules";
import GraphData from "./Payments/Graph";
import EnquiryData from "./Enquiry/Enquiry";
import { GlobalContext } from '../context/GlobalState'



const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor:"#ebf2f6",
  },
  footclr: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor:"#ebf2f6",
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { LogOut } = React.useContext(GlobalContext);
  const { fragment } = React.useContext(GlobalContext)
  const { getRedirect } = React.useContext(GlobalContext);
  React.useEffect(() => {
    const center = new URLSearchParams(window.location.search).get('centerCode');
    if (center) {
      getRedirect("DAYSUMMARY")
    }
    loadfragment()
  }, [fragment])

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const loadfragment = () => {
  
    switch (fragment) {
      case "HOME": return <MediaCard2/> 
      case "CLASSES": return  <AllClasses />
      case "STUDENTS": return  <AllStudents />
      case "PARENTS": return  <AllParents />
      case "FACULTY": return <AllFaculty /> 
      case "ADDNEWFACULTY": return <AddFaculty />
      case "ALLCENTERCODE": return <AllCenterCode />
      case "ALLCENTERHEAD": return <AllCenterHead />
      case "ADDNEWCENTERCODE": return <AddCenterCode />
      case "FACULTYWORKHOURS" :return <FacultyWorkHours/>
      case "BANKFILE" :return < BankFile/>
      case "PAYMENTHISTORY" :return < PaymentHistory/>
      case "CENTERPAYMENTHISTORY" :return < CenterPaymentHistory/>
      case "BULKIMPORT" :return < BulkImport/>
      case "DAYSUMMARY" :return < DaySummary/>
      case "ADDRESSDUMP" :return < AddressDump/>
      case "ALLINVENTORY" :return < AllInventory/>
      case "ALLORDERS" :return <AllOrders/>
      case "FINANCEHISTORY" :return <Finance/>
      case "ALLMODULE" :return <AllModules/>
      case "GRAPH" :return <GraphData/>
      case "ENQUIRY" :return <EnquiryData/>
      default: break;
    }
  } 

  return (
    <div className={classes.root}>
      <CssBaseline />

      <Appbar open={open} classes={classes} onOpen={handleDrawerOpen} LogOut={LogOut}/>

      <Drawers open={open} classes={classes} onClose={handleDrawerClose} />

      <main className={classes.content}>
        <div className={classes.toolbar} />
         {loadfragment()}
        <Box mt={1}>
        <footer className={classes.footclr}>
        </footer>
        </Box>
      </main>
    </div>
  );
}
