import React from "react";
import { GlobalContext } from "../../context/GlobalState";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
//import DialogContent from "@material-ui/core/DialogContent";
//import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

import {
  Paper,
  Grid,
  Typography,
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from "@material-ui/core";
import AllCenterCode from "./AllCenterCode";

function AddCenterCode() {
  const [statesel, setStateSel] = React.useState("");
  const [citysel, setCitySel] = React.useState({});
  const [area, setArea] = React.useState("");
  const [centercd, setCentercd] = React.useState("");
  const { allstatecity, getStateCityDetails, err } = React.useContext(GlobalContext);
  const { addCenterCodeDetails } = React.useContext(GlobalContext);
  const [cityOptions, setCityOptions] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [back, setBack] = React.useState(false);
  const data = JSON.parse(JSON.stringify(allstatecity));

  const onSubmit = e => {
    e.preventDefault();

    const addcentercode = {
      area: area,
      city: citysel,
      state: statesel,
      centerCode: centercd,
      status: "Active"
    };
    addCenterCodeDetails(addcentercode);
    setOpen(true);
  };

  React.useEffect(() => {
    getStateCityDetails();
  }, []);

  const onChangeState = e => {
    const value = e.target.value ? e.target.value : "";
    setStateSel(value);
    setCityOptions(data[value]);
  };

  const onChangeUserCity = e => {
    setCitySel(e.target.value);
  };

  const handleClose = () => {
    setOpen(false);
    setBack(true);
  };

  if (back) {
    return <AllCenterCode />;
  }
  return (
    <React.Fragment>
      <Box pb={2}>
        <Button
          onClick={handleClose}
          type="submit"
          size="large"
          pt={2}
          variant="contained"
          color="primary"
        >
          <KeyboardBackspaceIcon />
        </Button>
      </Box>

      <form onSubmit={onSubmit}>
        <Box boxShadow={3} mb={1}>
          <Grid container component={Paper}>
            <Grid item xs={12}>
              <Box p={2}>
                <Typography variant="h5">Add Center Code</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box px={2} my={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="select-state-name">Select State</InputLabel>
                  <Select
                    labelId="select-state-name"
                    id="demo-simple-select-filled"
                    value={statesel}
                    onChange={onChangeState}
                    name="state"
                    label="Select State"
                    required
                  >
                    {Object.keys(data).map(function(keyName) {
                      return (
                        <MenuItem key={keyName} value={keyName}>
                          {keyName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box px={2} my={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="select-city-name">Select City</InputLabel>
                  <Select
                    labelId="select-city-name"
                    id="demo-simple-select-filled1"
                    value={citysel}
                    onChange={onChangeUserCity}
                    name="city"
                    label="Select City"
                    required
                  >
                    {cityOptions && cityOptions.length
                      ? cityOptions.map(keyName => (
                          <MenuItem key={keyName} value={keyName}>
                            {keyName}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box px={2}>
                <TextField
                  label="area"
                  id=""
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={area}
                  onChange={e => setArea(e.target.value)}
                  name="area"
                  required
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box px={2}>
                <TextField
                  label="Center Code"
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={centercd}
                  onChange={e => setCentercd(e.target.value)}
                  name="centerCode"
                  required
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box p={2}>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </form>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          align="center"
          style={{ background: "antiquewhite" }}
          id="responsive-dialog-title"
        >
          {err ? err : "New Center Code has been added successfully"}
        </DialogTitle>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button onClick={handleClose} variant="contained" color="primary">
            Back
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default AddCenterCode;
