import React from "react";
import { GlobalContext } from "../../context/GlobalState";
import { CSVLink } from "react-csv";
import {
  Paper, Grid, Typography, Box, Button, MenuItem, FormControl, Select,
  InputLabel, CircularProgress, TableHead, Dialog, Table, DialogActions,
  DialogContent, DialogContentText, DialogTitle, TableBody, TableCell, TableRow, Link
  } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";

export default function DaySummary() {
  const [back, setBack] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState("");
  const [dbutton, setDbutton] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [startdate, setStartdate] = React.useState(new Date());
  const [enddate, setEnddate] = React.useState(new Date());
  const [centercode, setCentercode] = React.useState("All");
  const [centercodeselected, setCentercodeSelected] = React.useState("");
  const { allcentercode, getCenterCodeDetails } = React.useContext( GlobalContext);
  const { getRedirect, encodeDecodeString } = React.useContext(GlobalContext);
  const isAdmin = encodeDecodeString(window.localStorage.getItem("user"),'decode');
  const userCenterCode = localStorage.getItem("centercode")
  const {
    summaryData,
    summaryDataFaculty,
    getSummaryDataDetails,
    getSummaryDataDetailsForFaculty,
    err
  } = React.useContext(GlobalContext);

  const handleClose = () => {
    setOpen(false);
    setBack(true);
  };

  const handleStartDateChange = date => {
    setStartdate(date);
    setEnddate(date);
  };

  const handleEndDateChange = date => {
    setEnddate(date);
  };



React.useEffect((e) => {
  const center = new URLSearchParams(window.location.search).get('centerCode');
  const from = new URLSearchParams(window.location.search).get('from');
  const to = new URLSearchParams(window.location.search).get('to');
    if (center && from && to) {
      setStartdate(from);
      setEnddate(to);
      setCentercode(center);
      const summary = {
        centercode: center,
        type: "daily",
        fromDate: moment(from).format("YYYY-MM-DD"),
        toDate: moment(to).format("YYYY-MM-DD")
      };
      loadTable(summary);

    }
    getCenterCodeDetails("Active");
  }, []);

  const loadTable = async (summary)  => {
    await getSummaryDataDetails(summary);
    if (centercode === 'All'){
      await getSummaryDataDetailsForFaculty(summary);
    }
    setDbutton(false);
    setLoading(false);
  }

  const openTab = e =>{
    const environment = process.env.REACT_APP_STAGE;
    let apiUrl
    if(environment == "stage") {
      apiUrl = "https://staging-admin.koalapreschool.com/dashboard"
    } else if (environment == "prod") {
      apiUrl = "https://admin.koalapreschool.com/dashboard" // prod api url goes here
    } else {
      apiUrl = "http://localhost:3000/dashboard"
    }
    let start = moment(startdate).format("YYYY-MM-DD")
    let end = moment(enddate).format("YYYY-MM-DD")
    window.open(`${apiUrl}?centerCode=${e}&from=${start}&to=${end}`)
  }

  const disableWeekdaysFrom  = date => {
    if (type === "weekly") {
      return date.getDay() === 0 || date.getDay() === 2 || date.getDay() === 3 || date.getDay() === 4 || date.getDay() === 5 || date.getDay() === 6
    }
    // return date.getDay() === 0 || date.getDay() === 6; // for weekends disable
  }

  const disableWeekdaysTo  = date => {
    if (type === "weekly") {
      return date.getDay() === 1 || date.getDay() === 2 || date.getDay() === 3 || date.getDay() === 4 || date.getDay() === 5 || date.getDay() === 6
    }
  // return date.getDay() === 0 || date.getDay() === 6; // for weekends disable
}
let headCells = []
const totalRow = { id: 'date', numeric: false, label: "Date" }
for (const x in allcentercode) {
  headCells.push({id: allcentercode[x].centerCode, numeric: false, label:allcentercode[x].centerCode});
}
headCells.splice(0, 0, totalRow);

const headCellsTable2 = [
  { id: 'date', numeric: false,  label: 'Date' },
  { id: 'credit', numeric: false,  label: 'Center Code' },
  { id: 'payment', numeric: false,  label: 'At Home' },
  { id: 'student', numeric: false,  label: 'Home POD' },
  { id: 'parent', numeric: false, label: "Online" },
  { id: 'faculty', numeric: false, label: "Online POD" },
  { id: 'hours', numeric: false, label: "Total Hours" },
  { id: 'center', numeric: false, label: "Available Credits" },
  { id: 'active-s', numeric: false, label: "Student - Active" },
  { id: 'onhold-s', numeric: false, label: "Student - OnHold" },
  { id: 'opted-s', numeric: false, label: "Student - OptedOut" },
  { id: 'active-f', numeric: false, label: "Faculty - Active" },
  { id: 'onhold-f', numeric: false, label: "Faculty - OnHold" },
  { id: 'resigned-f', numeric: false, label: "Faculty - Resigned" },
  { id: 'weekly-s', numeric: false, label: "Weekly Active Student" },
  { id: 'daily-s', numeric: false, label: "Daily Active Student" },
  { id: 'newly-a', numeric: false, label: "Student - Newly Added" }
];

  let singleHeadCells = [
  { id: 'date', numeric: false,  label: 'Date' },
  { id: 'credit', numeric: false,  label: 'Center Code' },
  { id: 'payment', numeric: false,  label: 'At Home' },
  { id: 'student', numeric: false,  label: 'Home POD' },
  { id: 'parent', numeric: false, label: "Online" },
  { id: 'faculty', numeric: false, label: "Online POD" },
  { id: 'hours', numeric: false, label: "Total Hours" },
  { id: 'center', numeric: false, label: "Available Credits" },
  { id: 'active-s', numeric: false, label: "Student - Active" },
  { id: 'onhold-s', numeric: false, label: "Student - OnHold" },
  { id: 'opted-s', numeric: false, label: "Student - OptedOut" },
  { id: 'active-f', numeric: false, label: "Faculty - Active" },
  { id: 'onhold-f', numeric: false, label: "Faculty - OnHold" },
  { id: 'resigned-f', numeric: false, label: "Faculty - Resigned" },
  { id: 'weekly-s', numeric: false, label: "Weekly Active Student" },
  { id: 'daily-s', numeric: false, label: "Daily Active Student" },
  { id: 'newly-a', numeric: false, label: "Student - Newly Added" }
  ]

  const onSubmit = async e => {
    setCentercodeSelected(centercode)
    setDbutton(true);
    setLoading(true);
    e.preventDefault();
    const summary = {
      centercode: centercode,
      type: "daily",
      fromDate: moment(startdate).format("YYYY-MM-DD"),
      toDate: moment(enddate).format("YYYY-MM-DD")
    };
    await loadTable(summary)
  };
  let filterData = summaryData.filter((item)=>(
    item[0] !== "Date"
  ))
  
  const csvData = summaryData;

  let filterDataTable2 = summaryDataFaculty.filter((item)=>(
    item[0] !== "Date"
  ))

  const changeCenterCode = (e) => {
    setCentercode(e.target.value);
  };

  return (
    <React.Fragment>
      <Box boxShadow={3} mb={1}>
        <form onSubmit={(e)=>onSubmit(e) }>
          <Grid container component={Paper}>
            <Grid
              item
              xs={12}
              style={{ background: "antiquewhite", marginBottom: "16px" }}
            >
              <Box p={2}>
                <Typography variant="h6">Day Summary</Typography>
              </Box>
            </Grid>

    <Grid item xs={12} sm={3}>
        <Box px={2} mt={2}>
          <FormControl variant='outlined' required fullWidth>
            <InputLabel id='demo-simple-select-filled-label12' required>
              Center Code
            </InputLabel>
            <Select
              labelId='demo-simple-select-filled-label12'
              id='demo-simple-select-filled12'
              value={centercode}
              onChange={changeCenterCode}
              name='centerCode'
              label='Center Code'
            >
              {isAdmin && isAdmin !== "centerhead" &&
                <MenuItem key='all' value='All'>
                  All
                </MenuItem>}
                {isAdmin && isAdmin === "centerhead" && userCenterCode ? 
                <MenuItem key={userCenterCode} value={userCenterCode}>
                  {userCenterCode}
                </MenuItem> : 
              allcentercode.map((code) => (
                <MenuItem key={code._id} value={code.centerCode}>
                  {code.centerCode}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>

          <Grid item xs={12} md={3}>
            <Box px={2} bgcolor="white" display="flex" justifyContent="center">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              disableFuture
              autoOk
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              label="Start Date (DD/MM/YYYY)"
              value={startdate}
              name="startdate"
              onChange={handleStartDateChange}
              shouldDisableDate={disableWeekdaysFrom}
            />
            </MuiPickersUtilsProvider>
            </Box>
          </Grid>

          <Grid item xs={12} md={3}>
            <Box px={2} bgcolor="white" display="flex" justifyContent="center">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              disableFuture
              autoOk
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              label="End Date (DD/MM/YYYY)"
              value={enddate}
              name="enddate"
              onChange={handleEndDateChange}
              shouldDisableDate={disableWeekdaysTo}
            />
            </MuiPickersUtilsProvider>
            </Box>
          </Grid>

            <Grid item xs={12}>
            <Box p={2}>
            <Button style={{float:"right"}}
                  type="submit"
                  size="large"
                  variant="contained"
                  color="primary"
                >
                  {loading ? (
                    <>
                      Loading
                      <CircularProgress
                        style={{ color: "#fff", marginLeft: "15px" }}
                        size={20}
                      />
                    </>
                  ) : (
                    "Generate Statement"
                  )}
                </Button>
              <Button
                disabled={dbutton}
                id="download"
                size="large"
                variant="contained"
                color="primary"
              >
                <CSVLink
                  data={csvData}
                  filename={`${type}-day-statements-${moment().format("DD-MM-YYYY")}.csv`}
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  Download{" "}
                </CSVLink>
              </Button>
              
            </Box>
            </Grid>
          </Grid>
        </form>
        {filterData.length ?
        <div style={{margin: "15px", border: "1px solid", borderRadius: "6px"}}>
        <Table style={{backgroundColor: "#fff"}}>
        <TableHead style={{backgroundColor: "#3f51b5", color: "#fff"}}>
        {centercodeselected === 'All' ? 
      <TableRow style={{color: "#fff"}}>
      {headCells.map((headCell) => (
          <TableCell style={{color: "#fff"}}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            onClick={() => openTab(headCell.id)}
            component="a"
          >
          <Link style={{color: "#fff"}} underline="always" component="button">{headCell.label}</Link>
          </TableCell>
        ))}
      </TableRow>
       : 
      <TableRow style={{color: "#fff"}}>
      {singleHeadCells.map((singleHeadCell) => (
          <TableCell style={{color: "#fff"}}
            key={singleHeadCell.id}
            align={singleHeadCell.numeric ? 'right' : 'center'}
            padding={singleHeadCell.disablePadding ? 'none' : 'default'}
            onClick={() => openTab(singleHeadCell.id)}
            component="a"
          >
          {singleHeadCell.label}
          </TableCell>
        ))}
      </TableRow>
      }
      </TableHead>
      {centercodeselected === 'All' ?
      <TableBody>
      {filterData.map((value, index)=>{
  return (
  <TableRow key={index+1}>
    {value.map(function(object, i){
        return <TableCell align="center">{value[i] ? value[i] : '-'}</TableCell>
    })}
  </TableRow>  
  );
})}
</TableBody> : 
<TableBody>
      {filterData.map((value, index)=>{
  return (
  <TableRow key={index+1}>
    {value.map(function(object, i){
        return <TableCell align="center">{value[i] ? value[i] : '-'}</TableCell>
    })}
  </TableRow>  
  );
})}
</TableBody> }
</Table></div> : null }

{filterDataTable2.length && centercodeselected === 'All' ?
        <div style={{margin: "15px", border: "1px solid", borderRadius: "6px"}}>
        <Table style={{backgroundColor: "#fff"}}>
        <TableHead style={{backgroundColor: "#3f51b5", color: "#fff"}}>
      <TableRow style={{color: "#fff"}}>
      {headCellsTable2.map((headCell) => (
          <TableCell style={{color: "#fff"}}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'default'}
          >        
          {headCell.label}
          </TableCell>
        ))}

      </TableRow>
      </TableHead>
      <TableBody>
      {filterDataTable2.map((value, index)=>{
  return (
  <TableRow key={index+1}>
  {value.map(function(object, i){
      return <TableCell align="center">{value[i] ? value[i] : '-'}</TableCell>
  })}
  </TableRow>   
  );
})}
</TableBody>
</Table></div> : null }
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          align="center"
          style={{ background: "antiquewhite" }}
          id="responsive-dialog-title"
        >
          {err ? err : "Summary report is generated"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button onClick={handleClose} variant="contained" color="primary">
            Back
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
