import React from "react";
import { GlobalContext } from "../context/GlobalState";
import { Bar, Line, Pie, Doughnut } from "react-chartjs-2";

function Chart3({stat}) {
  let Completed = stat.classes && stat.classes.completed;
  let Cancelled = stat.classes && stat.classes.cancelled;
  let Ongoing = stat.classes && stat.classes.ongoing;
  let Upcoming = stat.classes && stat.classes.upcoming;

  const dat = {
    labels: ['Completed','Cancelled','Ongoing','Upcoming'],
    datasets: [
      {
        label: "",
        data: [Completed, Cancelled, Ongoing, Upcoming],
        backgroundColor: ["rgba(33,150,243,0.8)", "rgba(200,200,200,0.8)","rgba(60,240,192,0.8)", "rgba(252,75,108,0.8)"]
      }
    ]
  };
  
  return (
    <div>
      <Pie data={dat} options={{ maintainAspectRatio: true }} />
    </div>
  );
}

export default Chart3;
