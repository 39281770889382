import React from "react";
import {
  Grid,
  Typography,
  Box,
  TextField,
} from "@material-ui/core";

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from "@material-ui/pickers";

  import DateFnsUtils from "@date-io/date-fns";
  import { GlobalContext } from "../../context/GlobalState";
function CHPaymentDetails(props) {
  const {encodeDecodeString} = React.useContext(GlobalContext);
  const {transactionRefNo, setTransactionRefNo, amount, setAmount, paymentDate, handlePaymentDate, remarks, setRemarks} = props
  const isAdmin = encodeDecodeString(window.localStorage.getItem("user"),'decode');
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Box pt={2} px={2}>
          <Typography variant="h6">Payment Details</Typography>
        </Box>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Box px={2}>
          {isAdmin === "centerhead" ?
          <TextField
          disabled
            label="Bank Transaction Ref No"
            type="text"
            variant="outlined"
            margin="normal"
            fullWidth
             value={transactionRefNo} 
             onChange={e => setTransactionRefNo(e.target.value)}
            name="transactionRefNo"
          /> : <TextField
          label="Bank Transaction Ref No"
          type="text"
          variant="outlined"
          margin="normal"
          fullWidth
           value={transactionRefNo} 
           onChange={e => setTransactionRefNo(e.target.value)}
          name="transactionRefNo"
        /> }
        </Box>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Box px={2}>
        {isAdmin === "centerhead" ?
          <TextField
            disabled
            label="Amount"
            type="number"
            variant="outlined"
            margin="normal"
            fullWidth
            inputProps={{
              maxLength: 7
            }}
            value={amount} onChange={e => setAmount(e.target.value)}
            name="amount"
          /> : 
          <TextField
            label="Amount"
            type="number"
            variant="outlined"
            margin="normal"
            fullWidth
            inputProps={{
              maxLength: 7
            }}
            value={amount} onChange={e => setAmount(e.target.value)}
            name="amount"
          /> }
        </Box>
      </Grid>

      <Grid item xs={12} sm={4}>
            <Box px={2} bgcolor="white" display="flex" justifyContent="center">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {isAdmin === "centerhead" ?
            <KeyboardDatePicker
              disabled
              disableToolbar
              disableFuture
              autoOk
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              label="Date (DD/MM/YYYY)"
              value={paymentDate}
              name="paymentDate"
              onChange={handlePaymentDate}
            /> :
            <KeyboardDatePicker
              disableToolbar
              disableFuture
              autoOk
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              label="Date (DD/MM/YYYY)"
              value={paymentDate}
              name="paymentDate"
              onChange={handlePaymentDate}
            /> }
            </MuiPickersUtilsProvider>
            </Box>
          </Grid>

      <Grid item xs={12} sm={6}>
        <Box px={2}>
        {isAdmin === "centerhead" ?
          <TextField
            disabled
            label="Remarks"
            variant="outlined"
            margin="normal"
            fullWidth
           value={remarks} onChange={e => setRemarks(e.target.value)}
            name="remarks"
          /> :
          <TextField
            label="Remarks"
            variant="outlined"
            margin="normal"
            fullWidth
           value={remarks} onChange={e => setRemarks(e.target.value)}
            name="remarks"
          /> }
        </Box>
      </Grid>

    </React.Fragment>
  );
}

export default CHPaymentDetails;
