import React from "react";
import {
  Grid,
  Typography,
  Box,
  TextField,
} from "@material-ui/core";

function CHBankDetails(props) {
  const {acnumber,setAcnumber, ifsccode, setIfsccode, bankname, setBankname, aadhar, setAadhar, pan, setPan} = props
  

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Box pt={2} px={2}>
          <Typography variant="h6">Bank Details</Typography>
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box px={2}>
          <TextField
            label="Account Number"
            type="text"
            variant="outlined"
            margin="normal"
            fullWidth
            required
             value={acnumber} 
             onChange={e => setAcnumber(e.target.value)}
            name="acNumber"
          />
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box px={2}>
          <TextField
           required
            label="IFSC Code"
            type="text"
            variant="outlined"
            margin="normal"
            fullWidth
            value={ifsccode} onChange={e => setIfsccode(e.target.value.toUpperCase())}
            name="IFSCCode"
          />
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box px={2}>
          <TextField
            required
            label="Bank Name"
            type="text"
            variant="outlined"
            margin="normal"
            fullWidth
             value={bankname} 
             onChange={e => setBankname(e.target.value.toUpperCase())}
            name="bankName"
          />
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box px={2}>
          <TextField
            required
            label="Aadhar Number"
            type="text"
            variant="outlined"
            margin="normal"
            fullWidth
           value={aadhar} onChange={e => setAadhar(e.target.value)}
            name="aadhar"
          />
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box px={2}>
          <TextField
            required
            label="PAN Number"
            type="text"
            variant="outlined"
            margin="normal"
            fullWidth
             value={pan} onChange={e => setPan(e.target.value.toUpperCase())}
            name="pan"
          />
        </Box>
        <br></br>
      </Grid>
    </React.Fragment>
  );
}

export default CHBankDetails;
