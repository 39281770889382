import axios from "axios";

const apiUrl = 'https://api.koalapreschool.com/api'
const secretkey = 'FM?WafKiopS@k2&L$zfS#aA9'

export async function getStateCityDetail() {
    try {
      const values = await axios.get(
        `${apiUrl}/statecity`,
        {
          headers: {
            "secret-key": secretkey
          }
        }
      );
      return values.data.statecity

    } catch (err) {
      console.log(err);
    }
  }