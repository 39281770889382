import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { GlobalContext } from "../../context/GlobalState";
import {
  Table, Box, InputBase, CircularProgress, TableBody, TableCell, TableContainer, TableHead, TablePagination,
  TableRow, TableSortLabel, Paper, IconButton, Tooltip, Button, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, fade, lighten, makeStyles, useTheme, InputLabel, FormControl, Select, Input, MenuItem
} from '@material-ui/core';

import { Edit, Search, VisibilitySharp } from '@material-ui/icons';
import AddCenterCode from './AddCenterCode'
import UpdateCenterCode from './UpdateCenterCode';
import ViewCenterCode from './ViewCenterCode';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'state', numeric: false, label: 'State' },
  { id: 'city', numeric: false, label: 'City' },
  { id: 'area', numeric: false, label: 'Area' },
  { id: 'centerCode', numeric: false, label: 'Center Code' },
  { id: "action", numeric: false, label: "Action" }
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox"> */}
        {/* <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          /> */}
        {/* </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? 'right' : 'left'}
            align={headCell.numeric ? 'right' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto"
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit",
    border: '1px solid #dddd',
    margin: '6px 6px 0px 6px',
    borderRadius: '6px'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch"
      }
    }
  }
}));

export default function AllCenterCode() {
  const classes = useStyles();
  //const [rows, setData] = useState([]);
  const [selectedStatusType, setSelectedStatusType] = useState("Active");
  const [data, setData] = useState([]);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('state');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [open, setOpen] = React.useState(false);
  const [newdata, setNewdata] = React.useState({})
  const [onupdate, setUpdate] = React.useState(false)
  const [onview, setView] = React.useState(false)
  const [addcenter, setAddcenter] = React.useState(false);
  const [deleteid, setDeleteId] = React.useState()
  const [searchTerm, setSearchTerm] = React.useState("");
  const [spinner, setSpinner] = React.useState(true);
  const theme = useTheme();
  //const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));


  const { allcentercode, getCenterCodeDetails } = React.useContext(GlobalContext);

  const { deleteCenterCodeDetails } = React.useContext(GlobalContext);


  const showData = (e, id) => {
    setUpdate(true)
    //setDetails(false)
    var dat = allcentercode.filter(item => item._id === id)
    setNewdata(dat[0])
  }

  const viewData = (e, id) => {
    setView(true)
    var dat = allcentercode.filter(item => item._id === id)
    setNewdata(dat[0])
  }

  React.useEffect(() => {
    setData(allcentercode);
    setTimeout(() => setSpinner(false), 3500);
  }, [allcentercode])

  React.useEffect(() => {
    let searched = allcentercode.filter(
      row =>
        row.state.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
        row.city.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
        row.area.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
        row.centerCode.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
    );

    if (searchTerm === "") {
      getCenterCodeDetails();
      setData(searched);
    } else {
      setData(searched);
    }
  }, [searchTerm]);



  const handleSearch = event => {
    setSearchTerm(event.target.value);
  };

  const filterStudents = (ev) => {
    const { value } = ev.target;
    setSpinner(true);
    setSelectedStatusType(value);
    getCenterCodeDetails(value);
  }


  const handleClickOpen = (e, id) => {
    setOpen(true);
    var datdel = allcentercode.filter(item => item._id == id)
    datdel.map((m) => setDeleteId(m.centerCode))
  };

  const deleteCode = () => {
    deleteCenterCodeDetails(deleteid)
    getCenterCodeDetails();
    setOpen(false);
  };

  const goToAddcentercode = () => {
    setAddcenter(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = allcentercode.map((n) => n.state);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, allcentercode.length - page * rowsPerPage);

  if (addcenter) { return <AddCenterCode /> }
  if (onupdate) { return <UpdateCenterCode showData={newdata} /> }
  if (onview) { return <ViewCenterCode centerCodeData={newdata} /> }

  return (
    <div className={classes.root}>
      <Box display="flex" pb={2}>
        <Box flexGrow={1}  >
          <Button
            onClick={goToAddcentercode}
            type="submit"
            size="large"
            pt={2}
            variant="contained"
            color="primary"
          >
            Add center Code
          </Button>
        </Box>
      </Box>
      <Paper className={classes.paper}>
        <div className={classes.search} style={{ display: "inline-block", marginTop: "14px" }}>
          <div className={classes.searchIcon}>
            <Search />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
            inputProps={{ "aria-label": "search" }}
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        <div style={{ float: "right", marginRight: "36px", marginTop: "14px", width: "150px" }}>
          <FormControl fullWidth>
            <InputLabel style={{fontSize: "15px"}} id="class-status-label">Select status</InputLabel>
            <Select style={{fontSize: "13px", width:"70%"}}
              labelId="class-status-label"
              id="class-status"
              value={selectedStatusType || 'Active'}
              onChange={filterStudents}
              input={<Input />}
            >
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="InActive">InActive</MenuItem>
            </Select>
          </FormControl>
        </div>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={allcentercode.length}
            />
            {spinner ? <TableBody style={{ position: "relative" }}>
              <TableRow>
                <TableCell>
                  <CircularProgress
                    style={{ padding: "5px", position: "absolute", left: "50%" }}
                    color="primary"
                  />
                </TableCell>
              </TableRow>
            </TableBody> :
              <TableBody>
                {stableSort(data, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        //onClick={(event) => handleClick(event, row.name)}
                        // role="checkbox"
                        // aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={index}
                      // selected={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox"> */}
                        {/* <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        /> */}
                        {/* </TableCell> */}
                        <TableCell component="th" scope="row" align="center">{row.state}</TableCell>
                        <TableCell align="center">{row.city}</TableCell>
                        <TableCell align="center">{row.area}</TableCell>
                        <TableCell align="center">{row.centerCode}</TableCell>
                        <TableCell align="center">
                          <Tooltip title="Edit" arrow>
                            <IconButton className="bg5"
                              onClick={e => showData(e.currentTarget.value, row._id)}
                            >
                              <Edit />
                            </IconButton>
                          </Tooltip>
                          {/* <Tooltip title="Delete" arrow>
                            <IconButton
                              className="bg5"
                              onClick={e => handleClickOpen(e.currentTarget.value, row._id)}
                            >
                              <DeleteForever />
                            </IconButton>
                          </Tooltip> */}
                          <Tooltip title="View" arrow>
                            <IconButton
                              className="bg5"
                              onClick={e => viewData(e.currentTarget.value, row._id)}
                            >
                              <VisibilitySharp />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {/* {emptyRows > 0 && (
                  <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                  </TableRow>
                )} */}
              </TableBody>
            }
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={allcentercode.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Are you sure you want to Delete?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Clicking on Yes will deactivate the Center Code.
            Click on the button Yes or No to confirm.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={deleteCode} color="primary">
            Yes
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
