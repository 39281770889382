import React from "react";
import { GlobalContext } from "../../context/GlobalState";
import {
  FormControlLabel,
  FormGroup,
  Checkbox,
  Grid,
  Typography,
  Box,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@material-ui/core";

const lookup = {
  karnataka: [{ id: "1", text: "Bangalore" }],
  Rajasthan: [{ id: "2", text: "Jaipur" }],
  MadhyaPradesh: [{ id: "3", text: "Indore" }],
  Gujarat: [{ id: "4", text: "Surat" }],
  Maharashtra: [
    { id: "5", text: "Pune" },
    { id: "6", text: "Navi Mumbai" },
  ],
  Telangana: [{ id: "7", text: "Hyderabad" }],
};

// const dataValue = "karnataka"
function AddressDetails(props) {
  const {
    dataValue,
    setDataValue,
    city,
    setCity,
    houseno,
    setHouseno,
    area,
    setArea,
    pincode,
    setPincode,
    landmark,
    setLandmark,
    catchmentradius,
    setCatchmentRadius,
    centercode,
    setCentercode,
    statename,
    setStateName,
    trd,
    setTrd
  } = props;
  const options = lookup[dataValue];

  const { allcentercode, getCenterCodeDetails, encodeDecodeString } = React.useContext( GlobalContext);
  const {allstatecity, getStateCityDetails} = React.useContext(GlobalContext);
  const [cityOptions, setCityOptions] = React.useState([]);
  const data = JSON.parse(JSON.stringify(allstatecity));
  const isAdmin = encodeDecodeString(window.localStorage.getItem("user"),'decode');
  React.useEffect(() => {
    getStateCityDetails()
  }, [])

 

  const handleChange = (event) => {
    
     let index
     if (event.target.checked) {
       
      // add the numerical value of the checkbox to options array
      trd.push(event.target.value)
      let trd1 = [...new Set(trd)]
      setTrd(trd1)
     }
     else {
      index = trd.indexOf(event.target.value)
      trd.splice(index, 1)
      let trd1 = [...new Set(trd)]
      setTrd(trd1)
     }
  };
 
  React.useEffect(() => {
    getCenterCodeDetails();
  }, []);


  const onChangesc = (e) => {
    const value = e.target.value ? e.target.value : '';
    setStateName(value);
    setCityOptions(data[value]);
  }

  const onChangeUserCity = (e) => {
    //onsole.log(value)
    setCity(e.target.value)
  }

  const changeCenterCode = (e) => {
    setCentercode(e.target.value);
  };
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Box pt={2} px={2}>
          <Typography variant='h6'>Faculty Trained In:</Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box px={2}>
        {/* <Typography variant='h6' style={{fontSize:"17px"}}>Trained in conducting classes</Typography> */}
          <FormGroup row >
            <FormControlLabel disabled={isAdmin === 'faculty'}
              control={
                <Checkbox onChange={handleChange}  value='Play Group' name='Play Group' checked={trd.includes('Play Group')}/>
              }
              label='Play Group'
            />
     <FormControlLabel disabled={isAdmin === 'faculty'} control={ <Checkbox  onChange={handleChange} name='Nursery' value='Nursery' checked={trd.includes('Nursery')} /> } label='Nursery'/>
      <FormControlLabel disabled={isAdmin === 'faculty'} control={<Checkbox name="Jr KG" onChange={handleChange} value='Jr KG' checked={trd.includes('Jr KG')}/>} label="Jr KG" />
      <FormControlLabel disabled={isAdmin === 'faculty'}  control={<Checkbox name="Sr KG" onChange={handleChange} value='Sr KG' checked={trd.includes('Sr KG')}/>} label="Sr KG" />
      <FormControlLabel disabled={isAdmin === 'faculty'}  control={<Checkbox name="Demo" onChange={handleChange} value='Demo' checked={trd.includes('Demo')}/>} label="Demo" />
      <FormControlLabel disabled={isAdmin === 'faculty'}  control={<Checkbox name="Toddler" onChange={handleChange} value='Toddler' checked={trd.includes('Toddler')}/>} label="Toddler" />
      <FormControlLabel disabled={isAdmin === 'faculty'}  control={<Checkbox name="Class 1" onChange={handleChange} value='Class 1' checked={trd.includes('Class 1')}/>} label="Class 1" />
      <FormControlLabel disabled={isAdmin === 'faculty'}  control={<Checkbox name="Class 2" onChange={handleChange} value='Class 2' checked={trd.includes('Class 2')}/>} label="Class 2" />
          </FormGroup>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box pt={2} px={2}>
          <Typography variant='h6'>Add Address:</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box px={2}>
          <TextField
            disabled={isAdmin === 'faculty'}
            label='House Number / #Flat No'
            id=''
            type='text'
            variant='outlined'
            margin='normal'
            fullWidth
            value={houseno}
            onChange={(e) => setHouseno(e.target.value)}
            name='house_no'
          />
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box px={2}>
          <TextField
            disabled={isAdmin === 'faculty'}
            label='Area'
            id=''
            type='text'
            variant='outlined'
            margin='normal'
            required
            fullWidth
            value={area}
            onChange={(e) => setArea(e.target.value)}
            name='area'
          />
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box px={2}>
          <TextField
            disabled={isAdmin === 'faculty'}
            label='Landmark'
            id=''
            type='text'
            variant='outlined'
            margin='normal'
            required
            fullWidth
            value={landmark}
            onChange={(e) => setLandmark(e.target.value)}
            name='landmark'
          />
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box px={2} mt={2}>
          <FormControl variant='outlined' fullWidth required disabled={isAdmin === 'faculty'}>
            <InputLabel id='demo-simple-select-filled-label1234' required>
              State
            </InputLabel>
            <Select
              labelId='demo-simple-select-filled-label1234'
              id='demo-simple-select-filled1234'
              value={statename}
              onChange={onChangesc}
              name='state'
              label='state'
              required
            >
              {Object.keys(data).map(function(keyName ) {
    	        	return (
      		   	<MenuItem key={keyName} value={keyName}>
				      	{keyName}
          		</MenuItem>
          		)
	         	})}
            </Select>
          </FormControl>
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box px={2} mt={2}>
          <FormControl variant='outlined' fullWidth required disabled={isAdmin === 'faculty'}>
            <InputLabel id='demo-simple-select-filled-label12345' required>
              City
            </InputLabel>
            <Select
              labelId='demo-simple-select-filled-label12345'
              id='demo-simple-select-filled12345'
              value={city}
              onChange={onChangeUserCity}
              name='city'
              label='City'
              required
            >
               {cityOptions && cityOptions.length ? cityOptions.map(keyName =>    	        
      		   	<MenuItem key={keyName} value={keyName}>
				      	{keyName}
          		</MenuItem>
          		) : null}
            </Select>
          </FormControl>
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box px={2}>
          <TextField
            disabled={isAdmin === 'faculty'}
            label='Pincode'
            id=''
            variant='outlined'
            margin='normal'
            required
            fullWidth
            value={pincode}
            onChange={(e) => setPincode(e.target.value)}
            name='pincode'
          />
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box px={2}>
          <TextField
            disabled={isAdmin === 'faculty'}
            label='Catchment Radius'
            id=''
            type='text'
            variant='outlined'
            margin='normal'
            fullWidth
            value={catchmentradius}
            onChange={(e) => setCatchmentRadius(e.target.value)}
            name='catchmentRadius'
            required
          />
        </Box>
      </Grid>

      


      <Grid item xs={12} sm={6}>
        <Box px={2} mt={2}>
          <FormControl variant='outlined' fullWidth required disabled={isAdmin === 'faculty'}>
            <InputLabel id='demo-simple-select-filled-label12' required>
              Center Code
            </InputLabel>
            <Select
              labelId='demo-simple-select-filled-label12'
              id='demo-simple-select-filled12'
              value={centercode}
              onChange={changeCenterCode}
              name='centerCode'
              label='Center Code'
            >
              {allcentercode.map((code) => (
                <MenuItem key={code._id} value={code.centerCode}>
                  {code.centerCode}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>
    </React.Fragment>
  );
}

export default AddressDetails;
