import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
	Drawer,
	List,
	ListItem,
	ListItemIcon,
	ListItemText
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DashboardRoundedIcon from "@material-ui/icons/DashboardRounded";
import PersonIcon from "@material-ui/icons/Person";
import FaceIcon from "@material-ui/icons/Face";
import ParentIcon from '@material-ui/icons/Wc';
import TodayIcon from "@material-ui/icons/Today";
import { GlobalContext } from "../context/GlobalState";
import PinDropIcon from "@material-ui/icons/PinDrop";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import PaymentIcon from '@material-ui/icons/Payment';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import DateRangeIcon from '@material-ui/icons/DateRange';
import StorageIcon from '@material-ui/icons/Storage';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import InsertChart from '@material-ui/icons/InsertChart';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';

export default function Drawers(props) {
	let { classes, onClose, open } = props;
	const theme = useTheme();
	const [selectedIndex, setSelectedIndex] = React.useState(0);

	const handleListItemClick = (e, index) => {
	setSelectedIndex(index);
	};

	const { getRedirect, encodeDecodeString } = React.useContext(GlobalContext);
	const isAdmin = encodeDecodeString(window.localStorage.getItem("user"),'decode');
	return (
	<div className={classes.root}>
		<Drawer
		variant="permanent"
		className={clsx(classes.drawer, {
			[classes.drawerOpen]: open,
			[classes.drawerClose]: !open
		})}
		classes={{
			paper: clsx({
			[classes.drawerOpen]: open,
			[classes.drawerClose]: !open
			})
		}}
		>
		<div className={classes.toolbar}>
			<IconButton onClick={onClose}>
			{theme.direction === "rtl" ? (
				<ChevronRightIcon />
			) : (
				<ChevronLeftIcon />
			)}
			</IconButton>
		</div>

		<List>
		{isAdmin === "admin" || isAdmin === "centerhead" || isAdmin === "operations" || isAdmin === "coo" || isAdmin === "academics"?
			<ListItem
			selected={selectedIndex === 0}
			className="button1"
			button
			onClick={e => {
				getRedirect("HOME");
				handleListItemClick(e, 0);
			}}
			>
			<ListItemIcon>
			<DashboardRoundedIcon />
			</ListItemIcon>
			<ListItemText primary="Dashboard" />
			</ListItem> : ""}

			{isAdmin === "admin" || isAdmin === "centerhead" || isAdmin === "operations" || isAdmin === "coo" || isAdmin === "academics" || isAdmin === "faculty" ?
			<ListItem
			selected={selectedIndex === 1}
			className="button1"
			button
			onClick={e => {
				getRedirect("CLASSES");
				handleListItemClick(e, 1);
			}}
			>
			<ListItemIcon>
			<TodayIcon />
			</ListItemIcon>
			<ListItemText primary="Classes" />
			</ListItem>: ""}

			{isAdmin === "admin" || isAdmin === "centerhead" || isAdmin === "operations" || isAdmin === "coo" || isAdmin === "academics" || isAdmin === "marketing" || isAdmin === "faculty" ?
			<ListItem
			selected={selectedIndex === 2}
			className="button1"
			button
			onClick={e => {
				getRedirect("STUDENTS");
				handleListItemClick(e, 2);
			}}
			>
			<ListItemIcon>
				<FaceIcon />
			</ListItemIcon>
			<ListItemText primary="Students" />
			</ListItem>: ""}

			{isAdmin === "admin" || isAdmin === "centerhead" || isAdmin === "operations" || isAdmin === "coo" || isAdmin === "academics" || isAdmin === "marketing" ?
			<ListItem
			selected={selectedIndex === 3}
			className="button1"
			button
			onClick={e => {
				getRedirect("PARENTS");
				handleListItemClick(e, 3);
			}}
			>
			<ListItemIcon>
				<ParentIcon />
			</ListItemIcon>
			<ListItemText primary="Parents" />
			</ListItem>: ""}

			{isAdmin === "admin" || isAdmin === "centerhead" || isAdmin === "operations" || isAdmin === "coo" || isAdmin === "academics" || isAdmin === "marketing" || isAdmin === "faculty" ?
			<ListItem
			selected={selectedIndex === 4}
			className="button1"
			button
			onClick={e => {
				getRedirect("FACULTY");
				handleListItemClick(e, 4);
			}}
			>
			<ListItemIcon>
				<PersonIcon />
			</ListItemIcon>			
			<ListItemText primary="Faculty" />
			</ListItem>: ""}

			{isAdmin === "admin" || isAdmin === "centerhead" || isAdmin === "operations" || isAdmin === "coo" || isAdmin === "academics" || isAdmin === "marketing" ?
			<ListItem
			selected={selectedIndex === 5}
			className="button1"
			button
			onClick={e => {
				getRedirect("ALLCENTERHEAD");
				handleListItemClick(e, 5);
			}}
			>
			<ListItemIcon>
				<PersonPinIcon />
			</ListItemIcon>
			<ListItemText primary="Center Head" />
			</ListItem>: ""}

			{isAdmin === "admin" ?
			<ListItem
			selected={selectedIndex === 6}
			className="button1"
			button
			onClick={e => {
				getRedirect("ALLCENTERCODE");
				handleListItemClick(e, 6);
			}}
			>
			<ListItemIcon>
				<PinDropIcon />
			</ListItemIcon>
			<ListItemText primary="Center Code" />
			</ListItem> : ""}

			{isAdmin === "admin" ?
			<ListItem
			selected={selectedIndex === 8}
			className="button1"
			button
			onClick={e => {
				getRedirect("BANKFILE");
				handleListItemClick(e, 8);
			}}
			>
			<ListItemIcon>
				<AccountBalanceIcon />
			</ListItemIcon>
			<ListItemText primary="Bank File" />
			</ListItem>: ""}

			{isAdmin === "admin" || isAdmin === "centerhead" || isAdmin === "accounts" || isAdmin === "faculty" ?
			<ListItem
			selected={selectedIndex === 9}
			className="button1"
			button
			onClick={e => {
				getRedirect("PAYMENTHISTORY");
				handleListItemClick(e, 9);
			}}
			>
			<ListItemIcon>
				<LocalAtmIcon />
			</ListItemIcon>
			<ListItemText primary="Payment History" />
			</ListItem> : ""}

			{isAdmin === "admin" || isAdmin === "accounts" ?
			<ListItem
			selected={selectedIndex === 10}
			className="button1"
			button
			onClick={e => {
				getRedirect("CENTERPAYMENTHISTORY");
				handleListItemClick(e, 10);
			}}
			>
			<ListItemIcon>
				<PaymentIcon />
			</ListItemIcon>
			<ListItemText primary="Payment Recon" />
			</ListItem> : ""}

			{isAdmin === "admin" ?
			<ListItem
			selected={selectedIndex === 11}
			className="button1"
			button
			onClick={e => {
				getRedirect("BULKIMPORT");
				handleListItemClick(e, 11);
			}}
			>
			<ListItemIcon>
				<ImportExportIcon />
			</ListItemIcon>
			<ListItemText primary="Bulk Import" />
			</ListItem> : ""}

			{isAdmin === "admin" || isAdmin === "operations" || isAdmin === 'coo' || isAdmin === 'academics' || isAdmin === 'centerhead' ?
			<ListItem
			selected={selectedIndex === 12}
			className="button1"
			button
			onClick={e => {
				getRedirect("DAYSUMMARY");
				handleListItemClick(e, 12);
			}}
			>
			<ListItemIcon>
				<DateRangeIcon />
			</ListItemIcon>
			<ListItemText primary="Day Summary" />
			</ListItem> : ""}

			{isAdmin === "admin" || isAdmin === "marketing" ?
			<ListItem
			selected={selectedIndex === 13}
			className="button1"
			button
			onClick={e => {
				getRedirect("ADDRESSDUMP");
				handleListItemClick(e, 13);
			}}
			>
			<ListItemIcon>
				<HomeWorkIcon />
			</ListItemIcon>
			<ListItemText primary="Address Dump" />
			</ListItem> : ""}
			
			{isAdmin === "admin" || isAdmin === "stores" || isAdmin === "marketing" ?
			<ListItem
			selected={selectedIndex === 14}
			className="button1"
			button
			onClick={e => {
				getRedirect("ALLINVENTORY");
				handleListItemClick(e, 14);
			}}
			>
			<ListItemIcon>
				<StorageIcon />
			</ListItemIcon>
			<ListItemText primary="Inventory" />
			</ListItem> : ""}

			{isAdmin === "admin" || isAdmin === "stores" || isAdmin === "marketing" ?
			<ListItem
			selected={selectedIndex === 15}
			className="button1"
			button
			onClick={e => {
				getRedirect("ALLORDERS");
				handleListItemClick(e, 15);
			}}
			>
			<ListItemIcon>
				<AddShoppingCartIcon />
			</ListItemIcon>
			<ListItemText primary="Orders" />
			</ListItem> : ""}

			{isAdmin === "admin" || isAdmin === "accounts" ?
			<ListItem
			selected={selectedIndex === 16}
			className="button1"
			button
			onClick={e => {
				getRedirect("FINANCEHISTORY");
				handleListItemClick(e, 16);
			}}
			>
			<ListItemIcon>
				<AccountTreeIcon />
			</ListItemIcon>
			<ListItemText primary="Finance" />
			</ListItem> : ""}

			{isAdmin === "admin" ?
			<ListItem
			selected={selectedIndex === 17}
			className="button1"
			button
			onClick={e => {
				getRedirect("ALLMODULE");
				handleListItemClick(e, 17);
			}}
			>
			<ListItemIcon>
				<ViewModuleIcon />
			</ListItemIcon>
			<ListItemText primary="Modules" />
			</ListItem> : ""}

			{isAdmin === "admin" ?
			<ListItem
			selected={selectedIndex === 18}
			className="button1"
			button
			onClick={e => {
				getRedirect("GRAPH");
				handleListItemClick(e, 18);
			}}
			>
			<ListItemIcon>
				<InsertChart />
			</ListItemIcon>
			<ListItemText primary="Graphical Data" />
			</ListItem> : ""}

			{isAdmin === "admin" || isAdmin === "crm" ?
			<ListItem
			selected={selectedIndex === 19}
			className="button1"
			button
			onClick={e => {
				getRedirect("ENQUIRY");
				handleListItemClick(e, 19);
			}}
			>
			<ListItemIcon>
				<InsertChart />
			</ListItemIcon>
			<ListItemText primary="Enquiry Data" />
			</ListItem> : ""}
		</List>
		</Drawer>
	</div>
	);
}
