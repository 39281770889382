import React from "react";
import { GlobalContext } from "../../context/GlobalState";
import {
  Grid,
  Typography,
  Box,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from "@material-ui/core";
import {getStateCityDetail as cityData} from "../../common/api";
const lookup = {
  karnataka: [{ id: "1", text: "Bangalore" }],
  Rajasthan: [{ id: "2", text: "Jaipur" }],
  MadhyaPradesh: [{ id: "3", text: "Indore" }],
  Gujarat: [{ id: "4", text: "Surat" }],
  Maharashtra: [{ id: "5", text: "Pune" }, { id: "6", text: "Navi Mumbai" }],
  Telangana: [{ id: "7", text: "Hyderabad" }]
};

function CHAddressDetails(props) {
  const {
    dataValue,
    setDataValue,
    city,
    setCity,
    houseno,
    setHouseno,
    area,
    setArea,
    pincode,
    setPincode,
    landmark,
    setLandmark,
    centercode,
    setCentercode,
    statename,
    setStateName
  } = props;
  const options = lookup[dataValue];

  const { allcentercode, getCenterCodeDetails } = React.useContext(
    GlobalContext
  );
  const { allstatecity, getStateCityDetails } = React.useContext(GlobalContext);
  const [cityOptions, setCityOptions] = React.useState([]);
  const data = JSON.parse(JSON.stringify(allstatecity));

  React.useEffect(() => {
    getStateCityDetails();
    cityData().then(items => {
      let dataCity = JSON.parse(JSON.stringify(items));
      setCityOptions(dataCity[props.city]);
    })
  }, []);


  React.useEffect(() => {
    getCenterCodeDetails();
  }, []);

  const onChangesc = e => {
    const value = e.target.value ? e.target.value : "";
    setStateName(value);
    setCityOptions(data[value]);
  };

  const onChangeUserCity = e => {
    setCity(e.target.value);
  };

  const changeCenterCode = e => {
    setCentercode(e.target.value);
  };
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Box pt={2} px={2}>
          <Typography variant="h6">Add Address</Typography>
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box px={2}>
          <TextField
            label="House Number / #Flat No"
            type="text"
            variant="outlined"
            margin="normal"
            fullWidth
            value={houseno}
            onChange={e => setHouseno(e.target.value)}
            name="house_no"
          />
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box px={2}>
          <TextField
            label="Area"
            type="text"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={area}
            onChange={e => setArea(e.target.value)}
            name="area"
          />
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box px={2}>
          <TextField
            label="Landmark"
            type="text"
            variant="outlined"
            margin="normal"
            fullWidth
            value={landmark}
            onChange={e => setLandmark(e.target.value)}
            name="landmark"
          />
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box px={2} mt={2}>
          <FormControl variant="outlined" fullWidth required>
            <InputLabel id="demo-simple-select-filled-label1234" required>
              State
            </InputLabel>
            <Select
              labelId="demo-simple-select-filled-label1234"
              id="demo-simple-select-filled1234"
              value={statename}
              onChange={onChangesc}
              name="state"
              label="state"
              required
            >
              {Object.keys(data).map(function(keyName) {
                return (
                  <MenuItem key={keyName} value={keyName}>
                    {keyName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box px={2} mt={2}>
          <FormControl variant="outlined" fullWidth required>
            <InputLabel id="demo-simple-select-filled-label12345" required>
              City
            </InputLabel>
            <Select
              labelId="demo-simple-select-filled-label12345"
              id="demo-simple-select-filled12345"
              value={city}
              onChange={onChangeUserCity}
              name="city"
              label="City"
              required
            >
              {cityOptions && cityOptions.length
                ? cityOptions.map(keyName => (
                    <MenuItem key={keyName} value={keyName}>
                      {keyName}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box px={2}>
          <TextField
            label="Pincode"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={pincode}
            onChange={e => setPincode(e.target.value)}
            name="pincode"
          />
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box px={2} mt={2}>
          <FormControl variant="outlined" fullWidth required>
            <InputLabel id="demo-simple-select-filled-label12" required>
              Center Code
            </InputLabel>
            <Select
              labelId="demo-simple-select-filled-label12"
              id="demo-simple-select-filled12"
              value={centercode}
              onChange={changeCenterCode}
              name="centerCode"
              label="Center Code"
            >
              {allcentercode.map(code => (
                <MenuItem key={code._id} value={code.centerCode}>
                  {code.centerCode}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>
    </React.Fragment>
  );
}

export default CHAddressDetails;
