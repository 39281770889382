import React from "react";
import { GlobalContext } from "../context/GlobalState";
import { Bar, Line, Pie, Doughnut } from "react-chartjs-2";

function Chart2({stat}) {
  let active = stat.student && stat.student.active;
  let onhold = stat.student && stat.student.onhold;
  let inactive = stat.student && stat.student.inactive;

  const dat = {
    labels: ['Active','On Hold', 'Inactive'],
    datasets: [
      {
        label: "",
        data: [active, onhold, inactive],
        backgroundColor: ["rgba(255,178,43,0.8)", "rgba(200,200,200,0.8)", "rgba(50,50,50,0.8)"]
      }
    ]
  };
  
  return (
    <div>
      <Pie data={dat} options={{ maintainAspectRatio: true }} />
    </div>
  );
}

export default Chart2;
