import React from "react";
import { Paper, Grid, Typography, Box, Button} from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import AllParents from "./AllParents";
import FormRender from "../../common/FormHelper/FormRender";
import checkValidity from "../../common/FormHelper/FieldValidator";
import { isValidatedForm, DEFAULT_JSON } from "../../common/FormHelper/FormValidSetter";
import { GlobalContext } from "../../context/GlobalState";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

//JSON
import BasicJson from './Schema/BasicDetails.json';
import AddressDetailsJson from './Schema/AddressDetails.json';

class AddParent extends React.Component {
  static contextType = GlobalContext;
  constructor(props){
    super(props);
    this.state = {
      basicJson : DEFAULT_JSON(BasicJson),
      addressJson : DEFAULT_JSON(AddressDetailsJson),
      isBack: false,
      dialogMsg: "",
      openDialog: false,
      allStateCity: [],
      cityOptions: [],
      allCenterCode: []
    };
  }
  componentDidMount(){
    this.getStateCity();
    this.getCenterCode();
  }

  updateCenterCode = () => {
    const { allcentercode } = this.context;
    const { addressJson } = this.state;
    // const data = JSON.parse(JSON.stringify(allcentercode));
    const copyAddress = [...addressJson];
    const data = allcentercode.map(function(element){
      return element.centerCode;
    });
    copyAddress[6].options = data;
    this.setState({addressJson : copyAddress});
  }
 
  updateState = () => {
    const { allstatecity } = this.context;
    const { addressJson } = this.state;
    const data = JSON.parse(JSON.stringify(allstatecity));
    const stateData = Object.keys(data);
    const copyAddress = [...addressJson];
    copyAddress[3].options = stateData;
    this.setState({addressJson : copyAddress});
  }
  updateCity = (v) => {
    const value = v ? v : '';
    const { allstatecity } = this.context;
    const { addressJson } = this.state;
    const data = JSON.parse(JSON.stringify(allstatecity));
    const cityData = data[value];
    const copyAddress = [...addressJson];
    copyAddress[4].options = cityData;
    copyAddress[4].value = "";
    this.setState({addressJson : copyAddress});
  }
  getStateCity = async () => {
    const context = this.context;
    const { getStateCityDetails } = context;
    await getStateCityDetails();
    setTimeout(() => {
      this.updateState();
    }, 2000);
  }

  getCenterCode = async () => {
    const context = this.context;
    const { getCenterCodeDetails } = context;
    await getCenterCodeDetails();
    setTimeout(() => {
      this.updateCenterCode();
    }, 2000);
  }
  InputBasicChanger = (event, elemIndex) => {

      const copyJSON = [...this.state.basicJson];
      const updatedElement = copyJSON[elemIndex];
      if (updatedElement.type === "file") {
        updatedElement.value = event.target.value;
        updatedElement.file = event.target.files[0];
      }
      else if(updatedElement.type === "phone"){
        updatedElement.value = (event.target.value).replace(/-/g, "").replace(/ /g, "");
      }
      else {
        updatedElement.value = event.target.value;
      }
      const { isValid, errorText } = checkValidity(
        updatedElement.value,
        updatedElement.validation
        );
      updatedElement.valid = isValid;
      updatedElement.errorText = errorText;
      updatedElement.touched = 1;
      this.setState({ basicJson: copyJSON }, () => {});
  }
  InputAddressChanger = (event, elemIndex) => {
    const copyJSON = [...this.state.addressJson];
    const updatedElement = copyJSON[elemIndex];
    if (updatedElement.type === "file") {
      updatedElement.value = event.target.value;
      updatedElement.file = event.target.files[0];
    } else {
      updatedElement.value = event.target.value;
    }
    if(updatedElement?.name === "state"){
      this.updateCity(event.target.value);
    }
    const { isValid, errorText } = checkValidity(
      updatedElement.value,
      updatedElement.validation
      );
    updatedElement.valid = isValid;
    updatedElement.errorText = errorText;
    updatedElement.touched = 1;
    this.setState({ addressJson: copyJSON }, () => {});
}


  handleSave = () => {
    const { basicJson, addressJson } = this.state;
    const { addParentDetails } = this.context;
    const basic  = isValidatedForm(basicJson);
    const address = isValidatedForm(addressJson);
    
    this.setState({
      basicJson: basic?.validatedForm,
      addressJson: address?.validatedForm
    });
    if(basic?.formValidity && address?.formValidity){
      const postObj = {};
      postObj.name = basicJson[0]?.value;
      postObj.email = basicJson[3]?.value.toLowerCase().trim();
      postObj.mobileNo = (basicJson[1]?.value).replace(/-/g, "").replace(/ /g, "");
      postObj.secondaryMobile = (basicJson[2]?.value).replace(/-/g, "").replace(/ /g, "");
      postObj.residentalAddress = {};
      postObj.residentalAddress.house_no = addressJson[0]?.value;
      postObj.residentalAddress.area = addressJson[1]?.value;
      postObj.residentalAddress.landmark = addressJson[2]?.value;
      postObj.residentalAddress.state = addressJson[3]?.value;
      postObj.residentalAddress.city = addressJson[4]?.value;
      postObj.residentalAddress.pincode = parseInt(addressJson[5]?.value);
      postObj.centerCode = addressJson[6]?.value;
      addParentDetails(postObj);
      setTimeout(() => {
        const {err, addParent} = this.context || {};
        if(err){
          this.handleDialogOpen(err);
        }
        else if(addParent){
          this.handleDialogOpen(addParent);
        }
      }, 1000);
    }
  }
  backToParent = () => {
    this.setState({ isBack : true});
  }
  handleDialogOpen = (msg = "") => {
    this.setState({dialogMsg : msg, openDialog : true});
  }
  handleCloseBtn = () => {
    const { addParent }  = this.context;
    if(addParent){
      this.setState({openDialog : false, isBack: true});
    }
    else{
      this.setState({openDialog : false});
    }
  }
  handleClose = () => {
    this.setState({openDialog : false});
  }
  render() {
    const { basicJson, addressJson, openDialog, dialogMsg, isBack } = this.state;

    if (isBack) {
      return <AllParents />;
    }
    return (
      <React.Fragment>
        <Box pb={2}>
          <Button
            onClick={this.backToParent}
            type="submit"
            size="large"
            pt={2}
            variant="contained"
            color="primary"
          >
            <KeyboardBackspaceIcon />
          </Button>
        </Box>
        <Box boxShadow={3} mb={1}>
          <Grid container component={Paper}>
            <Grid item xs={12}>
              <Box p={2}>
                <Typography variant="h5">Add Parent</Typography>
              </Box>
            </Grid>
            <FormRender form={basicJson} onChange={this.InputBasicChanger} />
            <Grid item xs={12}>
              <Box p={2}>
                <Typography variant="h5">Add Address</Typography>
              </Box>
            </Grid>
            <FormRender
              form={addressJson}
              onChange={this.InputAddressChanger}
            />

            <Grid item xs={12}>
              <Box p={2}>
                <Button
                  size="large"
                  pt={2}
                  onClick={this.handleSave}
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Dialog
          open={openDialog}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle align="center" id="responsive-dialog-title">
            {"Message"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button onClick={this.handleCloseBtn} autoFocus color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }

}

export default AddParent;
