import React from "react";
import { Paper, Grid, Typography, Box, Button} from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
// import SingleParentData from "./SingleParentData";
import FormRender from "../../common/FormHelper/FormRender";
import checkValidity from "../../common/FormHelper/FieldValidator";
import { isValidatedForm } from "../../common/FormHelper/FormValidSetter";
import { GlobalContext } from "../../context/GlobalState";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

//JSON
import BasicJson from './Schema/BasicDetails.json';
import AddressDetailsJson from './Schema/AddressDetails.json';
import AllParents from "./AllParents";
import moment from "moment";
class UpdateParent extends React.Component {
  static contextType = GlobalContext;
  constructor(props){
    super(props);
    this.state = {
      basicJson : BasicJson,
      addressJson : AddressDetailsJson,
      isBack: false,
      dialogMsg: "",
      Parent: props.showData,
      openDialog: false,
      allStateCity: [],
      cityOptions: [],
      disabled : false
    };
  }
  componentDidMount(){
    this.getCenterCode();
    this.getStateCity();
    this.fillDetails();
  }
  fillDetails = () => {
    const { showData } = this.props;
    const { basicJson, addressJson } = this.state;
    const copyBasic = [...basicJson];
    if(showData){
      copyBasic.forEach( item => {
        if(item?.type === "phone"){
          item.value = showData[item?.name] ? (showData[item?.name]).replace(/-/g, "").replace(/ /g, "") : "";
        }
        else{
          item.value = showData[item?.name] ? showData[item?.name] : "";
        }
      });
    }
    const { allcentercode } = this.context;
    const centercodedata = allcentercode.map(function(element){
      return element.centerCode;
    });
    const copyAddress = [...addressJson];
    if(showData?.residentalAddress){
      if(showData?.residentalAddress?.state){
        const { allstatecity } = this.context;
        const data = JSON.parse(JSON.stringify(allstatecity));
        const cityData = data[showData?.residentalAddress?.state];
        copyAddress[4].options = cityData;
      }
      copyAddress.forEach( item => {
        if (item.name === "location.coordinates") {
          if (showData?.residentalAddress?.location?.coordinates) {
            item.value = (showData.residentalAddress.location.coordinates).reverse();
          }
        } else {
          item.value = showData?.residentalAddress[item?.name] ? showData?.residentalAddress[item?.name].toString() : showData?.centerCode;
        }
      });
    }
    this.setState({ basicJson : copyBasic, addressJson: copyAddress});
  }

  updateCenterCode = () => {
    const { allcentercode } = this.context;
    const { addressJson } = this.state;
    const copyAddress = [...addressJson];
    const data = allcentercode.map(function(element){
      return element.centerCode;
    });
    copyAddress[6].options = data;
    this.setState({addressJson : copyAddress});
  }

  updateState = () => {
    const { allstatecity } = this.context;
    const { addressJson } = this.state;
    const data = JSON.parse(JSON.stringify(allstatecity));
    const stateData = Object.keys(data);
    const copyAddress = [...addressJson];
    copyAddress[3].options = stateData;
    if(copyAddress[3]?.value){
      const cityData = data[copyAddress[3]?.value];
      copyAddress[4].options = cityData;
    }
    this.setState({addressJson : copyAddress});
  }
  updateCity = (v) => {
    const value = v ? v : '';
    const { allstatecity } = this.context;
    const { addressJson } = this.state;
    const data = JSON.parse(JSON.stringify(allstatecity));
    const cityData = data[value];
    const copyAddress = [...addressJson];
    copyAddress[4].options = cityData;
    copyAddress[4].value = "";
    this.setState({addressJson : copyAddress});
  }
  getStateCity = async () => {
    const context = this.context;
    const { getStateCityDetails } = context;
    await getStateCityDetails();
    this.updateState();
  }
  getCenterCode = async () => {
    const context = this.context;
    const { getCenterCodeDetails } = context;
    await getCenterCodeDetails();
    this.updateCenterCode();
  }
  InputBasicChanger = (event, elemIndex) => {

      const copyJSON = [...this.state.basicJson];
      const updatedElement = copyJSON[elemIndex];
      if (updatedElement.type === "file") {
        updatedElement.value = event.target.value;
        updatedElement.file = event.target.files[0];
      } else {
        updatedElement.value = event.target.value;
      }
      const { isValid, errorText } = checkValidity(
        updatedElement.value,
        updatedElement.validation
        );
      updatedElement.valid = isValid;
      updatedElement.errorText = errorText;
      updatedElement.touched = 1;
      this.setState({ basicJson: copyJSON }, () => {});
  }
  InputAddressChanger = (event, elemIndex) => {
    const copyJSON = [...this.state.addressJson];
    const updatedElement = copyJSON[elemIndex];
    if (updatedElement.type === "file") {
      updatedElement.value = event.target.value;
      updatedElement.file = event.target.files[0];
    } else {
      updatedElement.value = event.target.value;
    }
    if(updatedElement?.name === "state"){
      this.updateCity(event.target.value);
    }
    const { isValid, errorText } = checkValidity(
      updatedElement.value,
      updatedElement.validation
      );
    updatedElement.valid = isValid;
    updatedElement.errorText = errorText;
    updatedElement.touched = 1;
    this.setState({ addressJson: copyJSON }, () => {});
}

  handleSave = () => {
    this.setState({disabled: true});
    const { basicJson, addressJson, paymentJson } = this.state;
    const { showData } = this.props;
    const { updateParentDetails } = this.context;
    const basic  = isValidatedForm(basicJson);
    const address = isValidatedForm(addressJson);
    this.setState({
      basicJson: basic?.validatedForm,
      addressJson: address?.validatedForm
    });
    if(basic?.formValidity && address?.formValidity){
      const postObj = {};
      postObj.parent_id = showData?._id;
      postObj.name = basicJson[0]?.value;
      postObj.email = basicJson[3]?.value.toLowerCase().trim();
      postObj.mobileNo = (basicJson[1]?.value).replace(/-/g, "").replace(/ /g, "");
      postObj.secondaryMobile = (basicJson[2]?.value).replace(/-/g, "").replace(/ /g, "");
      postObj.residentalAddress = {};
      postObj.residentalAddress.house_no = addressJson[0]?.value;
      postObj.residentalAddress.area = addressJson[1]?.value;
      postObj.residentalAddress.landmark = addressJson[2]?.value;
      postObj.residentalAddress.state = addressJson[3]?.value;
      postObj.residentalAddress.city = addressJson[4]?.value;
      postObj.residentalAddress.pincode = parseInt(addressJson[5]?.value);
      postObj.centerCode = addressJson[6]?.value
      
      if (addressJson[7]?.value !== ""){
        postObj.residentalAddress.location = {};
        let coordinates = addressJson[7]?.value
        if (!Array.isArray(coordinates)){
          coordinates = addressJson[7]?.value?.split(",")
          if (coordinates.length !== 2) {
            this.handleDialogOpen(`Invalid coordinates for address.`);
            return;
          }
        }
        postObj.residentalAddress.location.coordinates = [parseFloat(coordinates[1]),parseFloat(coordinates[0])]
      } else {
        postObj.residentalAddress.location = {};
        postObj.residentalAddress.location.coordinates = [0,0]
      }
      
      
      updateParentDetails(postObj);
      setTimeout(() => {
        const {err, updateParent} = this.context || {};
        if(err){
          this.handleDialogOpen(err);
          this.setState({disabled: false});
        }
        else if(updateParent){
          this.setState({Parent : updateParent});
          this.handleDialogOpen(`Parent updated successfully.`);
        }
      }, 1000);
    }
  }
  backToParent = () => {
    this.setState({ isBack : true});
  }
  handleDialogOpen = (msg = "") => {
    this.setState({dialogMsg : msg, openDialog : true});
  }
  handleCloseBtn = () => {
    const { updateParent }  = this.context;
    if(updateParent){
      this.setState({openDialog : false, isBack: true});
    }
    else{
      this.setState({openDialog : false});
    }
  }
  handleClose = () => {
    this.setState({openDialog : false});
  }
  render() {
    const { basicJson, addressJson, Parent, openDialog, dialogMsg, isBack, paymentJson } = this.state;

    if (isBack) {
      // return  this.props.showData?.fromListing ? <AllParents /> : <SingleParentData showPayments={Parent} />;
      return <AllParents /> ;
    }
    return (
      <React.Fragment>
        <Box pb={2}>
          <Button
            onClick={this.backToParent}
            type="submit"
            size="large"
            pt={2}
            variant="contained"
            color="primary"
          >
            <KeyboardBackspaceIcon />
          </Button>
        </Box>
        <Box boxShadow={3} mb={1}>
          <Grid container component={Paper}>
            <Grid item xs={12}>
              <Box p={2}>
                <Typography variant="h5">Update Parent</Typography>
              </Box>
            </Grid>
            <FormRender form={basicJson} onChange={this.InputBasicChanger} />
            <Grid item xs={12}>
              <Box p={2}>
                <Typography variant="h5">Address</Typography>
              </Box>
            </Grid>
            <FormRender
              form={addressJson}
              onChange={this.InputAddressChanger}
            />
            <Grid item>
              <Box p={2}>
                <Button
                  disabled={this.state.disabled}
                  size="large"
                  pt={2}
                  onClick={this.handleSave}
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </Box>
            </Grid>
            <Grid item>
              <Box p={2}>
                <Button
                  onClick={this.backToParent}
                  type="submit"
                  size="large"
                  pt={2}
                  variant="contained"
                  color="primary"
                >
                  Back
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Dialog
          open={openDialog}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle align="center" id="responsive-dialog-title">
            {"Message"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button onClick={this.handleCloseBtn} autoFocus color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }

}

export default UpdateParent;
