import React from 'react'
import { GlobalContext } from "../../context/GlobalState";
import { CSVLink, CSVDownload } from "react-csv";

import {
    Paper, Grid, Typography, Box, Button,MenuItem, FormControl, Select, InputLabel, CircularProgress,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
  } from "@material-ui/pickers";
  import DateFnsUtils from "@date-io/date-fns";
  import moment from 'moment'

export default function BankFile() {
  const [back, setBack] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [user, setUser] = React.useState("");
  const [dbutton,setDbutton] = React.useState(true); // disable download button
  const [loading,setLoading] = React.useState(false);
  const [paymentDate, setPaymentDate] = React.useState(new Date());
  const [startdate, setStartdate] = React.useState(new Date());
  const [enddate, setEnddate] = React.useState(new Date());
  const { BankFileGenerate, err, bankFile} = React.useContext(GlobalContext );

  const handleClose = () => {
    setOpen(false);
    setBack(true);
  };

  const handleStartDateChange = date => {
    setStartdate(date);
    setEnddate(date);
  };

  const handleEndDateChange = date => {
    setEnddate(date);
  };

  const handlePaymentDate = date => {
    setPaymentDate(date);
  };

  const onSubmit = async (e) => {
    setDbutton(true)
    setLoading(true)
    e.preventDefault();
    const payment = {
        user:user,
        paymentDate: moment(paymentDate).format("YYYY-MM-DD"),
        fromDate: moment(startdate).format("YYYY-MM-DD"),
        toDate: moment(enddate).format("YYYY-MM-DD")
    };
    await BankFileGenerate(payment);
    setDbutton(false)
    setLoading(false)
  };

  const csvData = bankFile
    
  return (
    <React.Fragment>
      <Box boxShadow={3} mb={1}>
      <form onSubmit={onSubmit}>
        <Grid container component={Paper}>
          <Grid item xs={12} style={{ background: "antiquewhite",marginBottom:"16px"}}>
            <Box p={2}>
              <Typography variant='h6'>Bank File</Typography>
            </Box>
          </Grid>
          
          
          <Grid item xs={12} md={3}>
            <Box px={1} mt={2} pl={2}>
              <FormControl variant='outlined' fullWidth required>
                <InputLabel id='user'>
                  User
                </InputLabel>
                <Select
                  labelId='user'
                  value={user}
                  onChange={(e) => setUser(e.target.value)}
                  name='user'
                  label='User'>
                  <MenuItem value={"centerhead"}>Center Head </MenuItem>
                  <MenuItem value={"faculty"}>Faculty</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>

          
          <Grid item xs={12} md={3}>
            <Box px={2} bgcolor="white" display="flex" justifyContent="center">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              disableFuture
              autoOk
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              label="Transaction Start Date"
              value={startdate}
              name="startdate"
              onChange={handleStartDateChange}
            />
            </MuiPickersUtilsProvider>
            </Box>
          </Grid>

          <Grid item xs={12} md={3}>
            <Box px={2} bgcolor="white" display="flex" justifyContent="center">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              disableFuture
              autoOk
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              label="Transaction End Date"
              value={enddate}
              name="enddate"
              onChange={handleEndDateChange}
            />
            </MuiPickersUtilsProvider>
            </Box>
          </Grid>

          <Grid item xs={12} md={3}>
            <Box px={2} bgcolor="white" display="flex" justifyContent="center">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              autoOk
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              label="Payment Date"
              value={paymentDate}
              name="paymentDate"
              onChange={handlePaymentDate}
            />
            </MuiPickersUtilsProvider>
            </Box>
          </Grid>

          <Grid item xs={12}>
          <Box p={2}>
          <Button style={{float:"right"}}
            type='submit'
            size='large'
            pt={2}
            variant='contained'
            color='primary'
          >
            { loading ?<> Loading   <CircularProgress style={{color:"#fff",marginLeft:"15px"}} size={20}/></>  : "Generate Bank File"}
          </Button>
          <Button disabled={dbutton} id="download" size='large'
            variant='contained'
            color='primary' >
        <CSVLink data={csvData} filename={`${user}-bank-statement-${moment().format("DD-MM-YYYY")}.csv`} style={{textDecoration:"none",color:"#fff"}}>Download</CSVLink>
        </Button>
          </Box>
          </Grid>
        </Grid>
      </form>

      {/* <Grid container component={Paper}>
      <Grid item xs={12}>
        <Box p={2}>
        <Button disabled={dbutton} id="download" size='large'
            variant='contained'
            color='secondary' >
        <CSVLink data={csvData} filename={`${user}-bank-statement-${moment().format("DD-MM-YYYY")}.csv`} style={{textDecoration:"none",color:"#fff"}}>Download</CSVLink>
        </Button>
        
        </Box>
      </Grid>
      </Grid> */}
    </Box>
          

    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='responsive-dialog-title'>
      <DialogTitle
        align='center'
        style={{ background: "antiquewhite" }}
        id='responsive-dialog-title'>
        {err ? err : "Payment report is generated" }
      </DialogTitle>
      <DialogContent>
        <DialogContentText></DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button onClick={handleClose} variant='contained' color='primary'>
          Back
        </Button>
      </DialogActions>
    </Dialog>
  </React.Fragment>
)}


