export default (state, action) =>{

    switch(action.type){
      case 'GET_USER_DETAILS': return {...state, userDetails:action.payload};
      case 'LOGOUT': return {...state, userDetails:action.payload};
      case 'EMAIL_VERIFICATION': return {...state, emailCheck:action.payload};
      case 'CODE_VERIFICATION': return {...state, codeCheck:action.payload};
      case 'PASSWORD_VERIFICATION': return {...state, passwordCheck:action.payload};
      case 'GET_STAT_DETAILS': return {...state, faculty:action.payload};
      case 'GENERATE_FINANCE_FILE': return {...state, financeFile:action.payload};
      case 'GENERATE_BANK_FILE': return {...state, bankFile:action.payload};
      case 'GENERATE_PAYMENT_STATEMENTS': return {...state, paymentStatement:action.payload};
      case 'UPLOAD_PAYMENTS' : return {...state, uploadPayments: action.payload};
      case 'GENERATE_PARENT_STATEMENTS': return {...state, parentStatement:action.payload};
      case 'GENERATE_ENQUIRY_DATA': return {...state, enquiryStatement:action.payload};
      case 'GET_PARENT_DETAILS': return {...state, allparents:action.payload};
      case 'GET_CLASS_DETAILS': return {...state, allclasses:action.payload};
      case 'GET_INVENTORY_DATA_BY_PROGRAM': return {...state, inventoryDataByProgram:action.payload};
      case 'OLD_ORDER_DATA': return {...state, oldOrderData:action.payload};
      case 'GET_STUDENT_DETAILS': return {...state, allstudents:action.payload};
      case 'GET_CENTERHEAD_DETAILS': return {...state, allcenterhead:action.payload};
      case 'GET_FACULTY_DETAILS': return {...state, allfaculty:action.payload};
      case 'DAY_SUMMARY_DETAILS': return {...state, summaryData:action.payload};
      case 'DAY_SUMMARY_DETAILS_FACULTY': return {...state, summaryDataFaculty:action.payload};
      case 'GET_FACULTY_DETAILS_FOR_ADD_CLASS': return {...state, allFacultyForAddClass:action.payload};
      case 'ADD_UPDATE_CLASS_DETAILS': return {...state, addUpdateClass:action.payload};
      case 'GET_FACULTY_CLASS_DETAILS': return {...state, allfctyClass:action.payload};
      case 'GET_SINGLE_FACULTY_CLASS_DETAILS': return {...state, singlefctyClass:action.payload};
      case 'GET_SINGLE_CENTERHEAD_DETAILS': return {...state, singlectHead:action.payload};
      case 'GET_SINGLE_PARENT_CLASS_DETAILS': return {...state, singleparentClass:action.payload};
      case 'GET_SINGLE_CENTERHEAD_PAYMENT_DETAILS': return {...state, singlecenterheadPayment:action.payload};
      case 'GET_SINGLE_PARENT_PAYMENT_DETAILS': return {...state, singleparentPayment:action.payload};
      case 'GET_SINGLE_FACULTY_PAYMENT_DETAILS': return {...state, singlefacultyPayment:action.payload};
      case 'GET_CENTER_CODE_DETAILS': return {...state, allcentercode:action.payload};
      case 'ADD_FACULTY_DETAILS': return {...state, addfaculty:action.payload};
      case 'ADD_PARENT_DETAILS': return {...state, addParent:action.payload};
      case 'ADD_INVENTORY_DETAILS': return {...state, addInventory:action.payload};
      case 'ADD_MODULE_DETAILS': return {...state, addModule:action.payload};
      case 'ADD_STUDENT_DETAILS': return {...state, addStudent:action.payload};
      case 'ADD_CENTERHEAD_DETAILS': return {...state, addhead:action.payload};
      case 'UPDATE_FACULTY_DETAILS': return {...state, updatefaculty:action.payload};
      case 'UPDATE_PARENT_DETAILS': return {...state, updateParent:action.payload};
      case 'UPDATE_INVENTORY_DETAILS': return {...state, updateInventory:action.payload};
      case 'UPDATE_MODULE_DETAILS': return {...state, updateModule:action.payload};
      case 'UPDATE_PARENT_PAYMENT_DETAILS': return {...state, updateParentPayment:action.payload};
      case 'GENERATE_PARENT_PAYMENT_LINK': return {...state, generateParentPayment:action.payload};
      case 'UPDATE_STUDENT_DETAILS': return {...state, updateStudent:action.payload};
      case 'DELETE_STUDENT_DETAILS': return {...state, deleteStudent:action.payload};
      case 'UPDATE_SPECIAL_RATES_DETAILS': return {...state, sprates:action.payload};
      case 'UPDATE_CENTERHEAD_DETAILS': return {...state, updatecenterhead:action.payload};
      case 'ADD_CENTER_CODE_DETAILS': return {...state, addcentercode:action.payload};
      case 'ADD_BULK_CENTER_CODE_DETAILS': return {...state, addBulkcentercode:action.payload};
      case 'GET_STATE_CITY_DETAILS': return {...state, allstatecity:action.payload};
      case 'GET_ADDRESS_DETAILS': return {...state, addressData:action.payload};
      case 'GET_REDIRECT_DETAILS': return {...state, fragment:action.payload};
      case 'GET_ENCODE_DECODE_STR': return {...state, encodeDecodeStr:action.payload};
      case 'GET_ERROR_MSG': return {...state, err:action.payload };
      case 'GET_INVENTORY_DETAILS': return {...state, allInventory:action.payload };
      case 'GET_MODULE_DETAILS': return {...state, allModules:action.payload };
      case 'GET_ORDER_DETAILS': return {...state, allOrder:action.payload };
      case 'UPDATE_ORDER_DETAILS': return {...state, updatedOrderDetails:action.payload };
      case 'ADD_ORDER': return {...state, addOrder:action.payload };
      case 'GET_STUDENT_ORDER_DETAILS': return {...state, studentOrderDetails: action.payload};
      case 'FEEDBACK_FORM': return {...state, studentFeedbackForm: action.payload};
      case 'UPDATE_STUDENT_MODULE': return {...state, updateStudentModule: action.payload};
      case 'REMOVE_STUDENT_MODULE': return {...state, removeStudentModule: action.payload};
      case 'GRAPH_DATA_CATEGORIES': return {...state, graphDataCategories: action.payload}
      case 'GRAPH_DATA_SERIES': return {...state, graphDataSeries: action.payload}
      default : return {...state};
    }
}