import React from "react";
import {
  Paper,
  Grid,
  Typography,
  Box,
  TextField,
  Button
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import ParentPersonalDetails from "./ParentPersonalDetails";
import { GlobalContext } from "../../context/GlobalState";
import moment from "moment";

function SetSpecialRates({ showPayments, ids, name }) {
  let filterShowPayments = showPayments.student.filter(stu => stu._id === ids)
  const [open, setOpen] = React.useState(false);
  const [back, setBack] = React.useState(false);
  const today = new Date();
  today.setDate(today.getDate() + 1);
  const fouryearsday = new Date();
  fouryearsday.setDate(fouryearsday.getDate() + 1462);
  const [onlstartdate, setOnlstartdate] = React.useState(today);
  const [hmstartdate, setHmstartdate] = React.useState(today);
  const [onlenddate, setOnlenddate] = React.useState(fouryearsday);
  const [hmenddate, setHmenddate] = React.useState(fouryearsday);
  const [onlineSRate, setOnlSRate] = React.useState("");
  const [atHomeSRate, setAhmSRate] = React.useState("");
  const [onlineSRateFaculty, setOnlSRateFaculty] = React.useState("");
  const [onlineSRateCenterHd, setOnlSRateCenterHd] = React.useState("");
  const [atHomeSRateFaculty, setAhmSRateFaculty] = React.useState("");
  const [atHomeSRateCenterHd, setAhmSRateCenterHd] = React.useState("");
  const {
    updateSpecialRatesDetails,
    getParentDetails,
    allparents
  } = React.useContext(GlobalContext);

  let _id = showPayments._id;

  React.useEffect(() => {
    getParentDetails(_id);
    window.scrollTo(0, 0);
  }, []);

  const handleOlSDateChange = date => {
    setOnlstartdate(date);
  };

  const handleOlEDateChange = date => {
    setOnlenddate(date);
  };

  const handleAtmSDateChange = date => {
    setHmstartdate(date);
  };

  const handleAtmEDateChange = date => {
    setHmenddate(date);
  };

  const handleClose = () => {
    setOpen(false);
    setBack(true);
  };

  const backToAllParent = () => {
    setBack(true);
  };

  const onSubmit = e => {
    e.preventDefault();
    const specialrates = {
      studentId: ids,
      onlineSRate: parseInt(onlineSRate),
      onlineSRFrom: moment(onlstartdate).format("YYYY-MM-DD"),
      onlineSRTo: moment(onlenddate).format("YYYY-MM-DD"),
      onlineFacultyPer: parseInt(onlineSRateFaculty),
      onlineCenterheadPer: parseInt(onlineSRateCenterHd),
      atHomeSRate: parseInt(atHomeSRate),
      atHomeSRFrom: moment(hmstartdate).format("YYYY-MM-DD"),
      atHomeSRTo: moment(hmenddate).format("YYYY-MM-DD"),
      atHomeFacultyPer: parseInt(atHomeSRateFaculty),
      atHomeCenterheadPer: parseInt(atHomeSRateCenterHd)
    };
    updateSpecialRatesDetails(specialrates);
    setOpen(true);
  };

  let olpfrate = onlineSRate - onlineSRateFaculty - onlineSRateCenterHd;
  let ahpfrate = atHomeSRate - atHomeSRateFaculty - atHomeSRateCenterHd;

  if (back) {
    return <ParentPersonalDetails showPayments={showPayments} />;
  }

  return (
    <React.Fragment>
      <Box pb={2}>
        <Button
          onClick={backToAllParent}
          type="submit"
          size="large"
          pt={2}
          variant="contained"
          color="primary"
        >
          <KeyboardBackspaceIcon />
        </Button>
        <Typography
          variant="h6"
          style={{
            display: "inline-block",
            paddingLeft: "10px",
            textTransform: "uppercase"
          }}
        >
          Updating special rates for {name}
        </Typography>
      </Box>
      <form onSubmit={onSubmit}>
        <Box boxShadow={3} mb={1}>
          <Grid container component={Paper}>
            <Grid item xs={12} style={{ background: "antiquewhite" }}>
              <Box p={2}>
                <Typography variant="h6">
                  Set Special Rate for At Home Classes
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box p={2} bgcolor="white" display="flex" >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    required
                    minDate={today}
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    label="Start Date"
                    value={hmstartdate}
                    name="hmstartdate"
                    onChange={handleAtmSDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box p={2} bgcolor="white" display="flex" >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    required
                    //minDate={}
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    // id="date-picker-inline"
                    label="End Date"
                    value={hmenddate}
                    name="hmenddate"
                    onChange={handleAtmEDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Box>
            </Grid>

             <Grid  container xs={12} >
            <Grid item xs={12} md={3}>
              <Box p={2} bgcolor="white">
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  name="atHomeSRate"
                  label="Student"
                  value={atHomeSRate}
                  onChange={e => setAhmSRate(e.target.value)}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={3}>
              <Box p={2} bgcolor="white">
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  name="atHomeSRateFaculty"
                  label="Faculty"
                  value={atHomeSRateFaculty}
                  onChange={e => setAhmSRateFaculty(e.target.value)}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={3}>
              <Box p={2} bgcolor="white">
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  name="atHomeSRateCenterHd"
                  label="Center Head"
                  value={atHomeSRateCenterHd}
                  onChange={e => setAhmSRateCenterHd(e.target.value)}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={3}>
              <Box p={2} bgcolor="white">
                <TextField
                  fullWidth
                  disabled
                  variant="outlined"
                  //  name="onlineSRateCenterHd"
                  label="Platform Rate"
                  value={ahpfrate}
                  // onChange={e => setOnlSRateCenterHd(e.target.value)}
                />
              </Box>
            </Grid>
            </Grid>
            
            <Grid item xs={12} style={{ background: "antiquewhite", marginTop:"20px" }}>
              <Box p={2}>
                <Typography variant="h6">
                  Set Special Rate for Online Classes
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box p={2} bgcolor="white" display="flex" >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    required
                    minDate={today}
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    label="Start Date"
                    value={onlstartdate}
                    name="onlstartdate"
                    onChange={handleOlSDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box p={2} bgcolor="white" display="flex">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    required
                   // minDate={onlenddate}
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    //  id="date-picker-inline"
                    label="End Date"
                    value={onlenddate}
                    name="onlenddate"
                    onChange={handleOlEDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Box>
            </Grid>

            <Grid  container xs={12} >
            <Grid item xs={12} md={3}>
              <Box p={2} bgcolor="white">
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  name="onlineSRate"
                  label="Student"
                  value={onlineSRate}
                  onChange={e => setOnlSRate(e.target.value)}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={3}>
              <Box p={2} bgcolor="white">
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  name="onlineSRateFaculty"
                  label="Faculty"
                  value={onlineSRateFaculty}
                  onChange={e => setOnlSRateFaculty(e.target.value)}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={3}>
              <Box p={2} bgcolor="white">
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  name="onlineSRateCenterHd"
                  label="Center Head"
                  value={onlineSRateCenterHd}
                  onChange={e => setOnlSRateCenterHd(e.target.value)}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={3}>
              <Box p={2} bgcolor="white">
                <TextField
                  fullWidth
                  variant="outlined"
                  //  name="onlineSRateCenterHd"
                  label="Platform Rate"
                  disabled
                  value={olpfrate}
                  // onChange={e => setOnlSRateCenterHd(e.target.value)}
                />
              </Box>
            </Grid>
            </Grid>

            <Grid item xs={12}>
              <Box p={2}>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </form>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          align="center"
          style={{ background: "antiquewhite" }}
          id="responsive-dialog-title"
        >
          {"Special Rates Are Upadated"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button onClick={handleClose} variant="contained" color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default SetSpecialRates;
