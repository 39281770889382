import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { fade, makeStyles } from '@material-ui/core/styles';
import { Table, InputBase, Button, CircularProgress, Box } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import SearchIcon from "@material-ui/icons/Search";
import { GlobalContext } from "../../context/GlobalState";
import UpdateModule from './UpdateModule';
import AddModule from './AddModule';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'program', label: 'Program' },
  { id: 'title', label: 'Ttile' },
  { id: 'month', label: 'Month' },
  { id: 'week', label: 'Week' },
  { id: 'description', label: 'Description' },
  { id: 'active', label: 'Active' }
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">

        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto"
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit",
    border: '1px solid #dddd',
    margin: '6px 6px 0px 6px',
    borderRadius: '6px'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch"
      }
    }
  },
  tbrow: {
    '&:nth-of-type(odd)': {
      backgroundColor: "#faebd761",
    },
    "&:hover": {
      boxShadow: "0px 2px 5px lightsteelblue",
    },
  }
}));

export default function AllModules() {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [onpayment, setPayment] = React.useState(false)
  const [spinner, setSpinner] = React.useState(true);
  const [newdata1, setNewdata1] = React.useState({})
  const [addModule, setAddModule] = React.useState(false);
  const [editingInventory, setEditingInventory] = React.useState(false);
  const [updatingPayment, setUpdatingPayment] = React.useState(false);
  const { allModules, getModuleDetails, encodeDecodeString } = React.useContext(GlobalContext);
  const isAdmin = encodeDecodeString(window.localStorage.getItem("user"),'decode');
  React.useEffect(() => {
    setData(allModules);
    setTimeout(() => setSpinner(false), 1000)
  }, [allModules]);

  React.useEffect(() => {
    let searched = allModules.filter(
      row =>
        row.title.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
        row.program.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
        row.description.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
    );

    if (searchTerm === "") {
      getModuleDetails();
      setData(searched);
    } else {
      setData(searched);
    }
  }, [searchTerm]);

  const handleSearch = event => {
    setSearchTerm(event.target.value);
  };

  const editRecord = (e, id) => {
    var data1 = allModules.filter(item => item._id === id)
    setNewdata1({ ...data1[0], fromListing: true })
    setEditingInventory(true)
  };


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = allModules.map((n) => n.user.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const goToAddModule = () => {
    setAddModule(true);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, allModules.length - page * rowsPerPage);

  if (addModule) { return <AddModule /> }
  if (editingInventory) {
    return <UpdateModule showData={newdata1} />
  }
  if (updatingPayment) {
  }
  return (
    <div className={classes.root}>
      {isAdmin !== "stores" ?
      <Box display="flex" pb={2}>
        <Box flexGrow={1}>
          <Button
            onClick={goToAddModule}
            type="submit"
            size="large"
            pt={2}
            variant="contained"
            color="primary"
          >
            Add Module
          </Button>
        </Box>
      </Box> : "" }
      <Paper className={classes.paper}>
        <div className={classes.search} style={{ display: "inline-block" }}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={allModules.length}
            />

            {spinner ? (
              <TableBody style={{ position: "relative" }}>
                <TableRow>
                  <TableCell>
                    <CircularProgress
                      style={{ padding: "5px", position: "absolute", left: "50%" }}
                      color="primary"
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
                <TableBody>
                  {stableSort(data, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          className={classes.tbrow}
                          key={row._id}
                          style={{ cursor: "pointer" }}
                          hover>
                          <TableCell padding="checkbox"></TableCell>
                          <TableCell>{row.program}</TableCell>
                          <TableCell>{row.title}</TableCell>
                          <TableCell>{row.month}</TableCell>
                          <TableCell>{row.week}</TableCell>
                          <TableCell>{row.description}</TableCell>
                          <TableCell>{row.active ? "Yes" : "No"}</TableCell>
                          <TableCell>
                          {isAdmin !== "stores" ?
                            <Button
                            onClick={e => editRecord(e.currentTarget.value, row._id)}
                            type="submit"
                            size="medium"
                            pt={2}
                            variant="contained"
                            color="primary"
                            disabled={isAdmin === 'marketing'}
                          >
                            Update
                        </Button> : "" }
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 75, 100, 500]}
          component="div"
          count={allModules.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
