import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { Box, Grid, Button } from '@material-ui/core';
import StudentDetail from './StudentDetail'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import AllStudents from './AllStudents';
import { GlobalContext } from "../../context/GlobalState";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import EditStudent from './EditStudent';
import AddOrders from '../Orders/AddOrders';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "antiquewhite"
  },
}));

export default function SingleStudentData({ showPayments }) {
  const classes = useStyles();
  const { getSingleParentClassDetails, getParentDetails } = React.useContext(GlobalContext);
  const [value, setValue] = React.useState(0);
  const [back, setBack] = React.useState(false);
  const [editStudent, setEditStudent] = React.useState(false);
  const [selectedStudent, setSelectedStudent] = React.useState(null);
  const [editingOrder, setEditingOrder] = React.useState(false);
  const [editingOrderDetails, setEditingOrderDetails] = React.useState(false);

  const _id = showPayments._id

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    getSingleParentClassDetails(_id)
    getParentDetails()
    window.scrollTo(0, 0)
  }, []);

  const backToAllFaculty = () => {
    setBack(true)
  }

  const showData = (arg, id) => {
    setEditStudent(true);
    var data = showPayments;
    setSelectedStudent(data);
  };

  const handleEditOrder = (order) => {
    setEditingOrder(true)
    setEditingOrderDetails(order)
  }

  const handleEditOrderClose = (order) => {
    setEditingOrder(false)
  }

  if (editingOrder) { return <AddOrders handleEditOrderClose={handleEditOrderClose} editOrder={true} order={editingOrderDetails} student={showPayments} /> }

  if (back) { return <AllStudents /> }

  if (editStudent) {
    return <EditStudent Parent={null} Student={selectedStudent} />;
  }
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Box pb={2}>
          <Button
            onClick={backToAllFaculty}
            type="submit"
            size="large"
            pt={2}
            variant="contained"
            color="primary"
          >
            <KeyboardBackspaceIcon />
          </Button>
          <Typography variant="h6" style={{ display: "inline-block", paddingLeft: "10px", textTransform: "uppercase" }}>{showPayments.name}</Typography>
          <Tooltip title="Edit" arrow>
            <IconButton
              className="bg5"
              style={{ float: "right" }}
              onClick={e => showData(e.currentTarget.value, _id) } >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Box>

      </Grid>
      <div className={classes.root}>
        <AppBar position="static">
          <Tabs variant="fullWidth" value={value} onChange={handleChange} aria-label="simple tabs example">
            <Tab label="GENERAL INFORMATION" {...a11yProps(0)} />
          </Tabs>
        </AppBar>
        <TabPanel style={{ backgroundColor: "#fff" }} value={value} index={0}>
          <StudentDetail
            showPayments={showPayments}
            editOrder={handleEditOrder}
          />
        </TabPanel>
      </div>
    </React.Fragment>
  );
}
