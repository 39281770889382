import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  IconButton, MenuItem, Menu,FormControl, InputLabel, Select, Input
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from '@material-ui/icons/AccountCircle';
import { GlobalContext } from "../context/GlobalState";


export default function Appbar(props) {
 
  let { classes, onOpen, open, LogOut } = props;
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [centercodeAdmin, setCentercodeAdmin] = React.useState("");
  const open2 = Boolean(anchorEl);
  const { allcentercode, getCenterCodeDetails, encodeDecodeString } = React.useContext(
    GlobalContext
  );
  const isAdmin = encodeDecodeString(window.localStorage.getItem("user"),'decode');
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  React.useEffect(() => {
    getCenterCodeDetails();
    const centerCode = localStorage.getItem("centercode")
    setTimeout(() => changeCenterCode(centerCode !== undefined && centerCode !== "" && centerCode !== null ? centerCode : "ARA"), 2500);    
  }, []);

  const changeCenterCode = e => {
    let centerCode;
    if (e) {
      centerCode = e?.target?.value ? e.target.value : e
    }
    setCentercodeAdmin(centerCode);
    if (centerCode) {
      localStorage.setItem("centercode", centerCode);      
    }
    if (e && e.target && e.target.value) {
      window.location.reload();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar className="apbar1">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={onOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap style={{flexGrow:'1'}}>
            Koala Leap {centercodeAdmin}
          </Typography>
          {isAdmin === "admin" || isAdmin === "operations" || isAdmin === "coo" || isAdmin === "academics" ?
          <div style={{ float: "right", marginRight: "36px", marginTop: "14px", width: "150px" }}>
          <FormControl fullWidth>
            <InputLabel style={{fontSize: "15px", color:"#ffffff"}} id="class-status-label-app-bar">Select CenterCode</InputLabel>
            <Select style={{color:"#ffffff"}}
              labelId="demo-simple-select-filled-label12"
              id="demo-simple-select-filled12"
              value={centercodeAdmin}
              onChange={changeCenterCode}
              name="centerCode"
              label="Center Code"
            >
              {allcentercode.map(code => (
                <MenuItem key={code._id} value={code.centerCode}>
                  {code.centerCode}
                </MenuItem>
              ))}
            </Select>
              
          </FormControl>
        </div> : ""}

          <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open2}
                onClose={handleClose}
              >
                {/* <MenuItem onClick={handleClose}>View Profile</MenuItem> */}
               
                <MenuItem  onClick={LogOut}>Log Out</MenuItem>
              </Menu>
        </Toolbar>
      </AppBar>
    </div>
  );
}
