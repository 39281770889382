import React, { useContext, useState } from "react"
import { Container, Box } from "@material-ui/core"
import favicon from '../../media/koala-favicon.png'
import { Typography, TextField, Button } from "@material-ui/core"
import Avatar from "@material-ui/core/Avatar"
import { GlobalContext } from '../../context/GlobalState'
import "./MyLogin.css"
import {Link as LinkRouter} from 'react-router-dom'

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
   
  const { getUserDetails , err} = useContext(GlobalContext);

  const onSubmit = e => {
    e.preventDefault();

    const userDetails = {
      email: email.toLowerCase(),
      password
    };
    getUserDetails(userDetails)
  };
  
  return (
    <div className="bt">
      <br></br> <br></br>
      <form onSubmit={onSubmit}>
        <Container maxWidth="xs">
          <Box
            bgcolor="#ffffff"
            boxShadow="3"
            p={4}
            textAlign="center"
            borderRadius={16}
          >
            <br></br>
            <Avatar style={{ margin: "0 auto", height: '75px', width: '75px' }} src={favicon} />
            <br></br>
            <Typography variant="h5" mt="2" pt="3">Welcome Admin</Typography>

            <TextField
              label="Email"
              required
              variant="outlined"
              margin="normal"
              fullWidth
              value={email}
              onChange={e => setEmail(e.target.value)}
              name="email"
            />

            <TextField
              label="Password"
              type="password"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              value={password}
              onChange={e => setPassword(e.target.value)}
              name="password"
            />
            <br></br><br></br>
            <Button
              fullWidth
              type="submit"
              size="large"
              pt={2}
              variant="contained"
              color="primary"
            >
              Login
            </Button>
            <br></br><br></br>
            <Typography>
              <LinkRouter to="/forgot-password" style={{cursor:"pointer"}}>Forgot Password</LinkRouter>
            </Typography>
            <h5 color="red">{err}</h5>
          </Box>
        </Container>
      </form>
    </div>
  );
};
 export default Login