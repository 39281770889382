import React, { Component, Fragment } from "react";
import {
  Grid,
  Box,
  TextField,
  MenuItem,
  FormHelperText,
  InputLabel,
  FormControl,
  Select, Chip, Checkbox, FormControlLabel
} from "@material-ui/core";
import PhoneInput from 'material-ui-phone-number';
import { Autocomplete } from "@material-ui/lab";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import UiDatePicker from "../DatePicker/DatePicker";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

class FormRender extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  
  InputBuilder = (form, onChange, isDisable = false, index) => {
    const { xs, sm, type, value, label, name, placeholder, data,
      validation = {}, minDate, minDateValue, maxDateValue, checkOn,
      withoutCreate, format, touched, multiple = false,
      groupBy, valid, errorText, helperText, path, display = true,
      disabled = false, withTime = false, options, keyText, handleBlur, minValue, maxValue, maxSelection, ...rest } = form;
    let inputElement = null;
    if (!display) {
      return null
    }
    switch (type) {
      case "sectionMultiple":
        const { sectionMultipleBlock } = this.props || {};
        inputElement = (
          <Grid key={index} item xs={xs || 2} sm={sm || 2} style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <Box px={2}>
              {sectionMultipleBlock}
            </Box>
          </Grid>
        );
        break;
      case "dropdown":
        inputElement = (
          <Grid key={index} item xs={xs || 12} sm={sm || 6}>
            <Box px={2} mt={2}>
              <FormControl error={!!errorText} required={!!validation?.required} variant='outlined' fullWidth>
                <InputLabel id={`demo-simple-select-${index}`}>
                  {label}
                </InputLabel>
                <Select
                  id={`demo-simple-select-${index}`}
                  value={value}
                  required={!!validation?.required}
                  name={name}
                  label={label}
                  multiple={multiple || false}
                  disabled={isDisable || disabled}
                  onChange={(e) => onChange(e, index)}
                >
                  {options && options?.length ? options.map((item, indexx) =>
                    <MenuItem key={'indexx' + indexx} value={item}> {item[keyText] || item} </MenuItem>)
                    :
                    <MenuItem key={"nooption"} disabled value={""}> {"No Options"} </MenuItem>}
                </Select>
                <FormHelperText>{errorText}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>
        );
        break;
        case "dropdownMultiple":
          inputElement = (
            <Grid key={index} item xs={xs || 12} sm={sm || 6}>
              <Box px={2} mt={2}>
                <FormControl error={!!errorText} required={!!validation?.required} variant='outlined' fullWidth>
                  <InputLabel id={`demo-simple-select-${index}`}>
                    {label}
                  </InputLabel>
                  <Select
                    id={`demo-simple-select-${index}`}
                    value={value}
                    required={!!validation?.required}
                    name={name}
                    label={label}
                    multiple={multiple || false}
                    disabled={isDisable || disabled}
                    onChange={(e) => onChange(e, index)}
                    renderValue={selected => {
                      if (selected && selected.length === 0) {
                        return <em>Select</em>;
                      } else if (selected && selected[0] && selected[0][keyText]) {
                        return selected.map(obj => obj[keyText]).join(", ") || selected;
                      }
                      // This will return a comma-separated list of the values.
                      return selected;
                    }}
                  >
                    {options && options?.length ? options.map((item, indexx) =>
                      <MenuItem key={'indexx' + indexx} value={item}> {item[keyText] || item} </MenuItem>)
                      :
                      <MenuItem key={"nooption"} disabled value={""}> {"No Options"} </MenuItem>}
                  </Select>
                  <FormHelperText>{errorText}</FormHelperText>
                </FormControl>
              </Box>
            </Grid>
          );
          break;
      case "autocomplete":
        inputElement = (
          <Grid key={index} item xs={xs || 12} sm={sm || 6}>
            <Box px={2} mt={2}>
              <FormControl error={!!errorText} required={!!validation?.required} variant='outlined' fullWidth>
                <Autocomplete
                  fullWidth={true}
                  style={{ width: "100%" }}
                  disabled={isDisable || disabled}
                  multiple={multiple || false}
                  id={`demo-simple-select-${index}`}
                  options={options}
                  value={value}
                  onChange={(event, newValue) => {
                    onChange({ target: { value: newValue } }, index)
                  }}
                  getOptionLabel={(option) => option[keyText] || option}
                  getOptionSelected={(option) => {
                    return (value.length && multiple ? value : [value]).findIndex(item => JSON.stringify(item) === JSON.stringify(option)) >= 0
                  }}
                  renderOption={(option, { selected }) => {
                    return (
                      <React.Fragment>
                        {multiple &&
                          <Checkbox
                            iicon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                        }
                        { (keyText && option[keyText]) ? option[keyText] : option.title}
                      </React.Fragment>
                    )
                  }}
                  renderInput={(params) => (
                    <TextField fullWidth={true} {...params} variant="outlined" label={label} placeholder="Select more" />
                  )}
                />
                <FormHelperText>{errorText}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>
        );
        break;
      case "datetime-local":
        inputElement = (
          <Grid key={index} item xs={xs || 12} sm={sm || 6}>
            <Box px={2} mt={2}>
              <FormControl error={!!errorText} required={!!validation?.required} variant='outlined' fullWidth>
                <UiDatePicker
                  type={type}
                  name={name}
                  format={format}
                  placeholder={placeholder}
                  minDate={minDate}
                  withTime={withTime}
                  checkOn={checkOn ? checkOn : null}
                  label={label}
                  disabled={isDisable || disabled}
                  onChange={(e) => {
                    onChange(e, index)
                  }}
                  onBlur={handleBlur ? () => handleBlur(index) : () => { }}
                  value={value}
                  required={!!validation?.required}
                  error={!!errorText}
                  errorMsg={errorText}
                />
              </FormControl>
            </Box>
          </Grid>
        );
        break;
      case "date":
        inputElement = (
          <Grid key={index} item xs={xs || 6} sm={sm || 4}>
            <Box px={2}>
              <TextField
                type="date"
                format="dd/MM/yyyy"
                margin="normal"
                variant="outlined"
                name={name}
                fullWidth
                inputProps={{ min: minValue, max: (maxValue || "") }}
                placeholder={label}
                value={value}
                label={label}
                required={!!validation?.required}
                error={!!errorText}
                helperText={errorText}
                disabled={isDisable || disabled}
                onChange={(value) => onChange(value, index)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Grid>
        );
        break;
      case "phone":
        inputElement = (
          <Grid key={index} item xs={xs || 12} sm={sm || 6} style={{ display: "flex", alignItems: "center" }}>
            <Box mt={1} px={2} style={{ width: "100%" }}>
              <PhoneInput
                style={{ width: "100%" }}
                defaultCountry={"in"}
                variant="outlined"
                name={name}
                value={value}
                label={label}
                required={!!validation?.required}
                error={!!errorText}
                helperText={errorText}
                disabled={isDisable || disabled}
                onChange={(value) => onChange({ target: { value: value } }, index)}
              />
            </Box>
          </Grid>
        );

        break;
      case "text":
        inputElement = (
          <Grid key={index} item xs={xs || 12} sm={sm || 6}>
            <Box px={2}>
              <TextField
                variant="outlined"
                name={name}
                value={value}
                label={label}
                type={type}
                margin="normal"
                fullWidth
                required={!!validation?.required}
                error={!!errorText}
                helperText={errorText}
                disabled={isDisable || disabled}
                onChange={(e) => onChange(e, index)}
              />
            </Box>
          </Grid>
        );
        break;
      case "label":
          inputElement = (
          <Grid item xs={12} sm={6}>
          <Box px={2}>
          <TextField margin="normal" fullWidth label={label} variant="filled" value={value} disabled={disabled}/>
          </Box>
        </Grid>
          );
        break;
      case "number":
        inputElement = (
          <Grid key={index} item xs={xs || 4} sm={sm || 3}>
            <Box px={2}>
              <TextField
                variant="outlined"
                name={name}
                value={value}
                label={label}
                type={type}
                margin="normal"
                fullWidth
                required={!!validation?.required}
                error={!!errorText}
                helperText={errorText}
                disabled={isDisable || disabled}
                onChange={(e) => onChange(e, index)}
              />
            </Box>
          </Grid>
        );
        break;
      case "checkbox":
        inputElement = (
          <Grid key={index} item xs={xs || 12} sm={sm || 6}>
            <Box px={2} mt={2}>
              <FormControl error={!!errorText} required={!!validation?.required} variant='outlined'>
                <FormControlLabel
                  disabled={disabled}
                  control={
                    <Checkbox
                      name={name}
                      value={value}
                      checked={value || false}
                      onChange={(e, cValue) => {
                        onChange({ target: { value: cValue } }, index)
                      }}
                    />
                  }
                  label={label}
                  labelPlacement={form?.labelPlacement}
                />
                <FormHelperText>{errorText ? errorText : null}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>
        );
        break;
      default:
        break;
    }
    return inputElement;
  };
  render() {
    const { form, onChange, isDisable } = this.props;
    const renderedForm = form?.map((item, index) =>
      this.InputBuilder(item, onChange, isDisable, index)
    );
    return renderedForm;
  }
}
export default FormRender;
