import React from "react";
import { GlobalContext } from "../../context/GlobalState";
import {
  Paper, Grid, Box, MenuItem, Dialog, DialogContent, DialogContentText,
  DialogActions, DialogTitle, FormControl, Select,
  InputLabel, Button, Typography, TextField} from "@material-ui/core";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import {DropzoneArea} from 'material-ui-dropzone'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import MediaCard2 from "../MediaCard2";

class BulkImport extends React.Component {

  static contextType = GlobalContext;
  
  constructor(props){
    super(props);
    this.state = {
      files: [],
      user: "",
      remarks:"",
      paymentDate: new Date(),
      isBack: false,
      dialogMsg: "",
      openDialog: false,
      disabled : false
    };
  }

  handleDialogOpen = (msg = "") => {
    this.setState({dialogMsg : msg, openDialog : true});
  }

  handleChange = files => {
    this.setState({
      files: files
    });
  }

  handleCloseBtn = () => {
    const { uploadPayments }  = this.context;
    if(uploadPayments){
      this.setState({openDialog : false, isBack: true});
    }
    else{
      this.setState({openDialog : false});
    }
  }

  backToDashboard = () => {
    this.setState({ isBack : true});
  }

  handleClose = () => {
    this.setState({openDialog : false});
  }

  handlePaymentDate = (val) => {
    this.setState({paymentDate: val})
  }

  setRemarks = (val) => {
    this.setState({remarks: val});
  }

  onChangeUser = e => {
    const value = e.target.value ? e.target.value : "";
    this.setState({user: value})
  };

  onSubmit = async e => {
    this.setState({disabled: true});
    e.preventDefault();
    const statement = {
        paymentDate: moment(this.state.paymentDate).format("YYYY-MM-DD"),
        user: this.state.user,
        csvdata: this.state.files,
        remarks: this.state.remarks
    };
    const {uploadUsersPayments} = this.context;
    if (this.state.files.length === 0) {
      this.handleDialogOpen("Error! File missing.");
      this.setState({disabled: false});
    } else if (this.state.files.length === 1){
      await uploadUsersPayments(statement);
      setTimeout(() => {
        const {err, uploadPayments} = this.context || {};
        if(err){
          this.handleDialogOpen(err);
          this.setState({disabled: false});
        }
        else if(uploadPayments){
          this.handleDialogOpen(uploadPayments);
        }
      }, 1000);
    } else {
      this.handleDialogOpen("Error! Multiple files uploaded.");
      this.setState({disabled: false});
    }
  };


  render() {
    const { openDialog, dialogMsg, isBack, paymentDate, user, remarks } = this.state;
    if (isBack) {
      return <MediaCard2 />;
    }
    return (
    <React.Fragment>
      <Grid item xs={12}>
        <Box pb={2}>
          <Button
            onClick={this.backToDashboard}
            type="submit"
            size="large"
            variant="contained"
            color="primary"
          >
          <KeyboardBackspaceIcon/>
          </Button>
        </Box>
      </Grid>
      <Box boxShadow={3} mb={1}>
        <form onSubmit={this.onSubmit}>
        <Grid container component={Paper}>
            <Grid
              item
              xs={12}
              style={{ background: "antiquewhite", marginBottom: "16px" }}
            >
              <Box p={2}>
                <Typography variant="h6">Payment Bulk Import</Typography>
              </Box>
            </Grid>
            
        <Grid item xs={12} md={4}>
              <Box px={1} mt={2} pl={2}>
                <FormControl variant="outlined" style={{width:"50%", marginLeft: "5px"}} required>
                  <InputLabel id="user">User</InputLabel>
                  <Select
                    labelId="user"
                    value={user}
                    onChange={this.onChangeUser}
                    name="user"
                    label="User"
                    required
                  >
                    <MenuItem value={"faculty"}>Faculty</MenuItem>
                    <MenuItem value={"centerhead"}>Center Head</MenuItem>
                  </Select>
                </FormControl>
               </Box>
        </Grid>

        <Grid item xs={12} md={4}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              disableFuture
              autoOk
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              label="Date (DD/MM/YYYY)"
              value={paymentDate}
              name="paymentDate"
              onChange={this.handlePaymentDate}
              required
            />
            </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={12} md={4} style={{paddingRight: "15px"}}>
            <TextField
            label="Remarks"
            variant="outlined"
            margin="normal"
            fullWidth
            value={remarks} 
            onChange={e => this.setRemarks(e.target.value)}
            name="remarks"
            required
          /></Grid>
          
            <Grid              
              item
              md={12}
              style={{ background: "antiquewhite", margin: "40px 20px" }}
            >
            <DropzoneArea style={{minHeight: "100px !important"}}
                acceptedFiles={['.csv']}
                onChange={this.handleChange.bind(this)}
                dropzoneText="Upload .csv file"
            />
            </Grid>
            <Grid item>
              <Box p={2}>
                <Button
                  disabled={this.state.disabled}
                  type="submit"
                  size="large"
                  pt={2}
                  variant="contained"
                  color="primary"
                >
                  Upload
                </Button>
              </Box>
            </Grid>
            <Grid item>
              <Box p={2}>
                <Button
                  onClick={this.backToDashboard}
                  type="submit"
                  size="large"
                  pt={2}
                  variant="contained"
                  color="primary"
                >
                  Back
                </Button>
              </Box>
            </Grid>
            </Grid>
        </form>
        
      </Box>

      <Dialog
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
          open={openDialog}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle align="center" id="responsive-dialog-title">
            {"Message"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button onClick={this.handleCloseBtn} autoFocus color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
    </React.Fragment>
  );
}
}
export default BulkImport;