import React, { useReducer, createContext } from "react";
import AppReducer from "./AppReducer";
import axios from "axios";
const isAdmin = window.atob(window.localStorage.getItem("user"))
const initialState = {
  err: "",
  addParent: null,
  addOrder: null,
  addInventory: null,
  addModule: null,
  addStudent: null,
  deleteStudent: null,
  studentFeedbackForm: {},
  updateStudentModule: "",
  removeStudentModule: "",
  userDetails: { email: "", password: "", loggedIn: false },
  faculty: {},
  emailmsg: "",
  codeCheck: "",
  passwordCheck: '',
  allcentercode: [],
  allclasses: [],
  inventoryDataByProgram: [],
  oldOrderData: [],
  allparents: [],
  allInventory: [],
  allModules: [],
  allOrder: [],
  allstudents: [],
  allfaculty: [],
  allcenterhead: [],
  updatefaculty: {},
  updateParent: null,
  updateInventory: null,
  updateModule: null,
  updateParentPayment: null,
  generateParentPayment: null,
  updateStudent: null,
  updatecenterhead: {},
  allfctyClass: [],
  singleparentClass: [],
  singleparentPayment: [],
  singlefacultyPayment: [],
  singlecenterheadPayment: [],
  singlefctyClass: [],
  singlectHead: [],
  allstatecity: {},
  bankFile: [],
  financeFile: [],
  uploadPayments: [],
  paymentStatement: [],
  summaryData: [],
  summaryDataFaculty: [],
  addressData: [],
  parentStatement:[],
  enquiryStatement:[],
  studentOrderDetails: [],
  updatedOrderDetails: "",
  fragment: isAdmin === "faculty" ? "CLASSES" : isAdmin === "stores" ? "ALLINVENTORY" : "HOME",
  encodeDecodeStr: '',
  graphDataSeries: [],
  graphDataCategories: []
};

const environment = process.env.REACT_APP_STAGE;

let apiUrl
if(environment == "stage") {
  apiUrl = "https://staging-api.koalapreschool.com/api"
} else if (environment == "prod") {
  apiUrl = "https://api.koalapreschool.com/api" // prod api url goes here
} else {
  apiUrl = "http://localhost:9191/api"
}
const secretkey = 'FM?WafKiopS@k2&L$zfS#aA9'

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  async function apiCall(method, url, data, status = false) {
    try {
      const axiosInstance = axios.create({ baseURL: apiUrl });
      axiosInstance.defaults.headers['x-auth-token'] = localStorage.getItem("token")
      let response = await axiosInstance({ method, url, data })
      if (response && (response?.status === 200 || response?.status === 201 || response?.status === 206)) {
        if(status) {
          return response
        }
        return response.data
      }
      else {
        throw response
      }
    } catch (err) {
      throw status ? err.response : err.response?.data
    }
  }

  /****************************************/
  /**** Redrecting to the components ***/
  /****************************************/
  function getRedirect(setFragment) {
    if (setFragment === 'PAYMENTHISTORY') {
      dispatch({ type: "GENERATE_PAYMENT_STATEMENTS", payload: [] });
    } else if (setFragment === 'CENTERPAYMENTHISTORY'){
      dispatch({ type: "GENERATE_PARENT_STATEMENTS", payload: [] });
    } else if (setFragment === 'BULKIMPORT') {
      dispatch({ type: "UPLOAD_PAYMENTS", payload: [] });
    } else if (setFragment === 'DAYSUMMARY'){
      dispatch({ type: "DAY_SUMMARY_DETAILS", payload: [] });
      dispatch({ type: "DAY_SUMMARY_DETAILS_FACULTY", payload: [] });
    }
    dispatch({ type: "GET_REDIRECT_DETAILS", payload: setFragment });
  }

  /****************************************/
  /**** Encode/Decode string ***/
  /****************************************/
  function encodeDecodeString(str, opnType) {
    let value = '';
    if (opnType === 'encode'){
      value = window.btoa(str);
    } else if (opnType === 'decode'){
      value = window.atob(str);
    }
    return value;
  }



  /****************************************/
  /**** Login ***/
  /****************************************/
  async function getUserDetails(params) {
    try {
      const result = await axios.post(
        `${apiUrl}/admin/auth `,
        params,
        {
          headers: {
            "secret-key": secretkey
          }
        }
      );
      localStorage.setItem("token", result.data.token);
      if(result.data.role === "centerhead") {
        localStorage.setItem("centercode", result.data.centercode);
        localStorage.setItem("email", result.data.email);
      } else if(result.data.role === "faculty") {
        localStorage.setItem("centercode", result.data.centercode);
        localStorage.setItem("email", result.data.email);
        localStorage.setItem("facultyId", result.data.facultyId);
      }
      localStorage.setItem("user", window.btoa(result.data.role));
      window.location.href = "/dashboard";
      dispatch({ type: "GET_USER_DETAILS", payload: params });

    } catch (err) {
      dispatch({ type: "GET_ERROR_MSG", payload: err.response.data });
    }
  }

  /****************************************/
  /**** Email Verification ***/
  /****************************************/
  async function EmailVerification(params) {
    try {
      dispatch({ type: "GET_ERROR_MSG", payload: '' });
      const result = await axios.post(
        `${apiUrl}/admin/auth/verifyemailresetpassword`,
        params,
        {
          headers: {
            "secret-key": secretkey
          }
        }
      );
      localStorage.setItem("token", result.data.token);
      dispatch({ type: "EMAIL_VERIFICATION", payload: result.data });
    } catch (err) {
      dispatch({ type: "GET_ERROR_MSG", payload: err.response.data });
    }
  }



  /****************************************/
  /**** Code Verification ***/
  /****************************************/
  async function CodeVerification(verCode) {
    try {
      dispatch({ type: "GET_ERROR_MSG", payload: '' });
      const result = await axios.post(
        `${apiUrl}/admin/auth/verifycoderesetpassword`,
        verCode,
        {
          headers: {
            "secret-key": secretkey
          }
        }
      );
      localStorage.setItem("token", result.data.token);
      dispatch({ type: "CODE_VERIFICATION", payload: result.data });
    } catch (err) {
      dispatch({ type: "GET_ERROR_MSG", payload: err.response.data });
    }
  }


  /****************************************/
  /**** Password Verification ***/
  /****************************************/
  async function PasswordVerification(restPassword) {
    try {
      dispatch({ type: "GET_ERROR_MSG", payload: '' });
      const result = await axios.post(
        `${apiUrl}/admin/auth/resetpassword`,
        restPassword,
        {
          headers: {
            "secret-key": secretkey
          }
        }
      );
      localStorage.setItem("token", result.data.token);
      dispatch({ type: "PASSWORD_VERIFICATION", payload: result.data });
    } catch (err) {
      dispatch({ type: "GET_ERROR_MSG", payload: err.response.data });
    }
  }

  /****************************************/
  /**** LogOut ***/
  /****************************************/
  async function LogOut() {
    localStorage.removeItem("token");
    localStorage.removeItem("centercode");
    localStorage.removeItem("user");
    localStorage.removeItem("email");
    localStorage.removeItem("facultyId");
    window.location.href = "/";
  }

  /****************************************/
  /**** Statistics Details ***/
  /****************************************/
  async function getStatDetails() {
    try {
      const centerCode = localStorage.getItem("centercode")
      const res = await axios.get(
        `${apiUrl}/admin/stats?centerCode=${centerCode}`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token")
          }
        }
      );
      dispatch({ type: "GET_STAT_DETAILS", payload: res.data });
    } catch (err) {
      console.log(err);
    }
  }

  /****************************************/
  /**** Get All Classes Details ***/
  /****************************************/
  async function getClassesDetails(status) {
    try {
      let rest
      if (isAdmin === "centerhead" || isAdmin === "admin" || isAdmin === "coo" || isAdmin === "academics") {
        const centerCode = window.localStorage.getItem("centercode")
        rest = await axios.get(
          `${apiUrl}/admin/classes${status ? '?status='+status : ""}${centerCode ? '&centerCode='+centerCode : ""}`,
          {
            headers: {
              "x-auth-token": localStorage.getItem("token")
            }
          }
        );
      } else if (isAdmin === "faculty") {
        const centerCode = window.localStorage.getItem("centercode")
        const facultyId = window.localStorage.getItem("facultyId")
        rest = await axios.get(
          `${apiUrl}/admin/classes${status ? '?status='+status : ""}${centerCode ? '&centerCode='+centerCode : ""}${facultyId ? '&facultyId='+facultyId : ""}`,
          {
            headers: {
              "x-auth-token": localStorage.getItem("token")
            }
          }
        );
      }
       else {
        rest = await axios.get(
          `${apiUrl}/admin/classes${status ? '?status='+status : ""}`,
          {
            headers: {
              "x-auth-token": localStorage.getItem("token")
            }
          }
        );
      }
      dispatch({ type: "GET_CLASS_DETAILS", payload: rest.data });

    } catch (err) {
      console.log(err);
    }
  }

  /****************************************/
  /**** Get Inventory Data as per Student Program ***/
  /****************************************/
  async function getInventoryDataByProgram(program, id) {
    try {
      let rest = await axios.get(
          `${apiUrl}/admin/inventory?program=${program}&student=${id}`,
          {
            headers: {
              "x-auth-token": localStorage.getItem("token")
            }
          }
        );
      dispatch({ type: "GET_INVENTORY_DATA_BY_PROGRAM", payload: rest.data.newOrder });
      dispatch({ type: "OLD_ORDER_DATA", payload: rest.data.oldOrder });
    } catch (err) {
      console.log(err);
      dispatch({ type: "GET_ERROR_MSG", payload: err.data });
    }
  }

  /****************************************/
  /**** Get All Parents Details ***/
  /****************************************/
  async function getParentDetails() {
    try {
      let rest
      if (isAdmin === "centerhead" || isAdmin === "admin" || isAdmin === "coo" || isAdmin === "academics") {
        const centerCode = window.localStorage.getItem("centercode")
        rest = await axios.get(
          `${apiUrl}/admin/parent${centerCode ? '?centerCode='+centerCode : ""}`,
          {
            headers: {
              "x-auth-token": localStorage.getItem("token")
            }
          }
        );
      } else {
        rest = await axios.get(
          `${apiUrl}/admin/parent`,
          {
            headers: {
              "x-auth-token": localStorage.getItem("token")
            }
          }
        );
      }

      dispatch({ type: "GET_PARENT_DETAILS", payload: rest.data });

    } catch (err) {
      console.log(err);
    }
  }

  /****************************************/
  /**** Update Order Details ***/
  /****************************************/
  async function updateOrderDetails(data) {
    try {
      const values = await axios.post(
        `${apiUrl}/admin/order/update`,
        data,
          {
            headers: {
              "x-auth-token": localStorage.getItem("token")
            }
          }
      );

      dispatch({ type: "UPDATE_ORDER_DETAILS", payload: values.data });

    } catch (err) {
      console.log(err);
    }
  }

  /****************************************/
  /**** Get Order Details ***/
  /****************************************/
  async function getOrderDetails(status) {
    try {
      const values = await axios.get(
        `${apiUrl}/admin/order${status ? '?status='+status : ""}`,
          {
            headers: {
              "x-auth-token": localStorage.getItem("token")
            }
          }
      );

      dispatch({ type: "GET_ORDER_DETAILS", payload: values.data });
      
    } catch (err) {
      console.log(err);
    }
  }

  /****************************************/
  /**** Get Inventory Details ***/
  /****************************************/
  async function getInventoryDetails() {
    try {
      const values = await axios.get(
        `${apiUrl}/admin/inventory/all`,
          {
            headers: {
              "x-auth-token": localStorage.getItem("token")
            }
          }
      );

      dispatch({ type: "GET_INVENTORY_DETAILS", payload: values.data });

    } catch (err) {
      console.log(err);
    }
  }

  /****************************************/
  /**** Get Module Details ***/
  /****************************************/
  async function getModuleDetails(searchObj) {
    try {
      let values
      if (searchObj) {
        values = await axios.get(
          `${apiUrl}/admin/module/all?month=`+parseInt(searchObj.month)+'&week='+searchObj.week+`&program=${searchObj.program}`,
            {
              headers: {
                "x-auth-token": localStorage.getItem("token")
              }
            }
        );
      } else {
        values = await axios.get(
          `${apiUrl}/admin/module/all`,
            {
              headers: {
                "x-auth-token": localStorage.getItem("token")
              }
            }
        );
      }
      
      dispatch({ type: "GET_MODULE_DETAILS", payload: values.data });

    } catch (err) {
      console.log(err);
    }
  }

  /****************************************/
  /**** Get All Students Details ***/
  /****************************************/
  async function getStudentDetails(status) {
    try {
      let resp
      if (isAdmin === "centerhead" || isAdmin === "admin" || isAdmin === "coo" || isAdmin === "academics") {
        const centerCode = window.localStorage.getItem("centercode")
        resp = await axios.get(
          `${apiUrl}/admin/student${status ? '?approval_status.status='+status : ""}${centerCode ? status === undefined ? '?centerCode='+centerCode : '&centerCode='+centerCode : ""}`,
          {
            headers: {
              "x-auth-token": localStorage.getItem("token")
            }
          }
        );
      }else if (isAdmin === "faculty") {
        const centerCode = window.localStorage.getItem("centercode")
        const facultyId = window.localStorage.getItem("facultyId");
        resp = await axios.get(
          `${apiUrl}/admin/student/listing${status ? '?approval_status.status='+status : ""}${centerCode ? status === undefined ? '?centerCode='+centerCode + '&facultyId='+facultyId : '&centerCode='+centerCode + '&facultyId='+facultyId : "" + '&facultyId='+facultyId }`,
          {
            headers: {
              "x-auth-token": localStorage.getItem("token")
            }
          }
        );
      } else {
        resp = await axios.get(
          `${apiUrl}/admin/student${status ? '?approval_status.status='+status : ""}`,
          {
            headers: {
              "x-auth-token": localStorage.getItem("token")
            }
          }
        );
      }
      dispatch({ type: "GET_STUDENT_DETAILS", payload: resp.data });

    } catch (err) {
      console.log(err);
    }
  }

  /****************************************/
  /**** Get All States And Cities Details ***/
  /****************************************/
  async function getStateCityDetails() {
    try {
      const values = await axios.get(
        `${apiUrl}/statecity`,
        {
          headers: {
            "secret-key": secretkey
          }
        }
      );

      dispatch({
        type: "GET_STATE_CITY_DETAILS",
        payload: values.data.statecity
      });

    } catch (err) {
      console.log(err);
    }
  }

  /****************************************/
  /**** Get Address Details ***/
  /****************************************/
  async function getAddressDetails(summary) {
    try {
      const values = await axios.post(
        `${apiUrl}/address`,
        summary,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token")
          }
        }
      );

      dispatch({
        type: "GET_ADDRESS_DETAILS",
        payload: values.data
      });

    } catch (err) {
      console.log(err);
    }
  }

  /****************************************/
  /**** Remove Student Completed Modules ***/
  /****************************************/
  async function removeCompleteModule(data) {
    try {
      const values = await axios.post(
        `${apiUrl}/admin/module/remove/completedModules`,
        data,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token")
          }
        }
      );

      dispatch({
        type: "FEEDBACK_FORM",
        payload: values.data
      });

    } catch (err) {
      console.log(err);
    }
  }

  /****************************************/
  /**** Update Student Completed Modules ***/
  /****************************************/
  async function updateCompletedModule(data) {
    try {
      const values = await axios.post(
        `${apiUrl}/admin/module/update/completedModules`,
        data,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token")
          }
        }
      );

      dispatch({
        type: "UPDATE_STUDENT_MODULE",
        payload: values.data
      });

    } catch (err) {
      console.log(err);
    }
  }

  /****************************************/
  /**** Send Feedback Form ***/
  /****************************************/
  async function sendFeedbackForm(data) {
    try {
      const values = await axios.post(
        `${apiUrl}/admin/misc/parent/form/feedback`,
        data,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token")
          }
        }
      );

      dispatch({
        type: "FEEDBACK_FORM",
        payload: values.data
      });

    } catch (err) {
      console.log(err);
    }
  }

  /****************************************/
  /**** Get Day Summary Details***/
  /****************************************/
  async function getSummaryDataDetails(summary) {
    try {
      const value = await axios.post(
        `${apiUrl}/admin/summary/statement`,
        summary,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token")
          }
        }
      );
      dispatch({ type: "DAY_SUMMARY_DETAILS", payload: value.data });
    } catch (err) {
      console.log(err);
    }
  }

  /****************************************/
  /**** Get Day Summary Details For Faculty***/
  /****************************************/
  async function getSummaryDataDetailsForFaculty(summary) {
    try {
      const value = await axios.post(
        `${apiUrl}/admin/summary/statementFaculty`,
        summary,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token")
          }
        }
      );
      dispatch({ type: "DAY_SUMMARY_DETAILS_FACULTY", payload: value.data });
    } catch (err) {
      console.log(err);
    }
  }

  /****************************************/
  /**** Get All Center Code Details***/
  /****************************************/
  async function getCenterCodeDetails(status) {
    try {
      let url
      if (status){
        url = `${apiUrl}/admin/centercode?status=${status}`
      } else {
        url = `${apiUrl}/admin/centercode`
      }
      const value = await axios.get(
        url,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token")
          }
        }
      );

      dispatch({ type: "GET_CENTER_CODE_DETAILS", payload: value.data });
    } catch (err) {
      console.log(err);
    }
  }


  /****************************************/
  /**** Get Single Center Head Profile Details ***/
  /****************************************/
  async function getSingleCenterHeadDetails(_id) {
    try {
      const val = await axios.get(
        `${apiUrl}/admin/centerhead?_id=${_id}`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token")
          }
        }
      );

      dispatch({ type: "GET_SINGLE_CENTERHEAD_DETAILS", payload: val.data });
      
    } catch (err) {
      console.log(err);
    }
  }


  /****************************************/
  /**** Get All Faculties Class Schedule Details***/
  /****************************************/
  async function getFacultyClassDetails() {
    try {
      const val = await axios.get(
        `${apiUrl}/admin/classes`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token")
          }
        }
      );

      dispatch({ type: "GET_FACULTY_CLASS_DETAILS", payload: val.data });
    } catch (err) {
      console.log(err);
    }
  }


  /****************************************/
  /**** Get Single Faculty Class Details***/
  /****************************************/
  async function getSingleFacultyClassDetails(_id) {

    try {
      const val = await axios.get(
        `${apiUrl}/admin/classes?facultyId=${_id}`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token")
          }
        }
      );

      dispatch({ type: "GET_SINGLE_FACULTY_CLASS_DETAILS", payload: val.data });

    } catch (err) {
      console.log(err);
    }
  }

  /****************************************/
  /**** Get Single Student Class Details***/
  /****************************************/
  async function getSingleParentClassDetails(_id) {
    try {
      const val = await axios.get(
        `${apiUrl}/admin/classes?parentId=${_id}`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token")
          }
        }
      );

      dispatch({ type: "GET_SINGLE_PARENT_CLASS_DETAILS", payload: val.data });

    } catch (err) {
      console.log(err);
    }
  }

  /****************************************/
  /**** Get Single Parent Payments Details ***/
  /****************************************/
  async function getSingleParentPaymentDetails(_id) {
    try {
      const val = await axios.get(
        `${apiUrl}/admin/parent/payments?_id=${_id}`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token")
          }
        }
      );

      dispatch({ type: "GET_SINGLE_PARENT_PAYMENT_DETAILS", payload: val.data });

    } catch (err) {
      console.log(err);
    }
  }


  /****************************************/
  /**** Get Single Faculty Payments Details***/
  /****************************************/
  async function getSingleFacultyPaymentDetails(_id) {
    try {
      const val = await axios.get(
        `${apiUrl}/admin/faculty/payments?_id=${_id}`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token")
          }
        }
      );

      dispatch({ type: "GET_SINGLE_FACULTY_PAYMENT_DETAILS", payload: val.data });

    } catch (err) {
      console.log(err);
    }
  }


  /****************************************/
  /**** Get Single Center Head Payments Details***/
  /****************************************/
  async function getSingleCenterHeadPaymentDetails(_id) {
    try {
      const val = await axios.get(
        `${apiUrl}/admin/centerhead/payments?_id=${_id}`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token")
          }
        }
      );

      dispatch({ type: "GET_SINGLE_CENTERHEAD_PAYMENT_DETAILS", payload: val.data });

    } catch (err) {
      console.log(err);
    }
  }


  /****************************************/
  /**** Get All Center Head Details***/
  /****************************************/
  async function getCenterHeadDetails(status) {
    try {
      let val
      if (isAdmin === "centerhead") {
        const email = window.localStorage.getItem("email")
        if (status === undefined && (email !== "" || email !== undefined || email  !==null )){
          val = await axios.get(
            `${apiUrl}/admin/centerhead${email ? '?email='+email : ""}`,
            {
              headers: {
                "x-auth-token": localStorage.getItem("token")
              }
            }
          );
        } else {
          val = await axios.get(
            `${apiUrl}/admin/centerhead${status ? '?status='+status : ""}${status ? status === undefined ||  status === "" ? '?email='+email : '&email='+email : ""}`,
            {
              headers: {
                "x-auth-token": localStorage.getItem("token")
              }
            }
          );
        }
      }else{
      val = await axios.get(
        `${apiUrl}/admin/centerhead${status ? '?status='+status : ""}`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token")
          }
        }
      );
      }

      dispatch({ type: "GET_CENTERHEAD_DETAILS", payload: val.data });

    } catch (err) {
      console.log(err);
    }
  }

  /*******************************************************/
  /**** Generate Finance File For Center Head And Faculty ***/
  /*******************************************************/
  async function FinanceFileGenerate(payment) {
    try {
      const val = await axios.post(
        `${apiUrl}/admin/finance`,
        payment,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
            "Access-Control-Allow-Origin": '*',
          }
        }
      );

      dispatch({ type: "GENERATE_FINANCE_FILE", payload: val.data });
      console.log(val.data)
    } catch (err) {
      console.log(err);
      dispatch({ type: "GET_ERROR_MSG", payload: err });
    }
  }

  /*******************************************************/
  /**** Generate Bank File For Center Head And Faculty ***/
  /*******************************************************/
  async function BankFileGenerate(payment) {
    // dispatch({ type: "GET_ERROR_MSG", payload: '' });
    try {
      const val = await axios.post(
        `${apiUrl}/admin/statement`,
        payment,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
            "Access-Control-Allow-Origin": '*',
          }
        }
      );

      dispatch({ type: "GENERATE_BANK_FILE", payload: val.data });
      console.log(val.data)
    } catch (err) {
      console.log(err);
      dispatch({ type: "GET_ERROR_MSG", payload: err });
    }
  }

  async function uploadUsersPayments(statement) {
    try {
      let csvData = new FormData();
      csvData.append("csvdata", statement.csvdata[0])
      csvData.append("paymentDate", statement.paymentDate)
      csvData.append("user", statement.user);
      csvData.append("remarks", statement.remarks);
      
      const val = await axios.post(
        `${apiUrl}/admin/statement/data`,
        csvData,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
            "Access-Control-Allow-Origin": '*',
          }
        }
      );
      dispatch({ type: "UPLOAD_PAYMENTS", payload: val.data });
    } catch (err) {
      console.log(err);
      dispatch({ type: "GET_ERROR_MSG", payload: err });
    }
  }



  /****************************************/
  /**** Generate Payment statements For Center Head, Faculty and Parent***/
  /****************************************/
  async function getPaymentStatements(statement) {
    // dispatch({ type: "GET_ERROR_MSG", payload: '' });
    try {
      const val = await axios.post(
        `${apiUrl}/admin/statement/userStatement`,
        statement,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
            "Access-Control-Allow-Origin": '*',
          }
        }
      );
      dispatch({ type: "GENERATE_PAYMENT_STATEMENTS", payload: val.data.data });
      console.log(val.data)
    } catch (err) {
      console.log(err);
      dispatch({ type: "GET_ERROR_MSG", payload: err });
    }
  }

  /****************************************/
  /**** Generate Parent statements ***/
  /****************************************/


  async function getParentStatements(statement) {
    try {
      const val = await axios.post(
        `${apiUrl}/admin/statement/parentStatement`,
        statement,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
            "Access-Control-Allow-Origin": '*',
          }
        }
      );
      dispatch({ type: "GENERATE_PARENT_STATEMENTS", payload: val.data.data });
      console.log(val.data)
    } catch (err) {
      console.log(err);
      dispatch({ type: "GET_ERROR_MSG", payload: err });
    }
  }

  /****************************************/
  /**** Generate Enquiry data ***/
  /****************************************/


  async function getEnquiryData(statement) {
    try {
      const val = await axios.post(
        `${apiUrl}/admin/statement/enquiryStatement`,
        statement,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
            "Access-Control-Allow-Origin": '*',
          }
        }
      );
      dispatch({ type: "GENERATE_ENQUIRY_DATA", payload: val.data.data });
      console.log(val.data)
    } catch (err) {
      console.log(err);
      dispatch({ type: "GET_ERROR_MSG", payload: err });
    }
  }


  /****************************************/
  /**** Update Special Rates***/
  /****************************************/
  async function updateSpecialRatesDetails(specialrates) {
    dispatch({ type: "GET_ERROR_MSG", payload: '' });
    try {
      const val = await axios.post(
        `${apiUrl}/admin/parent/setspecialcommissionrate`,
        specialrates,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token")
          }
        }
      );

      dispatch({ type: "UPDATE_SPECIAL_RATES_DETAILS", payload: val.data });
      console.log(val.data)
    } catch (err) {
      console.log(err);
      dispatch({ type: "GET_ERROR_MSG", payload: err.response.data });
    }
  }

  /****************************************/
  /**** Add New Center Head ***/
  /****************************************/
  async function addCenterHeadDetails(addhead) {
    dispatch({ type: "GET_ERROR_MSG", payload: '' });
    try {
      const val = await axios.post(
        `${apiUrl}/admin/centerhead`,
        addhead,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token")
          }
        }
      );

      dispatch({ type: "ADD_CENTERHEAD_DETAILS", payload: val.data });

    } catch (err) {
      dispatch({ type: "GET_ERROR_MSG", payload: err.response.data });
    }
  }


  /****************************************/
  /**** Update Center Head ***/
  /****************************************/
  async function updateCenterHeadDetails(updatecthead, _id) {
    try {
      const val2 = await axios.post(
        `${apiUrl}/admin/centerhead/updatecenterhead/`,
        updatecthead,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token")
          }
        }
      );

      console.log(val2.data)
      dispatch({ type: "UPDATE_CENTERHEAD_DETAILS", payload: val2.data });
    } catch (err) {
      dispatch({ type: "GET_ERROR_MSG", payload: err.response.data });
    }
  }

  /****************************************/
  /**** Get All Faculties Details***/
  /****************************************/
  async function getFacultyDetails(status) {
    try {
      let val
      if (isAdmin === "centerhead" || isAdmin === "admin" || isAdmin === "coo" || isAdmin === "academics") {
        const centerCode = window.localStorage.getItem("centercode")
        val = await axios.get(
          `${apiUrl}/admin/faculty${status ? '?status='+status : ""}${centerCode ? status === undefined ? '?centerCode='+centerCode : '&centerCode='+centerCode : ""}`,
          {
            headers: {
              "x-auth-token": localStorage.getItem("token")
            }
          }
        );
      }else if (isAdmin === "faculty") {
        const facultyId = window.localStorage.getItem("facultyId")
        val = await axios.get(
          `${apiUrl}/admin/faculty${status ? '?status='+status + '&_id='+facultyId : '?_id='+facultyId}`,
          {
            headers: {
              "x-auth-token": localStorage.getItem("token")
            }
          }
        );
      }
      else {
        val = await axios.get(
          `${apiUrl}/admin/faculty${status ? '?status='+status : ""}`,
          {
            headers: {
              "x-auth-token": localStorage.getItem("token")
            }
          }
        );
      }

      dispatch({ type: "GET_FACULTY_DETAILS", payload: val.data });

    } catch (err) {
      console.log(err);
    }
  }

  async function getFacultyDetailsForAddClass(data) {
    try {
      const val = await apiCall('post', `/admin/classes/faculty`, data)
      console.log("GET_FACULTY_DETAILS_FOR_ADD_CLASS", val)
      dispatch({ type: "GET_FACULTY_DETAILS_FOR_ADD_CLASS", payload: val });
    } catch (err) {
      dispatch({ type: "GET_FACULTY_DETAILS_FOR_ADD_CLASS", payload: [] });
      dispatch({ type: "GET_ERROR_MSG", payload: err });
    }
  }

  async function addUpdateClassDetails(data, method = 'post') {
    dispatch({ type: "GET_ERROR_MSG", payload: '' });
    try {
      const val = await apiCall(method, `/admin/classes`, data)
      dispatch({ type: "ADD_UPDATE_CLASS_DETAILS", payload: val });
      dispatch({ type: "GET_ERROR_MSG", payload: null });

    } catch (err) {
      dispatch({ type: "ADD_UPDATE_CLASS_DETAILS", payload: null });
      dispatch({ type: "GET_ERROR_MSG", payload: err });
    }
  }


  /****************************************/
  /**** Add New Faculty Details***/
  /****************************************/
  async function addFacultyDetails(addfac) {
    dispatch({ type: "GET_ERROR_MSG", payload: '' });
    try {
      const val = await axios.post(
        `${apiUrl}/admin/faculty`,
        addfac,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token")
          }
        }
      );

      dispatch({ type: "ADD_FACULTY_DETAILS", payload: val.data });

    } catch (err) {
      console.log(err);
      dispatch({ type: "GET_ERROR_MSG", payload: err.response.data });
    }
  }

  /****************************************/
  /**** Add New Student Details***/
  /****************************************/
  async function addStudentDetails(student) {
    dispatch({ type: "GET_ERROR_MSG", payload: '' });
    try {
      const val = await axios.post(
        `${apiUrl}/admin/student`,
        student,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token")
          }
        }
      );
      dispatch({ type: "ADD_STUDENT_DETAILS", payload: val.data });
      dispatch({ type: "GET_ERROR_MSG", payload: null });

    } catch (err) {
      console.log(err);
      dispatch({ type: "ADD_STUDENT_DETAILS", payload: null });
      dispatch({ type: "GET_ERROR_MSG", payload: err.response.data });
    }
  }

  /****************************************/
  /**** Get Student Order Details ***/
  /****************************************/
  async function getStudentOrderDetails(id) {
    try {
      const res = await axios.get(
        `${apiUrl}/admin/student/order/details/${id}`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token")
          }
        }
      );
      dispatch({ type: "GET_STUDENT_ORDER_DETAILS", payload: res.data });
    } catch (err) {
      console.log(err);
    }
  }

  /****************************************/
  /**** Create Order ***/
  /****************************************/
  async function createOrderDetails(params) {
    dispatch({ type: "GET_ERROR_MSG", payload: '' });
    try {
      const val2 = await axios.post(
        `${apiUrl}/admin/order`,
        params,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token")
          }
        }
      );
      dispatch({ type: "ADD_ORDER", payload: val2.data });
      dispatch({ type: "GET_ERROR_MSG", payload: null });
      console.log("RESP: ", val2)
      console.log("RESP 1: ", val2.data)
    } catch (err) {
      console.log(err);
      dispatch({ type: "ADD_ORDER", payload: null });
      dispatch({ type: "GET_ERROR_MSG", payload: err.response.data });
    }
  }


  /****************************************/
  /**** Add New Parent Details***/
  /****************************************/
  async function addParentDetails(parent) {
    dispatch({ type: "GET_ERROR_MSG", payload: '' });
    try {
      const val = await axios.post(
        `${apiUrl}/admin/parent`,
        parent,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token")
          }
        }
      );
      dispatch({ type: "ADD_PARENT_DETAILS", payload: val.data });
      dispatch({ type: "GET_ERROR_MSG", payload: null });

    } catch (err) {
      console.log(err);
      dispatch({ type: "ADD_PARENT_DETAILS", payload: null });
      dispatch({ type: "GET_ERROR_MSG", payload: err.response.data });
    }
  }

  /****************************************/
  /**** Add New Inventory Details***/
  /****************************************/
  async function addInventoryDetails(data) {
    dispatch({ type: "GET_ERROR_MSG", payload: '' });
    try {
      const val = await axios.post(
        `${apiUrl}/admin/inventory`,
        data,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token")
          }
        }
      );
      dispatch({ type: "ADD_INVENTORY_DETAILS", payload: val.data });
      dispatch({ type: "GET_ERROR_MSG", payload: null });

    } catch (err) {
      console.log(err);
      dispatch({ type: "ADD_INVENTORY_DETAILS", payload: null });
      dispatch({ type: "GET_ERROR_MSG", payload: err.response.data });
    }
  }

  /****************************************/
  /**** Add New Module Details***/
  /****************************************/
  async function addModuleDetails(data) {
    dispatch({ type: "GET_ERROR_MSG", payload: '' });
    try {
      const val = await axios.post(
        `${apiUrl}/admin/module`,
        data,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token")
          }
        }
      );
      dispatch({ type: "ADD_MODULE_DETAILS", payload: val.data });
      dispatch({ type: "GET_ERROR_MSG", payload: null });

    } catch (err) {
      console.log(err);
      dispatch({ type: "ADD_MODULE_DETAILS", payload: null });
      dispatch({ type: "GET_ERROR_MSG", payload: err.response.data });
    }
  }

  /****************************************/
  /**** Update Inventory Details ***/
  /****************************************/
  async function updateInventoryDetails(updateInventory) {
    dispatch({ type: "GET_ERROR_MSG", payload: '' });
    try {
      const val2 = await axios.post(
        `${apiUrl}/admin/inventory/update`,
        updateInventory,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token")
          }
        }
      );
      dispatch({ type: "GET_ERROR_MSG", payload: null });
      dispatch({ type: "UPDATE_INVENTORY_DETAILS", payload: val2.data });

    } catch (err) {
      dispatch({ type: "UPDATE_INVENTORY_DETAILS", payload: null });
      dispatch({ type: "GET_ERROR_MSG", payload: err.response.data });
    }
  }

  /****************************************/
  /**** Update Module Details ***/
  /****************************************/
  async function updateModuleDetails(updateModule) {
    dispatch({ type: "GET_ERROR_MSG", payload: '' });
    try {
      const val2 = await axios.post(
        `${apiUrl}/admin/module/update`,
        updateModule,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token")
          }
        }
      );
      dispatch({ type: "GET_ERROR_MSG", payload: null });
      dispatch({ type: "UPDATE_MODULE_DETAILS", payload: val2.data });

    } catch (err) {
      dispatch({ type: "UPDATE_MODULE_DETAILS", payload: null });
      dispatch({ type: "GET_ERROR_MSG", payload: err.response.data });
    }
  }

  /****************************************/
  /**** Update Parent Details ***/
  /****************************************/
  async function updateParentDetails(updateprnt) {
    dispatch({ type: "GET_ERROR_MSG", payload: '' });
    try {
      const val2 = await axios.post(
        `${apiUrl}/admin/parent/updateParent`,
        updateprnt,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token")
          }
        }
      );
      dispatch({ type: "GET_ERROR_MSG", payload: null });
      dispatch({ type: "UPDATE_PARENT_DETAILS", payload: val2.data });

    } catch (err) {
      dispatch({ type: "UPDATE_PARENT_DETAILS", payload: null });
      dispatch({ type: "GET_ERROR_MSG", payload: err.response.data });
    }
  }

  /****************************************/
  /**** Update Parent Payment Details ***/
  /****************************************/
  async function updateParentPaymentDetails(updateprnt) {
    dispatch({ type: "GET_ERROR_MSG", payload: '' });
    try {
      const val2 = await axios.post(
        `${apiUrl}/admin/parent/updateParentPayment`,
        updateprnt,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token")
          }
        }
      );
      dispatch({ type: "GET_ERROR_MSG", payload: null });
      dispatch({ type: "UPDATE_PARENT_PAYMENT_DETAILS", payload: val2.data });

    } catch (err) {
      dispatch({ type: "UPDATE_PARENT_PAYMENT_DETAILS", payload: null });
      dispatch({ type: "GET_ERROR_MSG", payload: err.response.data });
    }
  }


  /****************************************/
  /**** Generate Parent Payment Link Via RazorPay ***/
  /****************************************/
  async function generateParentPaymentLink(updateparent) {
    dispatch({ type: "GET_ERROR_MSG", payload: '' });
    try {
      const val = await axios.post(
        `${apiUrl}/admin/payment/link`,
        updateparent,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token")
          }
        }
      );
      dispatch({ type: "GET_ERROR_MSG", payload: null });
      dispatch({ type: "GENERATE_PARENT_PAYMENT_LINK", payload: val.data });

    } catch (err) {
      dispatch({ type: "GENERATE_PARENT_PAYMENT_LINK", payload: null });
      dispatch({ type: "GET_ERROR_MSG", payload: err.response.data });
    }
  }


  /****************************************/
  /**** Update Student Details ***/
  /****************************************/
  async function updateStudentDetails(student) {
    dispatch({ type: "GET_ERROR_MSG", payload: '' });
    try {
      const val2 = await axios.put(
        `${apiUrl}/admin/student/update`,
        student,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token")
          }
        }
      );
      dispatch({ type: "GET_ERROR_MSG", payload: null });
      dispatch({ type: "UPDATE_STUDENT_DETAILS", payload: val2.data });

    } catch (err) {
      dispatch({ type: "UPDATE_STUDENT_DETAILS", payload: null });
      dispatch({ type: "GET_ERROR_MSG", payload: err.response.data });
    }
  }
  function clearErrStudent() {
    dispatch({ type: "GET_ERROR_MSG", payload: null });
    dispatch({ type: "DELETE_STUDENT_DETAILS", payload: null });
  }
  /****************************************/
  /**** Delete Student Details ***/
  /****************************************/
  async function deleteStudentDetails(student) {

    try {
      const val2 = await axios.post(
        `${apiUrl}/admin/student/delete`,
        student,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token")
          }
        }
      );

      dispatch({ type: "GET_ERROR_MSG", payload: null });
      dispatch({ type: "DELETE_STUDENT_DETAILS", payload: val2.data });
    } catch (err) {
      dispatch({ type: "DELETE_STUDENT_DETAILS", payload: null });
      dispatch({ type: "GET_ERROR_MSG", payload: err.response.data });
    }
  }

  /****************************************/
  /**** Update Faculty Details ***/
  /****************************************/
  async function updateFacultyDetails(updatefac, _id) {
    dispatch({ type: "GET_ERROR_MSG", payload: '' });
    try {
      const val2 = await axios.post(
        `${apiUrl}/admin/faculty/updateFaculty/`,
        updatefac,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token")
          }
        }
      );

      dispatch({ type: "UPDATE_FACULTY_DETAILS", payload: val2.data });

    } catch (err) {
      console.log(err);
      dispatch({ type: "GET_ERROR_MSG", payload: err.response.data });
    }
  }


  /****************************************/
  /**** Add New Center Code ***/
  /****************************************/
  async function addCenterCodeDetails(addcentercode) {
    dispatch({ type: "GET_ERROR_MSG", payload: "" });
    try {
      const val = await axios.post(
        `${apiUrl}/admin/centercode`,
        addcentercode,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token")
          }
        }
      );

      dispatch({ type: "ADD_CENTER_CODE_DETAILS", payload: val.data });

    } catch (err) {
      console.log(err);
      dispatch({ type: "GET_ERROR_MSG", payload: err.response.data });
    }
  }

  /****************************************/
  /**** Bulk Center Code Upload Option ***/
  /****************************************/
  async function addBulkCenterCodeDetails(addBulkcentercode) {
    try {
      const val = await axios.post(
        `${apiUrl}/admin/centercode`,
        addBulkcentercode,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
            "Content-Type": "application/json"
          }
        }
      );

      dispatch({ type: "ADD_BULK_CENTER_CODE_DETAILS", payload: val.data });

    } catch (err) {
      console.log(err);
      dispatch({ type: "GET_ERROR_MSG", payload: err.response.data });
    }
  }

  /****************************************/
  /************ Graph data ***************/
  /****************************************/

  async function getGraphDataDetails(summary) {
    try {
      const val = await axios.post(
        `${apiUrl}/admin/graph/data`,
        summary,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
            "Content-Type": "application/json"
          }
        }
      );
      dispatch({ type: "GRAPH_DATA_CATEGORIES", payload: val.data.categories });
      dispatch({ type: "GRAPH_DATA_SERIES", payload: val.data.seriesData });
    } catch (err) {
      console.log(err);
      dispatch({ type: "GET_ERROR_MSG", payload: err.response.data });
    }
  }

  /****************************************/
  /**** Update Center Code Details ***/
  /****************************************/
  async function updateCenterCodeDetails(updatecentercode, _id) {
    // console.log(updatecentercode)
    dispatch({ type: "GET_ERROR_MSG", payload: '' });
    try {
      const val2 = await axios.post(
        `${apiUrl}/admin/centercode/updateCentercode`,
        updatecentercode,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token")
          }
        }
      );

      dispatch({ type: "UPDATE_CENTER_CODE_DETAILS", payload: val2.data });
    } catch (err) {
      console.log(err);
      dispatch({ type: "GET_ERROR_MSG", payload: err.response.data });
    }
  }


  /****************************************/
  /**** Delete Center Code Details ***/
  /****************************************/
  async function deleteCenterCodeDetails(_id) {

    try {
      const val2 = await axios.delete(
        `${apiUrl}/admin/centercode/${_id}`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token")
          }
        }
      );

      dispatch({ type: "DELETE_CENTER_CODE_DETAILS", payload: val2.data });
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <React.Fragment>
      <GlobalContext.Provider
        value={{
          userDetails: state.userDetails,
          studentOrderDetails: state.studentOrderDetails,
          updatedOrderDetails: state.updatedOrderDetails,
          err: state.err,
          addParent: state.addParent,
          addOrder: state.addOrder,
          addInventory: state.addInventory,
          addModule: state.addModule,
          deleteStudent: state.deleteStudent,
          studentFeedbackForm: state.studentFeedbackForm,
          updateStudentModule: state.updateStudentModule,
          removeStudentModule: state.removeStudentModule,
          addStudent: state.addStudent,
          emailCheck: state.emailCheck,
          codeCheck: state.codeCheck,
          passwordCheck: state.passwordCheck,
          faculty: state.faculty,
          allclasses: state.allclasses,
          inventoryDataByProgram: state.inventoryDataByProgram,
          oldOrderData: state.oldOrderData,
          allparents: state.allparents,
          allInventory: state.allInventory,
          allModules: state.allModules,
          allOrder: state.allOrder,
          allstudents: state.allstudents,
          allcentercode: state.allcentercode,
          allstatecity: state.allstatecity,
          allfaculty: state.allfaculty,
          summaryData: state.summaryData,
          summaryDataFaculty: state.summaryDataFaculty,
          addressData: state.addressData,
          allFacultyForAddClass: state.allFacultyForAddClass,
          addUpdateClass: state.addUpdateClass,
          bankFile: state.bankFile,
          financeFile: state.financeFile,
          uploadPayments: state.uploadPayments,
          paymentStatement: state.paymentStatement,
          parentStatement: state.parentStatement,
          enquiryStatement: state.enquiryStatement,
          updatecenterhead: state.updatecenterhead,
          updatefaculty: state.updatefaculty,
          updateStudent: state.updateStudent,
          updateParent: state.updateParent,
          updateInventory: state.updateInventory,
          updateModule: state.updateModule,
          updateParentPayment: state.updateParentPayment,
          generateParentPayment: state.generateParentPayment,
          allfctyClass: state.allfctyClass,
          allcenterhead: state.allcenterhead,
          singlefctyClass: state.singlefctyClass,
          singlectHead: state.singlectHead,
          singleparentClass: state.singleparentClass,
          singleparentPayment: state.singleparentPayment,
          singlefacultyPayment: state.singlefacultyPayment,
          singlecenterheadPayment: state.singlecenterheadPayment,
          fragment: state.fragment,
          encodeDecodeStr: state.encodeDecodeStr,
          graphDataCategories: state.graphDataCategories,
          graphDataSeries: state.graphDataSeries,
          apiCall,
          getUserDetails,
          addFacultyDetails,
          addParentDetails,
          addInventoryDetails,
          addModuleDetails,
          addStudentDetails,
          addCenterHeadDetails,
          addCenterCodeDetails,
          deleteCenterCodeDetails,
          deleteStudentDetails,
          updateFacultyDetails,
          updateCenterHeadDetails,
          updateSpecialRatesDetails,
          updateCenterCodeDetails,
          PasswordVerification,
          EmailVerification,
          CodeVerification,
          clearErrStudent,
          getStatDetails,
          getRedirect,
          encodeDecodeString,
          LogOut,
          addBulkCenterCodeDetails,
          getParentDetails,
          getInventoryDetails,
          getModuleDetails,
          getOrderDetails,
          updateOrderDetails,
          getClassesDetails,
          getInventoryDataByProgram,
          getStudentDetails,
          sendFeedbackForm,
          updateCompletedModule,
          removeCompleteModule,
          FinanceFileGenerate,
          BankFileGenerate,
          uploadUsersPayments,
          getPaymentStatements,
          getParentStatements,
          getEnquiryData,
          getAddressDetails,
          getSummaryDataDetails,
          getSummaryDataDetailsForFaculty,
          getCenterCodeDetails,
          getStateCityDetails,
          getFacultyDetails,
          getFacultyDetailsForAddClass,
          addUpdateClassDetails,
          getFacultyClassDetails,
          getCenterHeadDetails,
          getSingleFacultyClassDetails,
          getSingleCenterHeadDetails,
          getSingleParentClassDetails,
          getSingleParentPaymentDetails,
          getSingleFacultyPaymentDetails,
          getSingleCenterHeadPaymentDetails,
          updateInventoryDetails,
          updateModuleDetails,
          updateParentDetails,
          updateParentPaymentDetails,
          generateParentPaymentLink,
          updateStudentDetails,
          createOrderDetails,
          getStudentOrderDetails,
          getGraphDataDetails
        }}
      >
        {children}
      </GlobalContext.Provider>
    </React.Fragment>
  );
};
