import React from "react";
import { GlobalProvider } from "./context/GlobalState";
import { Login } from "./components/Authentication/Login";
import { Switch, Route } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import EmailVerify from "./components/Authentication/EmailVerify"

function App() {
  return (
    <GlobalProvider>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/forgot-password" component={EmailVerify} />
      </Switch>
    </GlobalProvider>
  );
}

export default App;
