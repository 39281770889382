const dev = {
  maxLoss: process.env.REACT_APP_MAX_LOSS_PER_HOUR,
  max_loss_validation_text: 'Faculty and Center head cannot be greater than',
  delete_conform_text: 'Do you want to delete the student?'
};
const prod = {
  maxLoss: process.env.REACT_APP_MAX_LOSS_PER_HOUR,
};
// Default to dev if not set
const config = process.env.REACT_APP_STAGE === "prod"
  ? prod
  : dev;
export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 500000,
  timeout: 60 * 60 * 1000,
  ...config
};