import React from "react";
import { Paper, Grid, Typography, Box, Button, FormGroup, FormControlLabel, Checkbox, TextField} from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import FormRender from "../../common/FormHelper/FormRender";
import checkValidity from "../../common/FormHelper/FieldValidator";
import { isValidatedForm } from "../../common/FormHelper/FormValidSetter";
import { GlobalContext } from "../../context/GlobalState";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

//JSON
import ParentBasicJson from './Schema/ParentBasicDetails.json';

import PaymentDetailsJson from './Schema/PaymentDetails.json';
import AllParents from "./AllParents";
import moment from "moment";
class PaymentParentUpdate extends React.Component {
  static contextType = GlobalContext;
  constructor(props){
    super(props);
    this.state = {
      basicJson : ParentBasicJson,
      paymentJson : PaymentDetailsJson,
      isBack: false,
      dialogMsg: "",
      Parent: props.showData,
      openDialog: false,
      allStateCity: [],
      cityOptions: [],
      disabled : false,
      amount: 0,
      description: "",
      email: true,
      sms: true
    };
    this.state.paymentJson[0].value = ""
    this.state.paymentJson[1].value = ""
    this.state.paymentJson[2].value = ""
    this.state.paymentJson[3].value = ""
    this.state.paymentJson[2].maxValue = moment().format('yyyy-MM-DD');
  }
  componentDidMount(){
    this.fillDetails();
  }
  fillDetails = () => {
    const { showData } = this.props;
     const { basicJson } = this.state;
    const copyBasic = [...basicJson];
    if(showData){
      copyBasic.forEach( item => {
        if(item?.type === "phone"){
          item.value = showData[item?.name] ? (showData[item?.name]).replace(/-/g, "").replace(/ /g, "") : "";
        }
        else{
          item.value = showData[item?.name] ? showData[item?.name] : "";
        }
      });
    }
    const { allcentercode } = this.context;
    const centercodedata = allcentercode.map(function(element){
      return element.centerCode;
    });
    this.setState({ basicJson : copyBasic});
  }

  InputBasicChanger = (event, elemIndex) => {

      const copyJSON = [...this.state.basicJson];
      const updatedElement = copyJSON[elemIndex];
      if (updatedElement.type === "file") {
        updatedElement.value = event.target.value;
        updatedElement.file = event.target.files[0];
      } else {
        updatedElement.value = event.target.value;
      }
      const { isValid, errorText } = checkValidity(
        updatedElement.value,
        updatedElement.validation
        );
      updatedElement.valid = isValid;
      updatedElement.errorText = errorText;
      updatedElement.touched = 1;
      this.setState({ basicJson: copyJSON }, () => {});
  }

InputPaymentChanger = (event, elemIndex) => {
  const copyJSON = [...this.state.paymentJson];
  const updatedElement = copyJSON[elemIndex];
  updatedElement.value = event.target.value;

  if (updatedElement?.value?.length){
    copyJSON[0].validation.required = true
    copyJSON[1].validation.required = true
  } else if (!copyJSON[0].value.length && !copyJSON[1].value.length) {
    copyJSON[0].validation.required = false
    copyJSON[1].validation.required = false
  }

  const { isValid, errorText } = checkValidity(
    updatedElement.value,
    updatedElement.validation
    );
  updatedElement.valid = isValid;
  updatedElement.errorText = errorText;
  updatedElement.touched = 1;
  this.setState({ paymentJson: copyJSON }, () => {});
}

generateLink = () => {
  this.setState({disabled: true});
  const { basicJson } = this.state;
  const { showData } = this.props;
  const { generateParentPaymentLink } = this.context;
  const basic  = isValidatedForm(basicJson);
  this.setState({
    basicJson: basic?.validatedForm
  });
  
    const postObj = {};
    postObj.parent_id = showData?._id;
    postObj.email = showData?.email;
    postObj.name = showData?.name;
    postObj.contact = showData?.mobileNo;
    if ( parseInt(this.state.amount) <= 0 || parseInt(this.state.amount) === "" || this.state.amount === "" || this.state.description === "") {
      this.setState({disabled: false});
      this.handleDialogOpen(`Error! Please fill all the mandatory information.`);
      return;
    }
    postObj.amount = parseInt(this.state.amount);
    postObj.reference_id = `LEAP-${showData.centerCode}-${showData?._id}-${showData?.credits?.length}`;
    postObj.description = this.state.description;
    postObj.centerCode = showData?.centerCode;
    if (showData?.student && showData?.student.length){
      postObj.student = showData?.student[0].name;
    } else {
      postObj.student = "";
    }
    
    postObj.notify = {
      email: this.state.email,
      sms: this.state.sms
    }
    generateParentPaymentLink(postObj);
    setTimeout(() => {
      const {err, generateParentPayment} = this.context || {};
      if(err){
        this.handleDialogOpen(err);
        this.setState({disabled: false});
      }
      else if(generateParentPayment){
        this.setState({Parent : generateParentPayment});
        this.handleDialogOpen(`Payment link has been sent successfully.`);
      }
    }, 1000);
}

  handleSave = () => {
    this.setState({disabled: true});
    const { basicJson, paymentJson } = this.state;
    const { showData } = this.props;
    const { updateParentPaymentDetails } = this.context;
    const basic  = isValidatedForm(basicJson);
    const payment = isValidatedForm(paymentJson);
    this.setState({
      basicJson: basic?.validatedForm,
      paymentJson: payment?.validatedForm
    });
    if(payment?.formValidity){
      const postObj = {};
      postObj.parent_id = showData?._id;
      if (paymentJson[1]?.value && paymentJson[0]?.value ) {
        postObj.credits = [{points:  paymentJson[1]?.value, razorPayTransactionRefNo: paymentJson[0]?.value, remarks: paymentJson[3]?.value, date: paymentJson[2]?.value}]
      }
      
      updateParentPaymentDetails(postObj);
      setTimeout(() => {
        const {err, updateParentPayment} = this.context || {};
        if(err){
          this.handleDialogOpen(err);
          this.setState({disabled: false});
        }
        else if(updateParentPayment){
          this.setState({Parent : updateParentPayment});
          this.handleDialogOpen(`Parent points updated successfully.`);
        }
      }, 1000);
    }
  }
  backToParent = () => {
    this.setState({ isBack : true});
  }
  handleDialogOpen = (msg = "") => {
    this.setState({dialogMsg : msg, openDialog : true});
  }
  handleCloseBtn = () => {
    const { updateParentPayment, generateParentPayment}  = this.context;
    if(updateParentPayment || generateParentPayment){
      this.setState({openDialog : false, isBack: true});
    }
    else{
      this.setState({openDialog : false, isBack: false});
    }
  }
  handleClose = () => {
    this.setState({openDialog : false});
  }

  setAmount = (e) => {
    this.setState({amount: e})
  }

  setDescription = (e) => {
    this.setState({description: e});
  }

  handleChange = (notifyType, event) => {
    if (notifyType === "sms") {
      this.setState({sms: event.target.checked})   
    } else if (notifyType === "email") {
      this.setState({email: event.target.checked})
    }
 };
  render() {
    const { basicJson, Parent, openDialog, dialogMsg, isBack, paymentJson } = this.state;

    if (isBack) {
      return <AllParents /> ;
    }
    return (
      <React.Fragment>
        <Box pb={2}>
          <Button
            onClick={this.backToParent}
            type="submit"
            size="large"
            pt={2}
            variant="contained"
            color="primary"
          >
            <KeyboardBackspaceIcon />
          </Button>
        </Box>

        <Box boxShadow={3} mb={1}>
          <Grid container component={Paper}>
            <Grid item xs={12}>
              <Box p={2}>
                <Typography variant="h5">Automatic Payment</Typography>
              </Box>
            </Grid>
            <FormRender form={basicJson} onChange={this.InputBasicChanger} />
            <Grid item xs={12}>
      </Grid>

      

      <Grid item xs={3}>
        <Box px={2}>
        <Typography variant='h6'>Notify Via:</Typography>
          <FormGroup row >
            <FormControlLabel  control={<Checkbox defaultChecked={true}  name="Email" onChange={e => this.handleChange("email", e)} />} label="Email" />
            <FormControlLabel  control={<Checkbox defaultChecked={true}  name="SMS" onChange={e => this.handleChange("sms", e)} />} label="SMS" />
          </FormGroup>
        </Box>
      </Grid>

      <Grid item xs={3}>
        <Box px={2}>
          <TextField
            label="Amount"
            id="par-amount"
            variant="outlined"
            type="number"
            margin="normal"
            required
            value={this.amount}
            InputProps={{ inputProps: { min: 0, max: 50000 } }}
            onChange={e => this.setAmount(e.target.value)}
            name="amount"
          />
        </Box>
      </Grid>

      <Grid item xs={6}>
        <Box px={2}>
          <TextField
            label="Description"
            id="par-description"
            variant="outlined"
            type="text"
            margin="normal"
            required
            fullWidth
            value={this.description}
            onChange={e => this.setDescription(e.target.value)}
            name="description"
          />
        </Box>
      </Grid>

            <Grid item>
              <Box p={2}>
                <Button
                  disabled={this.state.disabled}
                  size="large"
                  pt={2}
                  onClick={this.generateLink}
                  variant="contained"
                  color="primary"
                >
                  Create Payment Link
                </Button>
              </Box>
            </Grid>
            <Grid item>
              <Box p={2}>
                <Button
                  onClick={this.backToParent}
                  type="submit"
                  size="large"
                  pt={2}
                  variant="contained"
                  color="primary"
                >
                  Back
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box boxShadow={3} mb={1}>
          <Grid container component={Paper}>
            <Grid item xs={12}>
              <Box p={2}>
                <Typography variant="h5">Manual Payment</Typography>
              </Box>
            </Grid>
            <FormRender form={basicJson} onChange={this.InputBasicChanger} />
            
            <Grid item xs={12}>
              <Box p={2}>
                <Typography variant="h5">Payment</Typography>
              </Box>
            </Grid>
            <FormRender
              form={paymentJson}
              onChange={this.InputPaymentChanger}
            />
            <Grid item>
              <Box p={2}>
                <Button
                  disabled={this.state.disabled}
                  size="large"
                  pt={2}
                  onClick={this.handleSave}
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </Box>
            </Grid>
            <Grid item>
              <Box p={2}>
                <Button
                  onClick={this.backToParent}
                  type="submit"
                  size="large"
                  pt={2}
                  variant="contained"
                  color="primary"
                >
                  Back
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Dialog
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
          open={openDialog}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle align="center" id="responsive-dialog-title">
            {"Message"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button onClick={this.handleCloseBtn} autoFocus color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }

}

export default PaymentParentUpdate;
