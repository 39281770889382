import React from "react";
import { Paper, Grid, Typography, Box, Button } from "@material-ui/core";
import NormalDetails from "./NormalDetails";
import AddressDetails from "./AddressDetails";
import BankDetails from "./BankDetails";
import PaymentDetails from "./PaymentDetails";
import AllFaculty from "./AllFaculty"
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { GlobalContext } from "../../context/GlobalState";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
// import FacultyAllData from "./FacultyAllData";

function Updatefaculty({ showData }) {
 // const v = showData.address.state
  const _id = showData._id;
  const location = showData.address.location;
  // const acnumber = showData.bank.acNumber;
  // const ifsccode = showData.bank.IFSCCode;
  // const bankname = showData.bank.bankName;
  // const aadhar = showData.aadhar;
  // const pan = showData.pan;
  //const [location, setlocation] = React.useState(showData.name);
  const [name, setName] = React.useState(showData.name);
  const [email, setEmail] = React.useState(showData.email);
  const [mobileNo, setMobile] = React.useState(showData.mobileNo);
 const [acnumber, setAcnumber] = React.useState(showData.bank.acNumber);
 const [ifsccode, setIfsccode] = React.useState(showData.bank.IFSCCode);
 const [bankname, setBankname] = React.useState(showData.bank.bankName);
  const [aadhar, setAadhar] = React.useState(showData.aadhar);
  const [pan, setPan] = React.useState(showData.pan);
  const [trd, setTrd] = React.useState(showData.trainedInProgram);
  const [houseno, setHouseno] = React.useState(showData.address.house_no);
  const [area, setArea] = React.useState(showData.address.area);
  const [pincode, setPincode] = React.useState(showData.address.pincode);
  const [landmark, setLandmark] = React.useState(showData.address.landmark);
  const [catchmentradius, setCatchmentRadius] = React.useState(
    showData.catchmentRadius
  );
  const [type, setType] = React.useState(showData.facultyClassType);
  const [employmentstatus, setEmploymentStatus] = React.useState(
    showData.type
  );
  const [activestatus, setActiveStatus] = React.useState(
    showData.status
  );
  const [centercode, setCentercode] = React.useState(showData.centerCode);
  const [statename, setStateName] = React.useState(showData.address.state);
  const [city, setCity] = React.useState(showData.address.city);
  //const [dataValue, setDataValue] = React.useState("karnataka");
  const [open, setOpen] = React.useState(false);
  const [back, setBack] = React.useState(false);
  const [transactionRefNo, setTransactionRefNo]  = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [paymentDate, handlePaymentDate] = React.useState(new Date());
  const [remarks, setRemarks] = React.useState("");
  const [paymentstatus, setPaymentStatus] = React.useState(showData?.holdPayment || showData?.holdPayment === true ? "Yes" : "No");

 
  //const { addFacultyDetails } = React.useContext(GlobalContext);

  const { updateFacultyDetails, updatefaculty  } = React.useContext(GlobalContext);
  let {err} = React.useContext(GlobalContext);
  const backToFaculty = () =>{
    setBack(true)
  }
  
  const handleClose = () => {
    setOpen(false);
    if(!err){setBack(true);}
  };

  const onSubmit = e => {
    e.preventDefault();
    if ((amount === "" && transactionRefNo !== "") || (amount !== "" && transactionRefNo === "")){
      alert("Please input both Transaction Ref No and Amount or keep both empty");
      return;
    }
    const updatefac = {
      faculty_id: _id,
      name: name,
      email: email.toLowerCase().trim(),
      mobileNo: mobileNo,
     // password: "123456",
      location: location,
      pincode: pincode,
      house_no: houseno,
      area: area,
      city: city,
      state: statename,
      landmark: landmark,
      catchmentRadius: catchmentradius.toString(),
      facultyClassType: type,
      status: activestatus,
      type: employmentstatus,
      centerCode: centercode,
      aadhar: aadhar,
      pan: pan,
      trainedInProgram: trd,
      bank: {
        acNumber: acnumber,
        IFSCCode: ifsccode,
        bankName: bankname,
        bankChequeLeaf: false
      },
      debits: [{
        amount: amount,
        bankTransactionRefNum: transactionRefNo,
        date: paymentDate,
        remarks: remarks
      }],
      holdPayment: paymentstatus === "Yes" ? true : false
    };
    updateFacultyDetails(updatefac, _id);
    setTimeout(function() {setOpen(true)},800);
    //setOpen(true);
  };

  if (back) {
    return <AllFaculty />
    // if (Object.keys(updatefaculty).length === 0) {
    //   return <FacultyAllData showPayments={showData} />
    // }
    // else {
    //   return <FacultyAllData showPayments={updatefaculty} />
    // }
  }

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Box pb={2}>
          <Button
            onClick={backToFaculty}
            type="submit"
            size="large"
            variant="contained"
            color="primary"
          >
          <KeyboardBackspaceIcon/>
          </Button>
        </Box>
      </Grid>
      <form onSubmit={onSubmit}>
        <Box boxShadow={3} mb={1}>
          <Grid container component={Paper}>
            <Grid item xs={12}>
              <Box p={2}>
                <Typography variant="h5">Update Faculty</Typography>
              </Box>
            </Grid>
            <NormalDetails
              name={name}
              email={email}
              mobileNo={mobileNo}
              setEmail={setEmail}
              setName={setName}
              setMobile={setMobile}
              activestatus={activestatus}
              setActiveStatus={setActiveStatus}
              employmentstatus={employmentstatus}
              setEmploymentStatus={setEmploymentStatus}
              paymentstatus={paymentstatus}
              setPaymentStatus={setPaymentStatus}
              type={type}
              setType={setType}
            />
            <AddressDetails
              trd={trd}
              setTrd={setTrd}
              houseno={houseno}
              setHouseno={setHouseno}
              area={area}
              setArea={setArea}
              landmark={landmark}
              setLandmark={setLandmark}
              pincode={pincode}
              setPincode={setPincode}
              catchmentradius={catchmentradius}
              setCatchmentRadius={setCatchmentRadius}
              
              statename={statename}
              setStateName={setStateName}
              city={city}
              setCity={setCity}
            //  dataValue={dataValue}
             // setDataValue={setDataValue}
              centercode={centercode}
              setCentercode={setCentercode}

            />
            <BankDetails
              bankname={bankname}
              setBankname={setBankname}
              acnumber={acnumber}
              setAcnumber={setAcnumber}
              ifsccode={ifsccode}
              aadhar={aadhar}
              setAadhar={setAadhar}
              pan={pan}
              setPan={setPan}
              setIfsccode={setIfsccode}
            />
            <PaymentDetails 
            transactionRefNo={transactionRefNo}
            setTransactionRefNo={setTransactionRefNo}
            amount={amount}
            setAmount={setAmount}
            paymentDate={paymentDate}
            handlePaymentDate={handlePaymentDate}
            remarks={remarks}
            setRemarks={setRemarks}
            />
            <Grid item xs={12}>
              <Box p={2} mt={3}>
                <Button type="submit" size="large" variant="contained" color="primary">   Save </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </form>

       <Dialog
        
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle align="center" style={{background: 'antiquewhite'}} id="responsive-dialog-title">{err ? err : "Faculty updated"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{justifyContent: "center"}} >
          <Button onClick={handleClose} variant="contained" color="primary" >
            Back
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default Updatefaculty;
