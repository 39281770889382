import React from "react";
import { Paper, Grid, Typography, Box, Button} from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import FormRender from "../../common/FormHelper/FormRender";
import checkValidity from "../../common/FormHelper/FieldValidator";
import { isValidatedForm, DEFAULT_JSON } from "../../common/FormHelper/FormValidSetter";
import { GlobalContext } from "../../context/GlobalState";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AllStudents from "../Students/AllStudents";
//JSON
import BasicJson from './Schema/AddOrders.json';
import AddressJson from './Schema/AddressDetails.json';

class AddOrders extends React.Component {
  static contextType = GlobalContext;
  constructor(props){
    super(props);
    this.state = {
      basicJson : DEFAULT_JSON(BasicJson),
      addressJson: DEFAULT_JSON(AddressJson),
      isBack: false,
      dialogMsg: "",
      openDialog: false,
      disabled : false
    };
  }
  componentDidMount(){
    this.getStateCity()
    this.fillDetails();
  }

  fillDetails = async () => {
    const { student } = this.props;
    // const { order } = this.props;
    const { basicJson } = this.state;
    const copyBasic = [...basicJson];
    if(student){
      copyBasic.forEach( item => {
        if(item?.type === "phone"){
          item.value = student[item?.name] ? (student[item?.name]).replace(/-/g, "").replace(/ /g, "") : "";
        }
        else{
          item.value = student[item?.name] ? student[item?.name] : "";
        }
      });
    }
    
    const { getInventoryDataByProgram } = this.context;
    await getInventoryDataByProgram(this.props.student.program, this.props.student._id)
    const { inventoryDataByProgram, err } = this.context;
    const { oldOrderData, err1 } = this.context;

    const classJsonCopy = JSON.parse(JSON.stringify(this.state.basicJson))
    // let preselected = []
    // if (order?.item_qty_cost?.length){
    //   preselected = order.item_qty_cost.map(item => ({name: item.details.name, value: item.details._id}))
    // }
    if (inventoryDataByProgram.length) {
      let options = inventoryDataByProgram.length ? new Set([...inventoryDataByProgram.map(f => {return { name: f.name, value: f._id }})]) : []
          options = [...options]
          classJsonCopy[2].options = options
          // if (preselected.length) {
          //   classJsonCopy[2].value = preselected
          // } else {
            // classJsonCopy[2].value = options.length ? [options[0]] : [classJsonCopy[2].value]
          // }
          classJsonCopy[2].valid = true
          classJsonCopy[2].errorText = "" 
          classJsonCopy[2].touched = 0
    } else {
      classJsonCopy[2].value = ""
      classJsonCopy[2].options = []
      if (err) {
        classJsonCopy[2].valid = false 
        classJsonCopy[2].errorText = err 
        classJsonCopy[2].touched = 1
      }
    }

    if (oldOrderData.length) {
      let options = oldOrderData.length ? new Set([...oldOrderData.map(f => {return { name: f.name, value: f._id }})]) : []
          options = [...options]
          classJsonCopy[3].options = options
          // if (preselected.length) {
          //   classJsonCopy[3].value = preselected
          // } else {
            // classJsonCopy[3].value = options.length ? [options[0]] : [classJsonCopy[3].value]
          // }
          classJsonCopy[3].valid = true
          classJsonCopy[3].errorText = "" 
          classJsonCopy[3].touched = 0
    } else {
      classJsonCopy[3].value = ""
      classJsonCopy[3].options = []
      if (err1) {
        classJsonCopy[3].valid = false 
        classJsonCopy[3].errorText = err1
        classJsonCopy[3].touched = 1
      }
    }


    this.setState({ basicJson : classJsonCopy});
  }

  getStateCity = async () => {
    const context = this.context;
    const { getStateCityDetails } = context;
    await getStateCityDetails();
    setTimeout(() => {
      this.updateState();
    }, 2000);
  }
  updateState = () => {
    const { allstatecity } = this.context;
    const { addressJson } = this.state;
    const data = JSON.parse(JSON.stringify(allstatecity));
    const stateData = Object.keys(data);
    const copyAddress = [...addressJson];
    copyAddress[3].options = stateData;
    this.setState({addressJson : copyAddress}, () => {this.mapAddress()});
  }
  mapAddress() {
    const { student } = this.props;
    const { basicJson, addressJson } = this.state;
    const copyAddressJson = [...addressJson];
    if(student){
      basicJson.forEach( item => {
        if(item.name === "address") {
          const address = student.parent?.residentalAddress || {}
          copyAddressJson.forEach(addItem => {
            if(addItem.name === 'state') {
              const { allstatecity } = this.context;
              const data = JSON.parse(JSON.stringify(allstatecity || []))
              copyAddressJson[4].options =  data[address[addItem?.name] || ""]
            }
            if (item.value !== "Default") {
              addItem.value = "";
              addItem.disabled = false;
            } else {
              addItem.value = String(address[addItem?.name]) || "";
              addItem.disabled = true;
            }
          });
        }
      });
    }
    this.setState({ addressJson: copyAddressJson });
  }

  updateCity = (v) => {
    const value = v ? v : '';
    const { allstatecity } = this.context;
    const { addressJson } = this.state;
    const data = JSON.parse(JSON.stringify(allstatecity));
    const cityData = data[value];
    const copyAddress = [...addressJson];
    copyAddress[4].options = cityData;
    copyAddress[4].value = "";
    this.setState({addressJson : copyAddress});
  }

  InputAddressChanger = (event, elemIndex) => {
    const copyJSON = [...this.state.addressJson];
    const updatedElement = copyJSON[elemIndex];
    updatedElement.value = event.target.value

    const { isValid, errorText } = checkValidity(
      updatedElement.value,
      updatedElement.validation
    );
    updatedElement.valid = isValid;
    updatedElement.errorText = errorText;
    updatedElement.touched = 1;
    this.setState({ addressJson: copyJSON }, () => {
      if(updatedElement?.name === "state"){
        this.updateCity(event.target.value);
      }
    });
  }

  InputBasicChanger = (event, elemIndex) => {
      const copyJSON = [...this.state.basicJson];
      const updatedElement = copyJSON[elemIndex];
      if (updatedElement.type === "file") {
        updatedElement.value = event.target.value;
        updatedElement.file = event.target.files[0];
      }
      else if(updatedElement.type === "phone"){
        updatedElement.value = (event.target.value).replace(/-/g, "").replace(/ /g, "");
      }  else {
        updatedElement.value = event.target.value;
      }

      const { isValid, errorText } = checkValidity(
        updatedElement.value,
        updatedElement.validation
        );
      updatedElement.valid = isValid;
      updatedElement.errorText = errorText;
      updatedElement.touched = 1;
      if (updatedElement.name === 'address') {
        this.mapAddress();
      }
      this.setState({ basicJson: copyJSON }, () => {});
  }

  handleSave = () => {
    this.setState({disabled: true});
    const { basicJson, addressJson } = this.state;
    const { createOrderDetails } = this.context;
    const basic  = isValidatedForm(basicJson);
    const address  = isValidatedForm(addressJson);
    
    this.setState({
      basicJson: basic?.validatedForm,
      addressJson: address?.validatedForm
    });
    // console.log("addressJson for api call: ", addressJson.map(item => ({ [`${item.name}`]: item.value })))
    if(basic?.formValidity && address?.formValidity) {
      const postObj = {};
      let inventory_id = []
      const newOrder = basicJson.filter(item => item.name === 'workbooks')[0].value.map(item => ( item.value ));
      const oldOrder = basicJson.filter(item => item.name === 'oldOrders')[0].value.map(item => ( item.value ));
      inventory_id = [...newOrder, ...oldOrder]
      if (!inventory_id.length) {
        return this.handleDialogOpen("Please select items to order.");
      }
      postObj.student_id = this.props.student?._id;
      postObj.inventory_id = inventory_id; //basicJson.filter(item => item.name === 'workbooks')[0].value.map(item => ( item.value ));
      postObj.parent_id = this.props.student?.parent._id
      postObj.centerCode = this.props.student?.centerCode;
      postObj.deliveryAddress = {};
      postObj.deliveryAddress.house_no = addressJson[0]?.value;
      postObj.deliveryAddress.area = addressJson[1]?.value;
      postObj.deliveryAddress.landmark = addressJson[2]?.value;
      postObj.deliveryAddress.state = addressJson[3]?.value;
      postObj.deliveryAddress.city = addressJson[4]?.value;
      postObj.deliveryAddress.pincode = parseInt(addressJson[5]?.value);
      
      createOrderDetails(postObj);
      setTimeout(() => {
        const {err, addOrder} = this.context || {};
        if(err){
          this.setState({disabled: false});
          this.handleDialogOpen(err);
        } else if(addOrder){
          this.handleDialogOpen(addOrder);
        }
      }, 2000);
    }
  }
  backToParent = () => {
    if (this.props.editOrder) {
      this.props.handleEditOrderClose()
    } else {
      this.setState({ isBack : true});
    }
  }
  handleDialogOpen = (msg = "") => {
    this.setState({dialogMsg : msg, openDialog : true});
  }
  handleCloseBtn = () => {
    const { addOrder }  = this.context;
    if(addOrder){
      this.setState({openDialog : false, isBack: true});
    }
    else{
      this.setState({openDialog : false});
    }
  }
  handleClose = () => {
    this.setState({openDialog : false});
  }
  render() {
    const { basicJson, addressJson, openDialog, dialogMsg, isBack } = this.state;
    basicJson[2].value = basicJson[2].value || []
    basicJson[3].value = basicJson[3].value || []
    // console.log("BASIC JSON: ", basicJson)
    if (isBack) { return <AllStudents /> }
    return (
      <React.Fragment>
        <Box pb={2}>
          <Button
            onClick={this.backToParent}
            type="submit"
            size="large"
            pt={2}
            variant="contained"
            color="primary"
          >
            <KeyboardBackspaceIcon />
          </Button>
        </Box>
        <Box boxShadow={3} mb={1}>
          <Grid container component={Paper}>
            <Grid item xs={12}>
              <Box p={2}>
                <Typography variant="h5">Place an Order</Typography>
              </Box>
            </Grid>
            <FormRender form={basicJson} onChange={this.InputBasicChanger} />
            <Grid item xs={12}>
              <Box p={2}>
                <Typography variant="h5">Add Address</Typography>
              </Box>
            </Grid>
            <FormRender
              form={addressJson}
              onChange={this.InputAddressChanger}
            />
            <Grid item xs={12}>
              <Box p={2}>
                <Button
                disabled={this.state.disabled}
                  size="large"
                  pt={2}
                  onClick={this.handleSave}
                  variant="contained"
                  color="primary"
                >
                  Confirm Order
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Dialog
          open={openDialog}
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle align="center" id="responsive-dialog-title">
            {"Message"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button onClick={this.handleCloseBtn} autoFocus color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}
export default AddOrders;